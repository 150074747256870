import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const PickupsSearchBar = ({
  typing,
  setTyping,
  setTransactionId,
  transactionId,
  disabled = false
}) => {
  const useStyles = makeStyles(theme => ({
    searchInput: {
      width: "320px",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TextField
      autoFocus={typing}
      className={classes.searchInput}
      placeholder={t("Placeholder_SearchTransactionId")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      variant="outlined"
      onBlur={event => setTyping(false)}
      onChange={event => {
        setTyping(true);
        setTransactionId(event.target.value);
      }}
      value={transactionId || ""}
      disabled={disabled}
    />
  );
};

export default PickupsSearchBar;
