import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import InfoIcon from "@material-ui/icons/Info";
import CustomDialog from "../../../components/general/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Button from "../../../components/core/Button";
import useEventTracking from "../../../hooks/useEventTracking";
import PickUpCardInModal from "../../../components/pickups/PickupsCard/shared/PickUpCardInModal";
import PickUpStatus from "../../../components/products/card/PickUpStatus";
import useUserData from "../../../hooks/useUserData";
import { useProductFruitsSurvey } from "../../../hooks/useProductFruitsSurvey";
const useStyles = makeStyles(theme => ({
  paper: {
    width: 936,
    maxWidth: 936,
    "& .MuiPaper-root.MuiCard-root": {
      boxShadow: "none"
    },
    "& > .dialog-title": {
      paddingTop: "48px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
        "& .rightContent": {
          paddingLeft: "4px",
          paddingBottom: "16px"
        }
      }
    },
    "& > .MuiDialogContent-root": {
      [theme.breakpoints.down("sm")]: {
        padding: "0 16px 8px"
      }
    }
  },
  description: {
    marginTop: "12px",
    marginBottom: "20px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: "14px !important",
      lineHeight: "20px !important"
    }
  },
  body1: {
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.25px",
    marginTop: "4px",
    marginBottom: "8px"
  },
  actions: {
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "12px !important"
    }
  }
}));

const RequestPickupModalContent = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { TrackEvent } = useEventTracking();
  const { startSurvey } = useProductFruitsSurvey();
  const { token, pf } = useUserData();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [requestPickupModalData, setRequestPickupModalData] = useState(
    location.state?.requestPickup
  );

  // Clear state
  useEffect(() => {
    requestPickupModalData && history.replace("/pickups?status=pending", {});
  }, [history, requestPickupModalData]);

  const newRequest = useCallback(() => {
    TrackEvent("New request redirect");
    history.push("/products");
  }, [history]);

  if (!requestPickupModalData) return null;

  const pickup = requestPickupModalData.pickup;
  const pickupsData = requestPickupModalData.pickups;

  return (
    <CustomDialog
      mode="advanced"
      open={true}
      title={
        <>
          {!fullScreen && <InfoIcon />}
          {t("PickupModal_PickupRequested_Header")}
        </>
      }
      titleRightContent={
        <div
          onClick={e => {
            if (e.target instanceof HTMLAnchorElement) {
              setRequestPickupModalData(null);
            }
          }}
        >
          <PickUpStatus
            pickups={pickupsData}
            hideLabel={true}
            productId={pickup.productId}
          />
        </div>
      }
      onClose={() => setRequestPickupModalData(null)}
      classes={classes}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.description}
        >
          {t("PickupModal_PickupRequested_Body")}
        </DialogContentText>
        <PickUpCardInModal item={pickup} showSla={true} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="alert" onClick={newRequest}>
          {t("PickupModal_NewRequest_Button")}
        </Button>
        <Button
          onClick={() => {
            setRequestPickupModalData(null);
            startSurvey("PICKUP_REQUEST", pf?.surveys, token);
          }}
          variant="contained"
          color="primary"
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export const RequestPickupModal = ({ showRoute }) => {
  if (showRoute) return null;

  return <RequestPickupModalContent />;
};
