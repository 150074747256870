import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext
} from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import CustomDialog from "../../../general/Dialog";
import classNames from "classnames";
import { useNotifications } from "../../../../hooks/Notifications";
import UserContext from "../../../../providers/UserProvider";
import ChangeProductAlias from "../../../../mutations/ChangeProductAlias";
import DeleteProductAlias from "../../../../mutations/DeleteProductAlias";
import { useMutation } from "react-apollo";
import useEventTracking from "../../../../hooks/useEventTracking";
import { StepTitleComponent } from "../uploadDialog/steps";
import UploadMaterialAliasDialogContent from "./components/uploadMaterialAliasDialogContent";

import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
  root: {
    "& > .dialog-title": {
      paddingTop: "48px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0"
      },
      "& > .MuiTypography-h2": {
        [theme.breakpoints.down("sm")]: {
          position: "absolute",
          top: 0,
          paddingTop: "10px",
          paddingLeft: "24px"
        },
        display: "flex",
        gap: "10px"
      }
    },
    "&  > .MuiDialogContent-root": {
      paddingTop: "12px",
      minHeight: "0px"
    },
    "& > .MuiDialogActions-root": {
      paddingTop: "36px"
    }
  },
  hideTitle: {
    "& > .dialog-title": { display: "none" }
  }
}));

const UploadMaterialNameDialog = ({
  onClose,
  accept = "image/*",
  item,
  refetch
}) => {
  const [newAlias, setNewAlias] = useState(null);

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user, selectedCompany } = useContext(UserContext);
  // const { TrackEvent } = useEventTracking();
  const token = user?.token;

  const [ChangeProductAliasMutation] = useMutation(ChangeProductAlias);
  // const [DeleteProductAliasMutation] = useMutation(DeleteProductAlias);
  const [loading, setLoading] = useState(false);

  const title = "change_material_name_title";
  const titleIcon = Edit;
  const hideTitle = true;

  const fullTitle = StepTitleComponent({
    fullScreen,
    title,
    titleIcon,
    type: ""
  });

  const { pushNotification } = useNotifications();

  const onReset = () => {
    const partnerId = selectedCompany;
    const materialId = item.productId;

    if (!token || !partnerId || !materialId) {
      pushNotification("Error", {
        color: "primary"
      });
      return;
    }

    setLoading(true);
    DeleteProductAlias({
      variables: {
        token,
        partnerId,
        productId: materialId
      }
    })
      .then(() => {
        pushNotification(t("newMatAlias_snack_Suc"), {
          color: "success"
        });

        refetch();
        onClose();

        //Trackevent
      })
      .catch(err => {
        pushNotification(t("newMatAlias_snack_Failed"), {
          color: "primary"
        });
        console.log("Error", err);
        onClose();
      });
  };
  const onSubmit = () => {
    const partnerId = selectedCompany;
    const materialId = item.productId;

    if (!token || !partnerId || !materialId) {
      pushNotification("Error", {
        color: "primary"
      });
      return;
    }

    setLoading(true);
    ChangeProductAliasMutation({
      variables: {
        token,
        partnerId,
        productId: materialId,
        newAlias: newAlias
      }
    })
      .then(() => {
        pushNotification(t("newMatAlias_snack_Suc"), {
          color: "success"
        });

        refetch();
        onClose();

        //Trackevent
      })
      .catch(err => {
        pushNotification(t("newMatAlias_snack_Failed"), {
          color: "primary"
        });
        console.log("Error", err);
        onClose();
      });
  };

  return (
    <CustomDialog
      open={true}
      title={fullTitle}
      onClose={onClose}
      disableClose={false}
      mode="advanced"
      fullScreen={fullScreen}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        className: classNames(classes.root, hideTitle && classes.hideTitle)
      }}
    >
      <UploadMaterialAliasDialogContent
        onClose={onClose}
        fullScreen={fullScreen}
        accept={accept}
        item={item}
        setNewAlias={setNewAlias}
        newAlias={newAlias}
        onSubmit={onSubmit}
        onReset={onReset}
        loading={loading}
      />
    </CustomDialog>
  );
};

export default UploadMaterialNameDialog;
