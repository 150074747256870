import gql from "graphql-tag";

export default gql`
  query GetSinglePickupDataById(
    $token: String!
    $pickupId: String!
    $partnerId: String!
    $reasonOfThePetition: String
  ) {
    SinglePickupDataById(
      token: $token
      pickupId: $pickupId
      partnerId: $partnerId
      reasonOfThePetition: $reasonOfThePetition
    ) {
      _id
      transactionId
      internalTransactionId
      pickupName
      companyName
      streetAddress
      zipCode
      city
      contractStartDate
      contractEndDate
      country
      pickupDate
      pickupOrderDate
      quantity
      items
      truckType
      truckNumberPlate
      imageSrc
      isContracted
      files
      packaging
      collectionType
      collectionDate
      collectionDateRange
      statusId
      status
      canConfirmPickup
      canReschedulePickup
      slaLevel
      documents {
        documentType
        downloadLink
        documentName
        uploadedByMetaloop
        fileType
      }
      new
      aliasForLanguage
    }
  }
`;
