import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography, Hidden, Button } from "@material-ui/core";
import GetPickupsCount from "../../queries/GetPickUpCount";
import UserTransactionsQuery from "../../queries/UserTransactionsQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ProductCard from "../../components/products/card/ProductsCard";
import MobileProductCard from "../../components/products/card/MobileProductCard";
import SaleRequest from "../../components/products/SaleRequest";
import Loader from "../../components/core/shared/Loader";
import { useQuery } from "react-apollo";
import { GET_FULL_PRODUCT_DATA } from "../../queries/Products";
import useEventTracking from "../../hooks/useEventTracking";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import useCompany from "../../hooks/useCompany";
import CompanyLocationProvider from "../../providers/CompanyLocationProvider";
import useIsGroupAdmin from "../../hooks/useIsGroupAdmin";

import { isItAndAdminLoggedInParentCompany } from "../../hooks/useUserData";
import { SelectMenuProps } from "../../components/core/shared/constants";
import { isDemoEnviroment as _isDemoEnviroment } from "../../config/config";
import StyledTooltip from "../../components/core/StyledTooltip";
import UploadMaterialImageDialog from "../../components/core/shared/uploadMatImageDialog";
import UpdateMaterialAliasDialog from "../../components/core/shared/updateMaterialAliasDialog";
const ProductsPage = props => {
  const {
    setShowLocations,
    locationFilter,
    productData,
    type,
    selectedCompany,
    user,
    params_id
  } = props;
  const { showLocations } = useContext(CompanyLocationProvider);
  const isGroupAdmin = useIsGroupAdmin();
  const isDemoEnviroment = _isDemoEnviroment();
  const disableButton = isGroupAdmin || isDemoEnviroment;
  const useStyles = makeStyles(theme => ({
    selectProduct: {
      height: "40px"
    },
    productDropdown: {
      width: "100%"
    },
    w240: {
      width: "240px",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    pageTitle: {
      flexGrow: 1,
      fontSize: 32
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "28px 32px",
      borderBottom: "1px solid #EEEEEE"
    },
    mainContentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 24,
      padding: 32,
      overflowY: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: "72px 16px"
      }
    },
    mobileTopButton: {
      display: "flex",
      justifyContent: "end",
      marginTop: "-4px",
      marginBottom: "-4px"
    }
  }));
  const [updateMaterialAlias, setUpdateMaterialAlias] = useState({
    open: false,
    data: null
  });
  const [updateMaterialImageData, setUpdateMaterialImageData] = useState({
    open: false,
    data: null
  });
  const { companyRoles } = useCompany();

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { TrackEvent } = useEventTracking();
  const hideMaterialButton = history.location.search.includes("sale-request");
  const { loading: queryLoading, refetch } = useQuery(GET_FULL_PRODUCT_DATA, {
    variables: {
      token: user.token,
      companyId: selectedCompany,
      location: showLocations
    },
    notifyOnNetworkStatusChange: true
  });

  const newMaterialToCatalogueFlowStartedEvent = useCallback(() => {
    TrackEvent("New material added to catalogue flow started", {
      accountId: selectedCompany,
      clickSource: "Button",
      roles: companyRoles
    });
  }, [selectedCompany, companyRoles]);

  const salesQueryVariables = {
    type: "all",
    productID: null,
    token: user.token,
    company: selectedCompany,
    limit: 10,
    offset: 0,
    startDate: null,
    endDate: null,
    transaction: "all",
    searchText: null,
    transportType: "all",
    transactionType: "sale",
    statusId: null,
    localization: "all"
  };
  const pickupsQueryVariables = {
    token: user?.token,
    partnerId: selectedCompany
  };

  const { data: countSalesData } = useQuery(UserTransactionsQuery, {
    variables: salesQueryVariables,
    fetchPolicy: "cache-and-network"
  });
  const { data: countPickupsData } = useQuery(GetPickupsCount, {
    variables: pickupsQueryVariables,
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    if (history.location.state?.refetch === true) {
      refetch();
      history.replace({
        ...history.location,
        state: {}
      });
    }
  }, [history.location]);

  React.useEffect(() => {
    TrackEvent("Materials page opened");
  }, []);

  const saleRequestInitializedEvent = (source, item) => {
    TrackEvent("Sale request initialized", {
      source: source,
      productId: item.productId,
      quantity: item.quantity,
      conditionsId: item.conditionId,
      nSalesInProgress: countSalesData?.UserTransactions?.actualTransactions,
      nPickupsInProgress: countPickupsData?.PickupsDataCount?.pending
    });
  };

  const changeNameAction = (source, item) => {
    setUpdateMaterialAlias({ open: true, data: item });
  };

  const changeImageAction = (source, item) => {
    setUpdateMaterialImageData({ open: true, data: item });
    TrackEvent("Material Image Change Started", {
      source: source,
      productId: item.productId,
      conditionsId: item.conditionId
    });
  };

  return (
    <>
      <Hidden smDown>
        <div className={classes.titleWrapper}>
          <Typography variant="h4" className={classes.pageTitle}>
            {t("All Materials")}
          </Typography>
          <div>
            {!hideMaterialButton &&
              (isDemoEnviroment ? (
                <StyledTooltip
                  text={t("demo_disabledFeature")}
                  placement={"left"}
                >
                  <div
                    style={{
                      display: "inline-block",
                      cursor: "not-allowed"
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        history.push("/products/add");
                        newMaterialToCatalogueFlowStartedEvent();
                      }}
                      disabled={disableButton}
                    >
                      {t("Button_AddMaterial")}
                    </Button>
                  </div>
                </StyledTooltip>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    history.push("/products/add");
                    newMaterialToCatalogueFlowStartedEvent();
                  }}
                  disabled={disableButton}
                >
                  {t("Button_AddMaterial")}
                </Button>
              ))}
          </div>
        </div>
      </Hidden>
      <div className={classes.mainContentWrapper}>
        {isItAndAdminLoggedInParentCompany() && (
          <div className={classes.w240}>
            <FormControl variant="outlined" className={classes.productDropdown}>
              <InputLabel
                id="all-transaction-dropdown"
                className={classes.inputLabel}
              >
                {t("filter_show_locations")}
              </InputLabel>
              <Select
                labelId="all-transaction-dropdown"
                id="select-transaction"
                value={showLocations}
                onChange={event => {
                  setShowLocations(event.target.value);
                }}
                label={t("filter_show_locations")}
                className={classes.selectProduct}
                MenuProps={SelectMenuProps}
              >
                <MenuItem value="all">{t("All")}</MenuItem>

                {locationFilter?.map(item => (
                  <MenuItem value={item.locationId}>{item.location}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <Hidden mdUp>
          {!hideMaterialButton && (
            <div className={classes.mobileTopButton}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  history.push("/products/add");
                  newMaterialToCatalogueFlowStartedEvent();
                }}
              >
                {t("Button_AddMaterial")}
              </Button>
            </div>
          )}
        </Hidden>

        {updateMaterialAlias.open && (
          <UpdateMaterialAliasDialog
            open={updateMaterialAlias.open}
            onClose={() => setUpdateMaterialAlias({ open: false, data: null })}
            item={updateMaterialAlias.data}
            user={user}
            selectedCompany={selectedCompany}
            refetch={refetch}
          />
        )}
        {updateMaterialImageData.open && (
          <UploadMaterialImageDialog
            open={updateMaterialImageData.open}
            onClose={() =>
              setUpdateMaterialImageData({ open: false, data: null })
            }
            item={updateMaterialImageData.data}
            user={user}
            selectedCompany={selectedCompany}
            refetch={refetch}
          />
        )}
        {type === "main" ? (
          productData.map(item => {
            const lowerCaseContractType = (
              item.contractType || ""
            ).toLowerCase();
            const isSpotOrOverseas = ["spot", "overseas"].some(a =>
              lowerCaseContractType.includes(a)
            );
            item.showPickupRequest = !isSpotOrOverseas;

            return (
              <Fragment key={item.aliasId}>
                <Hidden smDown>
                  <ProductCard
                    item={item}
                    isGroupAdmin={isGroupAdmin}
                    saleRequestInitializedEvent={saleRequestInitializedEvent}
                    changeImageAction={changeImageAction}
                    changeNameAction={changeNameAction}
                  />
                </Hidden>
                <Hidden mdUp>
                  <MobileProductCard
                    key={item.aliasId}
                    item={item}
                    saleRequestInitializedEvent={saleRequestInitializedEvent}
                    changeImageAction={changeImageAction}
                    changeNameAction={changeNameAction}
                  />
                </Hidden>
              </Fragment>
            );
          })
        ) : (
          <SaleRequest
            user={user}
            selectedCompany={selectedCompany}
            params_id={params_id}
          />
        )}
      </div>
    </>
  );
};

export default ProductsPage;
