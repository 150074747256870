/* eslint-disable linebreak-style */
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";

import CustomTable from "../general/Table";
import ModalWrapper from "../transactions/layout/Modals/ModalClass";
import Skeleton from "../core/shared/Skeleton";
import EmptySalesInfo from "./EmptySalesInfo";

const useStyles = makeStyles(theme => ({
  ModalWrapperCloseWrapper: {
    padding: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    display: "flex",
    alignItem: "center"
  },
  ModalWrapperCloseIcon: {
    marginRight: "21px"
  },
  ModalWrapperContent: {
    // padding: "8px 16px"
  },
  disabled: {
    pointerEvents: "none",
    userSelect: "none"
  },
  alignAtTheEnd: { display: "flex", justifyContent: "flex-end" },
  [theme.breakpoints.down("sm")]: {
    CustomTableRoot: {
      "& .MuiTable-root": {
        width: "auto",
        minWidth: "100%",
        whiteSpace: "nowrap"
      }
    }
  }
}));

const getRandomValueBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const defaultData = [
  {
    id: 1,
    widthStatus: getRandomValueBetween(96, 184),
    widthTransaction: getRandomValueBetween(96, 132),
    widthQuantity: getRandomValueBetween(72, 96),
    widthTotalValue: getRandomValueBetween(72, 128)
  },
  {
    id: 2,
    widthStatus: getRandomValueBetween(96, 184),
    widthTransaction: getRandomValueBetween(96, 132),
    widthQuantity: getRandomValueBetween(72, 96),
    widthTotalValue: getRandomValueBetween(72, 128)
  },
  {
    id: 3,
    widthStatus: getRandomValueBetween(96, 184),
    widthTransaction: getRandomValueBetween(96, 132),
    widthQuantity: getRandomValueBetween(72, 96),
    widthTotalValue: getRandomValueBetween(72, 128)
  },
  {
    id: 4,
    widthStatus: getRandomValueBetween(96, 184),
    widthTransaction: getRandomValueBetween(96, 132),
    widthQuantity: getRandomValueBetween(72, 96),
    widthTotalValue: getRandomValueBetween(72, 128)
  },
  {
    id: 5,
    widthStatus: getRandomValueBetween(96, 184),
    widthTransaction: getRandomValueBetween(96, 132),
    widthQuantity: getRandomValueBetween(72, 96),
    widthTotalValue: getRandomValueBetween(72, 128)
  }
];
const EmptySaleTable = props => {
  const { t } = useTranslation();
  const { loading, isSeller } = props;

  const data = loading
    ? [...defaultData, ...defaultData].map((item, index) => ({
        ...item,
        id: index + 1
      }))
    : defaultData;

  const activatedRow = loading ? null : 2;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isExpanded, setIsExpanded] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const onRowClick = ({ record, onOpenCollapse }) => {
    if (loading) return;
    // open modal not table inner content in mobile
    if (isMobile) {
      setIsExpanded(true);
      setCurrentRecord(record);
      return;
    }
    onOpenCollapse();
  };

  const onCloseModal = () => {
    setIsExpanded(false);
    if (isMobile) {
      return;
    }
  };

  const saleColumns = [
    {
      width: "16px",
      spacer: true
    },
    {
      field: "metaloop_id",
      headerName: t("metaloop_id"),
      //   width: 'auto',
      flex: 1,
      valueFormatter() {
        return <Skeleton height={"14px"} width={"94px"} animation={loading} />;
      }
    },
    {
      field: "yourOrderId",
      headerName: t("yourOrderId"),
      flex: 1,
      valueFormatter() {
        return <Skeleton height={"14px"} width={"94px"} animation={loading} />;
      }
    },
    {
      field: "pickupDate",
      headerName: t("Pickup Date"),
      sortable: false,
      valueFormatter() {
        return <Skeleton height={"14px"} width={"106px"} animation={loading} />;
      }
    },
    {
      field: "status",
      headerName: t("Status"),
      sortable: false,
      valueFormatter({ record }) {
        return (
          <Skeleton
            height={"14px"}
            width={record.widthStatus}
            animation={loading}
          />
        );
      }
    },
    {
      field: "amount",
      headerName: t("Declared Quantity (kg)"),
      flex: 1,
      type: "number",
      valueFormatter({ record }) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton
              height={"14px"}
              width={record.widthQuantity}
              animation={loading}
            />
          </div>
        );
      }
    },
    {
      field: "invoicedAmount",
      headerName: t("Invoiced Quantity (kg)"),
      flex: 1,
      type: "number",
      valueFormatter({ record }) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton
              height={"14px"}
              width={record.widthQuantity}
              animation={loading}
            />
          </div>
        );
      }
    },
    {
      field: "price",
      headerName: t("Total Value"),
      flex: 1,
      type: "number",
      renderCell({ record }) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton
              height={"14px"}
              width={record.widthTotalValue}
              animation={loading}
            />
          </div>
        );
      }
    },
    {
      headerName: "",
      flex: 1,
      type: "number",
      hide: isMobile ? true : false,
      renderCell: ({ record, onOpenCollapse, collapseIsOpen }) => (
        <IconButton aria-label="expand row" size="small">
          {collapseIsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    {
      width: "16px",
      spacer: true
    }
  ];

  const purchaseColumns = [
    {
      width: "32px",
      spacer: true
    },
    {
      headerName: "",
      flex: 1,
      renderCell() {
        return <Skeleton height={"24px"} width={"24px"} animation={loading} />;
      }
    },
    {
      field: "metaloop_id",
      headerName: t("metaloop_id"),
      flex: 1,
      valueFormatter() {
        return <Skeleton height={"14px"} width={"94px"} animation={loading} />;
      }
    },
    {
      field: "yourOrderId",
      headerName: t("yourOrderId"),
      flex: 1,
      valueFormatter() {
        return <Skeleton height={"14px"} width={"94px"} animation={loading} />;
      }
    },
    {
      field: "source",
      headerName: t("Transaction source"),
      sortable: false,
      valueFormatter({ record }) {
        return (
          <Skeleton
            height={"14px"}
            width={record.widthTransaction}
            animation={loading}
          />
        );
      }
    },
    {
      field: "transportOption",
      headerName: t("Transport type"),
      flex: 1,
      valueFormatter({ record }) {
        return (
          <Skeleton
            height={"14px"}
            width={record.widthStatus}
            animation={loading}
          />
        );
      }
    },
    {
      field: "amount",
      headerName: t("Quantity (kg)"),
      flex: 1,
      type: "number",
      valueFormatter({ record }) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton
              height={"14px"}
              width={record.widthQuantity}
              animation={loading}
            />
          </div>
        );
      }
    },
    {
      field: "price",
      headerName: t("Purchase price"),
      flex: 1,
      type: "number",
      renderCell({ record }) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton
              height={"14px"}
              width={record.widthTotalValue}
              animation={loading}
            />
          </div>
        );
      }
    },
    !isMobile && {
      headerName: "",
      flex: 1,
      type: "number",
      renderCell: ({ record, onOpenCollapse, collapseIsOpen }) => (
        <IconButton aria-label="expand row" size="small">
          {collapseIsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    {
      width: "32px",
      spacer: true
    }
  ];

  const columns = isSeller ? saleColumns : purchaseColumns;

  const expandedRowRender = record => {
    if (!record) {
      return null;
    }
    return <EmptySalesInfo order={record} />;
  };

  return (
    <React.Fragment>
      <CustomTable
        columns={columns}
        rows={data}
        hover
        loading={false}
        getRowId={record => record.id}
        expandedRowRender={isMobile ? null : expandedRowRender}
        page={0}
        rowsPerPageOptions={[10]}
        pageSize={10}
        onPageChange={() => {}}
        onPageSizeChange={() => {}}
        rowCount={0}
        onRowClick={onRowClick}
        className={classes.CustomTableRoot}
        defaultCollapseId={activatedRow}
      />
      <Hidden lgUp>
        <ModalWrapper
          onCloseModal={onCloseModal}
          isOpen={isExpanded}
          disableBackdropClick={true}
        >
          <div className={classes.ModalWrapperCloseWrapper}>
            <CloseIcon
              onClick={onCloseModal}
              className={classes.ModalWrapperCloseIcon}
            />
            <Skeleton width={"180px"} height={"24px"} />
          </div>
          <div className={classes.ModalWrapperContent}>
            {expandedRowRender(currentRecord)}
          </div>
        </ModalWrapper>
      </Hidden>
    </React.Fragment>
  );
};

export default EmptySaleTable;
