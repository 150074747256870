import React from "react";
import { makeStyles, Typography, Link, useTheme } from "@material-ui/core";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router";
import { FiberManualRecord } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import momentBD from "moment-business-days";

const useStyles = makeStyles(theme => ({
  desktopPickupTitle: {
    fontWeight: "500",
    fontSize: "12px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  pickUpStatusContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  dotAndLinkContainer: {
    display: "flex",
    alignContent: "center",
    gap: "5px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      gap: "8px"
    }
  },
  Materials_ConfirmationRequired: {
    color: "rgba(0, 0, 0, 0.6)",
    "& > svg": {
      color: "#da1c17",
      fontSize: "12px"
    }
  },
  Materials_Prescheduled: {
    color: "rgba(0, 0, 0, 0.6)",
    "& > svg": {
      color: "#feb749",
      fontSize: "12px"
    }
  },
  Materials_Confirmed: {
    color: "rgba(0, 0, 0, 0.6)",
    "& > svg": {
      color: "#4ef191",
      fontSize: "12px"
    }
  },
  statusContainer: {
    textDecoration: "underline",
    fontSize: "12px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: 400,
    lineHeight: "20px"
  },
  mobileContainer: {
    display: "flex",
    gap: "8px"
  },
  mobileStatusContainer: {
    display: "flex",
    gap: "12px"
  },
  mobileTitle: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  contractPickupContainer: {
    display: "flex",
    flexDirection: "row"
  },
  contractNumber: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "4px"
  }
}));

export const PickUpStatusMobile = ({ pickups, productId, hideLabel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  //Change for having same logic as in the backend
  const formattedEndDate = momentBD().businessAdd(10).format("DD-MM-YYYY");
  const formattedStartDate = momentBD().format("DD-MM-YYYY");

  const totalAmount =
    pickups["confirmation_required"] +
    pickups["confirmed"] +
    pickups["pre_scheduled"];

  return (
    <div className={classes.mobileContainer}>
      {!hideLabel && totalAmount > 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" className={classes.mobileTitle}>
            {t("Pickups")}:
          </Typography>
        </div>
      )}
      <div>
        <div>
          <DotAndLink
            status={"Materials_ConfirmationRequired"}
            extraQuery={`&startDate=${formattedStartDate}&endDate=${formattedEndDate}&date=dateRange`}
            amount={pickups["confirmation_required"]}
            productId={productId}
          />
        </div>
        <div className={classes.mobileStatusContainer}>
          <DotAndLink
            status={"Materials_Confirmed"}
            amount={pickups["confirmed"]}
            productId={productId}
          />

          <DotAndLink
            status={"Materials_Prescheduled"}
            amount={pickups["pre_scheduled"]}
            productId={productId}
          />
        </div>
      </div>
    </div>
  );
};

export const PickUpStatusDesktop = ({ pickups, productId, hideLabel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  //Change for having same logic as in the backend
  const formattedEndDate = momentBD().businessAdd(10).format("DD-MM-YYYY");
  const formattedStartDate = momentBD().format("DD-MM-YYYY");

  return (
    <>
      {pickups["confirmed"] !== 0 ||
      pickups["confirmation_required"] !== 0 ||
      pickups["pre_scheduled"] !== 0 ? (
        <div className={classes.pickUpStatusContainer}>
          {!hideLabel && (
            <Typography className={classes.desktopPickupTitle} variant="body1">
              {t("Pickups")}:
            </Typography>
          )}
          <DotAndLink
            status={"Materials_Confirmed"}
            amount={pickups["confirmed"]}
            productId={productId}
          />
          <DotAndLink
            status={"Materials_ConfirmationRequired"}
            extraQuery={`&startDate=${formattedStartDate}&endDate=${formattedEndDate}&date=dateRange`}
            amount={pickups["confirmation_required"]}
            productId={productId}
          />
          <DotAndLink
            status={"Materials_Prescheduled"}
            amount={pickups["pre_scheduled"]}
            productId={productId}
          />
        </div>
      ) : null}
    </>
  );
};

// Not sure if will need order or product
const PickUpStatus = ({ pickups, productId, hideLabel, contractNumber }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  if (isMobile) {
    return (
      <div className={classes.contractPickupContainer}>
        <ContractNumberDisplay contractNumber={contractNumber} />
        <PickUpStatusMobile
          pickups={pickups}
          productId={productId}
          hideLabel={hideLabel}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.contractPickupContainer}>
        <ContractNumberDisplay contractNumber={contractNumber} />
        <PickUpStatusDesktop
          pickups={pickups}
          productId={productId}
          hideLabel={hideLabel}
        />
      </div>
    );
  }
};

const ContractNumberDisplay = ({ contractNumber }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {contractNumber && (
        <div className={classes.contractNumber}>
          <Typography
            className={
              isMobile ? classes.mobileTitle : classes.desktopPickupTitle
            }
            variant="body1"
          >
            {t("Contract_productPage")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontSize: "12px",
              marginLeft: "4px",
              color: "rgba(0, 0, 0, 0.6)"
            }}
          >
            {contractNumber}
          </Typography>
        </div>
      )}
    </>
  );
};

export const DotAndLink = ({ status, amount, productId, extraQuery }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const redirectStatus = {
    Materials_Confirmed: "scheduled",
    Materials_ConfirmationRequired: "pending",
    Materials_Prescheduled: "pending"
  };
  return (
    <>
      {amount !== 0 ? (
        <div className={` ${classes.dotAndLinkContainer}`}>
          <div className={`${classes[status]} ${classes.dotAndLinkContainer}`}>
            <FiberManualRecord style={{ alignSelf: "center" }} />
          </div>{" "}
          <Link
            onClick={() => {
              history.push(
                "/pickups?status=" +
                  redirectStatus[status] +
                  "&product=" +
                  productId +
                  "" +
                  (extraQuery !== undefined ? extraQuery : "")
              );
            }}
            className={classes.statusContainer}
          >
            {amount} {t(status)}
          </Link>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PickUpStatus;
