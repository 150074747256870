import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Query, useApolloClient } from "react-apollo";
import QueryContext from "../../providers/QueryProvider";
import UserTransactionsQuery from "../../queries/UserTransactionsQuery";
import TransactionsFilters from "../../components/transactions/filters/filters";
import {
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  CircularProgress
} from "@material-ui/core";
import TransactionsTable from "../../components/transactions/TransactionsTable";
import { CheckErrors } from "../../ErrorTracking";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Button, { DropdownButton } from "../../components/core/Button";
import axios from "axios";
import download from "in-browser-download";
import { useHistory, useLocation } from "react-router-dom";

import { showError } from "../../components/core/shared/Notify";
import { decode } from "base64-arraybuffer";
import GLOBAL_VARIABLES from "../../config/config";
import classnames from "classnames";
import useEventTracking from "../../hooks/useEventTracking";
import {
  exportToCSV,
  exportToXLSX,
  transactionListToAoA
} from "../../utils/OrderToDownloadFormat";
import queryString from "query-string";
import {
  IMPORT_DATA_TRANSACTION_TYPE,
  IMPORT_DATA_CONTRACT_TYPE
} from "../../components/core/shared/ImportDataComponent/utils/constants";
import partnerImportedContractsInfo from "../../queries/partnerImportedContractsInfo";
import { useQuery } from "react-apollo";
import useIsGroupAdmin from "../../hooks/useIsGroupAdmin";
import InformationalComponent from "../../components/general/InformationalComponent";
import StyledTooltip from "../../components/core/StyledTooltip";
import { applyFnOrValue } from "../../utils/SharedUtils";

const ORDER_LIST_URL =
  GLOBAL_VARIABLES.API_BASE_URL + "/api/v1/orders/download/ordersList";

const useStyles = makeStyles(theme => ({
  printScreenButton: {
    position: "fixed",
    bottom: "29px",
    right: "16px",
    backgroundColor: "#37474F",
    color: "white",
    borderRadius: "128px",
    width: "56px",
    height: "56px",
    minWidth: "56px"
  },
  filterListIcon: {
    marginRight: "15px"
  },
  absoluteBottom: {
    width: "100%",
    position: "fixed",
    bottom: "33px"
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center"
  },
  bottomFilter: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderRadius: "64px",
    boxShadow:
      "0px 2px 6px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.24)",
    width: "126px",
    height: "48px",
    fontWeight: "500",
    fontSize: "14px"
  },
  tableContainer: {
    width: "100%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 0 0"
    }
  },

  mensageIfEmpty: {
    fontSize: "16px",
    fontWeight: "400",
    flex: "0 0 100%",
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    margin: "8px 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      paddingTop: "24px",
      paddingLeft: "16px",
      paddingRight: "16px",
      margin: "0"
    }
  },
  displayNone: {
    display: "none"
  },
  printAllButton: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "10px",
    paddingBottom: "10px"
    // backgroundColor: theme.palette.primary.main,
    // color: "white"
  },
  headerContainer: {
    padding: "28px 32px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      boxShadow: "none",
      marginBottom: "0"
    },
    "&>div": {
      display: "flex",
      alignItems: "center",
      "& .MuiButton-outlined": {
        marginRight: "16px"
      }
    }
  },
  pageTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "32px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "400",
    lineHeight: "40px"
  },
  productDivider: {
    marginBottom: "24px"
  },
  mainWrapper: {
    backgroundColor: "white",
    padding: "0 0 46px 0",
    alignContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "48px 0 0 0"
    }
  },
  filtersWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  fullWidthMobile: {
    width: "100%",
    backgroundColor: "white"
  },
  fullWidth: {
    width: "100%"
  },
  maxWidth: {
    maxWidth: "1600px"
  },
  title: {
    margin: "34px 0 34px 0",
    display: "flex",
    justifyContent: "space-between"
  },
  bottomPagination: {
    margin: "34px 0 34px 0",
    display: "flex",
    justifyContent: "flex-end"
  },
  bottomPaginationSmall: {
    margin: "0 auto",
    marginTop: "1em",
    display: "flex",
    justifyContent: "flex-end"
  },
  tableHolder: {
    justifyContent: "flex-start",
    width: "100%"
  },
  downloadProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12
  },
  downloadButton: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    marginRight: "16px",
    paddingLeft: "16px"
  },
  downloadButton2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    marginRight: "20px",
    paddingLeft: "16px"
  },
  informationalComponent: {
    width: "1050px",
    maxWidth: "1050px",
    margin: "24px 32px",
    "& p": { lineHeight: "24px", letterSpacing: "0.25px" },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      margin: "20px 16px",
      "& p": { lineHeight: "unset", letterSpacing: "unset" }
    }
  }
}));

const TransactionsContainer = props => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { TrackEvent } = useEventTracking();

  useEffect(() => {
    TrackEvent("Sales page opened");
  }, []);

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const [filterModal, setFilterModal] = useState(false);
  const [webshop, setWebshop] = useState(false);

  const [listProducts, setlistProducts] = useState([]);
  const [listTransports, setlistTransports] = useState([]);
  const [listStatuses, setListStatuses] = useState([]);
  const [listLocalization, setListLocalization] = useState([]);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [downloadingExcelFile, setdownloadingExcelFile] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const isMounted = useRef(true);
  const {
    searchText,
    product,
    date,
    startDate,
    endDate,
    transaction,
    statusId,
    transport,
    localization,
    uploadInitialType
  } = props;

  const [dateSelected, setDateSelected] = useState(date);
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  const [loadedDates, setLoadedDates] = useState(false);
  const [importedDataPreferences, setImportedDataPreferences] = useState({
    checked: false,
    hasImportedContracts: false,
    isAllowedToImportContracts: false
  });

  const updateFilter = (type, value, array_of_values = []) => {
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);

    value ? searchParams.set(type, value) : searchParams.delete(type);
    //Maybe we want to update more than one filter
    if (array_of_values.length > 0) {
      array_of_values.forEach(function (item, index) {
        const { key: k, value: v } = item;
        v ? searchParams.set(k, v) : searchParams.delete(k);
      });
    }

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const resetPagination = () => {
    setOffset(0);
    setPage(0);
  };

  const changePage = pageNumber => {
    setOffset((pageNumber + 1) * limit - limit);
    setPage(pageNumber);
  };

  const setDate = date => {
    setDateSelected(date);
  };

  useEffect(() => {
    if (loadedDates) {
      let query = queryString.parse(location.search);
      const searchParams = new URLSearchParams(query);

      searchParams.set("date", dateSelected);
      if (tempStartDate !== null) {
        searchParams.set("startDate", tempStartDate);
      } else {
        searchParams.delete("startDate");
      }

      if (tempEndDate !== null) {
        searchParams.set("endDate", tempEndDate);
      } else {
        searchParams.delete("endDate");
      }

      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    } else {
      setLoadedDates(true);
    }
  }, [tempEndDate, tempStartDate]);

  const filterChanged = event => {
    resetPagination();
    updateFilter("transactionId", event.target.value);
  };

  const changeProduct = val => {
    resetPagination();
    updateFilter("product", val, [{ key: "statusId", value: "" }]);
  };

  const changeStatus = val => {
    resetPagination();
    updateFilter("statusId", val);
  };

  const changeTransaction = val => {
    resetPagination();
    updateFilter("transaction", val);
  };

  const changeLocalization = val => {
    resetPagination();
    updateFilter("location", val);
  };

  const changeTransport = val => {
    resetPagination();
    updateFilter("transport", val);
  };

  const resetFilters = () => {
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);
    searchParams.delete("transactionId");
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    searchParams.set("transaction", "all");
    searchParams.set("transport", "all");
    searchParams.set("product", "all");
    searchParams.set("location", "all");
    searchParams.set("date", "all");

    setListStatuses([]);
    setListLocalization([]);
    setDateSelected("all");
    searchParams.delete("statusId");
    changePage(0);
    // also reset query params
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const { queryType, user, selectedCompany, transactionType } = props;
  const [prevQueryType, setPrevQueryType] = useState([]);
  const handleChangeRowsPerPage = value => {
    setLimit(parseInt(value, 10));
  };

  const isGroupAdmin = useIsGroupAdmin();

  const { data: importPreferences, loading: loadingImportPreferences } =
    useQuery(partnerImportedContractsInfo, {
      variables: { token: user.token, partnerId: selectedCompany },
      fetchPolicy: "network-only",
      enabled: isGroupAdmin
    });

  useEffect(() => {
    if (transactionType === "purchase") {
      const eventName =
        queryType === "open"
          ? "Open transactions viewed"
          : "Past transactions viewed";
      TrackEvent(eventName, { dateRange: { startDate, endDate } });
    } else if (transactionType === "sale") {
      TrackEvent("Sale selected", {
        status: getStatusFromQueryType(queryType)
      });
    }
  }, [transactionType, queryType, startDate, endDate]);

  const trackOrderExpandedEvent = useCallback(({ order, filters }) => {
    const { product, startDate, endDate } = filters;
    TrackEvent("Order expanded", {
      order,
      filteredProducts: product,
      filteredStartDate: startDate,
      filteredEndDate: endDate,
      status: getStatusFromQueryType(queryType)
    });
  }, []);

  const isSeller = transactionType === "sale";

  if (prevQueryType !== queryType) {
    setPrevQueryType(queryType);
  }

  useEffect(() => {
    if (isMounted.current && queryType !== "transport") {
      isMounted.current = false;
    }
  }, [prevQueryType, queryType]);
  useEffect(() => {
    if (isGroupAdmin && !importedDataPreferences.checked) {
      if (
        !loadingImportPreferences &&
        importPreferences?.partnerImportedContractsInfo
      ) {
        setImportedDataPreferences({
          checked: true,
          hasImportedContracts:
            importPreferences.partnerImportedContractsInfo
              ?.hasPartnerImportedContracts,
          isAllowedToImportContracts:
            importPreferences.partnerImportedContractsInfo
              ?.isAllowedToImportContracts
        });
      }
    }
  }, [isGroupAdmin, loadingImportPreferences, importPreferences]);

  const downloadFile = () => {
    setDownloadingFile(true);
    axios
      .get(downloadUrl(), {
        responseType: "application/octet-stream"
      })
      .then(response => {
        setDownloadingFile(false);
        download(decode(response.data), "transactions list.pdf");
        if (transactionType === "sale") {
          TrackEvent("Sale printed", {
            status: getStatusFromQueryType(queryType),
            filteredProducts: product,
            filteredStartDate: startDate,
            filteredEndDate: endDate
          });
        }
      })
      .catch(() => {
        setDownloadingFile(false);
        showError(t("There was an error downloading the file"));
      });
  };

  const partnerStatuses = {
    100: "Sales_Filter_Pickup_RequestConfirmed",
    new: "Sales_Filter_Transport_Organisation",
    waiting_for_transport_information: "Sales_Filter_Transport_Organisation",
    scheduling_transport: "Sales_Filter_Transport_Organisation",
    transport_document_creation: "Sales_Filter_Transport_Documents",
    transport_in_progress: "Sales_Filter_Transport_InTransit",
    waiting_for_invoicing_information: "Sales_Filter_Transport_Delivered",
    invoicing_payment: "Sales_Filter_Payment_InProgress",
    ready_for_datenstrom: "Sales_Filter_Payment_InProgress",
    buffer: "Sales_Filter_Payment_InProgress",
    completed: "Sales_Filter_Sale_Complete",
    waiting_for_buyer_payment: transactionType =>
      transactionType === "sale"
        ? "Sales_Filter_Sale_Complete"
        : "Sales_Filter_Payment_InProgress",
    waiting_for_material: "Sales_Filter_Transport_InTransit",
    paid_for_export: "Sales_Filter_Payment_InProgress",
    waiting_for_spedition: "Sales_Filter_Transport_Organisation",
    scheduling_pickup: "Sales_Filter_Transport_Organisation",
    quote_required: "Sales_Filter_Transport_Organisation",
    waiting_for_package: "Sales_Filter_Transport_InTransit",
    "IBAN / Verification": "Sales_Filter_Payment_InProgress",
    waiting_for_wiegeschein: "Sales_Filter_Transport_InTransit",
    webshop: "Sales_Filter_Sale_Complete",
    waiting_for_buyer_shipping_instructions: "Sales_Filter_Transport_Documents",
    creating_seller_shipping_instructions: "Sales_Filter_Transport_Documents",
    scheduling_shipment: "Sales_Filter_Transport_Organisation",
    waiting_for_shipping_docs_from_seller: "Sales_Filter_Transport_Documents",
    creating_seller_payment_and_buyer_invoice:
      "Sales_Filter_Payment_InProgress",
    bnl_creation: "Sales_Filter_Transport_Documents",
    container_released: transactionType =>
      transactionType === "sale"
        ? "Sales_Filter_Sale_Complete"
        : "Sales_Filter_Transport_InTransit"
  };

  const getOrderPartnerStatus = (statusId, transactionType) =>
    applyFnOrValue(partnerStatuses[statusId], transactionType);

  const mergeSameTitleStatuses = statuses => {
    const newStatuses = [];
    const statusesCopy = [...statuses];

    statusesCopy.forEach(status => {
      const existingStatus = newStatuses.find(
        newStatus => newStatus.statusUI === status.statusUI
      );
      if (existingStatus) {
        existingStatus.statusId = `${existingStatus.statusId},${status.statusId}`;
      } else {
        newStatuses.push(status);
      }
    });
    return newStatuses;
  };

  const getStatusesForInsideTable = statuses => {
    const newStatuses = [...statuses];
    newStatuses.forEach(
      item =>
        (item.statusUI =
          getOrderPartnerStatus(item.statusId, transactionType) || item.status)
    );
    return newStatuses;
  };

  const getMergedStatuses = statuses => {
    const newStatuses = getStatusesForInsideTable(statuses);
    const statusesMerged = mergeSameTitleStatuses([...newStatuses]);
    return statusesMerged;
  };

  const downloadUrl = () =>
    `${ORDER_LIST_URL}?company=${selectedCompany}&type=${queryType}&token=${user.token}&language=${i18n.language}&transactionType=${transactionType}`;

  const [queryVariables, setQueryVariables] = useState({
    productID: isFirstLoad ? null : product === "all" ? null : product,
    token: user.token,
    company: selectedCompany,
    type: queryType,
    limit,
    offset,
    startDate,
    endDate,
    transaction,
    searchText: searchText && searchText.length >= 3 ? searchText : null,
    transportType: transport,
    transactionType,
    statusId,
    localization: localization.toString()
  });

  const convertDateFormatToQuery = dt => {
    if (!dt) return null;
    const [dd, mm, yyyy] = dt.split("-");
    return `${mm}-${dd}-${yyyy}`;
  };

  useEffect(() => {
    setQueryVariables({
      productID: product === "all" ? null : product,
      token: user.token,
      company: selectedCompany,
      type: queryType,
      limit,
      offset,
      startDate: convertDateFormatToQuery(startDate),
      endDate: convertDateFormatToQuery(endDate),
      transaction,
      searchText: searchText && searchText.length >= 3 ? searchText : null,
      transportType: transport,
      transactionType,
      statusId,
      localization: localization.toString()
    });
  }, [
    product,
    user,
    selectedCompany,
    queryType,
    limit,
    offset,
    startDate,
    endDate,
    transaction,
    searchText,
    transport,
    transactionType,
    statusId,
    localization
  ]);

  //code needed to download the data client side
  const DROPDOWN_OPTIONS = [
    { label: t("Download XLSX"), value: "xlsx" },
    { label: t("Download CSV"), value: "csv" }
  ];

  const DROPDOWN_OPTIONS_GROUP_ADMIN = [
    { label: t("Download XLSX"), value: "xlsx" },
    { label: t("Download CSV"), value: "csv" },
    { label: t("Print all"), value: "pdf" }
  ];
  const client = useApolloClient();

  const handleDownload = value => {
    if (value === "pdf") return downloadFile();
    setdownloadingExcelFile(true);
    client
      .query({
        query: UserTransactionsQuery,
        variables: { ...queryVariables, ...{ limit: 0, offset: 0 } },
        fetchPolicy: "network-only"
      })
      .then(data => {
        let dataToDownload = [];
        if (data?.data?.UserTransactions) {
          dataToDownload = data?.data?.UserTransactions.transactions;

          let aoa = transactionListToAoA(dataToDownload, props, t);

          if (value === "csv") exportToCSV(aoa, "Transactions");
          else if (value === "xlsx") exportToXLSX(aoa, "Transactions");
        }
        setdownloadingExcelFile(false);
      })
      .catch(error => {
        console.log(error);
      });
  };
  //end code needed to download files on the client

  const handleImport = value => {
    history.push({
      pathname: "sales/import",
      search: queryString.stringify({ type: value })
    });
  };

  const DROPDOWN_OPTIONS_FOR_IMPORT = [
    { label: t("dialog_title_import_con"), value: IMPORT_DATA_CONTRACT_TYPE },
    {
      label: t("dialog_title_import_tran"),
      value: IMPORT_DATA_TRANSACTION_TYPE,
      disabled: !importedDataPreferences.hasImportedContracts
    }
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CheckErrors>
      <Query
        query={UserTransactionsQuery}
        fetchPolicy={"cache-and-network"}
        notifyOnNetworkStatusChange
        pollInterval={90000}
        variables={queryVariables}
        partialRefetch={true}
      >
        {({ data, loading, error, networkStatus, refetch }) => {
          if (error) {
            if (
              location.search.includes("download") &&
              !error?.message?.includes("Not enough permissions for this")
            ) {
              refetch();
              return null;
            }
            return history.push({
              pathname: "/dashboard"
            });
          }
          if (!data || _.isEmpty(data))
            data = {
              UserTransactions: {
                actualTransactions: 0,
                transactions: []
              }
            };

          if (
            (listTransports && !listTransports.length) ||
            listTransports === undefined ||
            prevQueryType !== queryType
          ) {
            setlistTransports(data?.UserTransactions.transports);
          }

          if (
            (listStatuses && !listStatuses.length) ||
            listStatuses === undefined ||
            prevQueryType !== queryType ||
            (data?.UserTransactions.statuses !== listStatuses &&
              statusId === null)
          ) {
            setListStatuses(data?.UserTransactions.statuses);
          }

          if (
            (listProducts && !listProducts.length) ||
            listProducts === undefined ||
            prevQueryType !== queryType
          ) {
            setPrevQueryType(queryType);
            setlistProducts(data?.UserTransactions.products);
          }

          const conditionTransactions =
            data?.UserTransactions &&
            data?.UserTransactions.locations &&
            listLocalization &&
            data?.UserTransactions.locations.length > listLocalization.length &&
            data?.UserTransactions.locations.length;
          const conditions =
            conditionTransactions ||
            listLocalization === undefined ||
            prevQueryType !== queryType;

          if (conditions) {
            setPrevQueryType(queryType);

            if (data?.UserTransactions?.locations?.length > 0)
              setListLocalization(data?.UserTransactions.locations);
          }

          let isPolling = networkStatus === 6;
          const isReady =
            loading === false &&
            searchText === null &&
            startDate === null &&
            endDate === null &&
            product === "all" &&
            transaction === "all" &&
            transport === "all" &&
            data?.UserTransactions.actualTransactions === null;

          const prevStatuses = _.cloneDeep(listStatuses);
          const prevStatusesForInsideTable = _.cloneDeep(listStatuses);

          let statuses,
            insideTableStatuses = null;
          if (!_.isEmpty(prevStatuses)) {
            statuses = getMergedStatuses(prevStatuses);
            insideTableStatuses = getStatusesForInsideTable(
              prevStatusesForInsideTable
            );
          }

          const noTransactions =
            isSeller && data?.UserTransactions?.totalTransactions === 0;

          //Once we loaded the products list, we set the filter of the product
          if (isFirstLoad) {
            setIsFirstLoad(false);
            setQueryVariables({
              productID: product === "all" ? null : product,
              token: user.token,
              company: selectedCompany,
              type: queryType,
              limit,
              offset,
              startDate: convertDateFormatToQuery(startDate),
              endDate: convertDateFormatToQuery(endDate),
              transaction,
              searchText:
                searchText && searchText.length >= 3 ? searchText : null,
              transportType: transport,
              transactionType,
              statusId,
              localization
            });
          }

          return (
            <Grid container className={classes.mainWrapper}>
              <Grid item xs={12} className={classes.headerContainer}>
                <Hidden smDown>
                  <Typography variant="h4" className={classes.pageTitle}>
                    {
                      {
                        all: isSeller ? t("Sales_AllSales") : t("All Sales"),
                        open: isSeller ? t("Open Sales") : t("Open Purchases"),
                        closed: isSeller
                          ? t("Completed Sales")
                          : t("Completed Purchases"),
                        transport: t("Sales_SalesInTransit"),
                        sold: t("Sales_SalesInProgress")
                      }[queryType]
                    }
                  </Typography>
                  {!isReady && (
                    <div>
                      {isGroupAdmin ? (
                        <>
                          {importedDataPreferences.checked &&
                          importedDataPreferences.isAllowedToImportContracts ? (
                            <DropdownButton
                              variant="outlined"
                              color="primary"
                              onClick={handleImport}
                              options={DROPDOWN_OPTIONS_FOR_IMPORT}
                              className={classes.downloadButton2}
                            >
                              {t("import_data")}
                            </DropdownButton>
                          ) : (
                            <StyledTooltip
                              text={t("import_orders_not_allowed")}
                              placement={"bottom"}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  cursor: "not-allowed"
                                }}
                              >
                                <DropdownButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleImport}
                                  options={DROPDOWN_OPTIONS_FOR_IMPORT}
                                  className={classes.downloadButton2}
                                  disabled={true}
                                >
                                  {t("import_data")}
                                </DropdownButton>
                              </div>
                            </StyledTooltip>
                          )}

                          <DropdownButton
                            variant="contained"
                            color="primary"
                            onClick={handleDownload}
                            options={DROPDOWN_OPTIONS_GROUP_ADMIN}
                            className={classes.downloadButton}
                          >
                            {t("Download")}
                            {downloadingExcelFile ? (
                              <CircularProgress
                                color="secondary"
                                size={18}
                                className={classes.downloadProgress}
                              />
                            ) : null}
                          </DropdownButton>
                        </>
                      ) : (
                        <>
                          <DropdownButton
                            variant="contained"
                            color="primary"
                            onClick={handleDownload}
                            options={DROPDOWN_OPTIONS}
                            className={classes.downloadButton}
                            disabled={noTransactions}
                          >
                            {t("Download")}
                            {downloadingExcelFile ? (
                              <CircularProgress
                                color="secondary"
                                size={18}
                                className={classes.downloadProgress}
                              />
                            ) : null}
                          </DropdownButton>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.printAllButton}
                            href="#contained-buttons"
                            onClick={downloadFile}
                            disabled={noTransactions || downloadingFile}
                          >
                            {t("Print all")}
                            {downloadingFile ? (
                              <CircularProgress
                                color="secondary"
                                size={18}
                                className={classes.downloadProgress}
                              />
                            ) : null}
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </Hidden>
                {isReady && (
                  <Typography
                    variant="body2"
                    className={classes.mensageIfEmpty}
                  >
                    {
                      {
                        all: t("All transactions will appear here"),
                        open: t(
                          "Transactions in progress will appear here once your offer has been accepted"
                        ),
                        transport: t(
                          "This is where transactions will appear once we sort the transport out"
                        ),
                        closed: t(
                          "Once the transactions have been completed, they will appear here"
                        ),
                        sold: t(
                          "You havent sold anything with us yet, we are working on creating a simple way for you to do exactly that from here"
                        )
                      }[queryType]
                    }
                    {"."}
                  </Typography>
                )}
              </Grid>

              {isReady ? null : (
                <div className={classes.tableContainer}>
                  <QueryContext.Provider value={queryVariables}>
                    <Grid
                      item
                      sm={12}
                      className={classnames(
                        classes.fullWidth,
                        classes.maxWidth
                      )}
                    >
                      <TransactionsFilters
                        date={dateSelected}
                        setDate={setDate}
                        transaction={transaction}
                        onChangeSelectedTransaction={changeTransaction}
                        onChangeSelectedLocalization={changeLocalization}
                        onChangeSelectedProduct={changeProduct}
                        onChangeSelectedTransport={changeTransport}
                        localization={localization}
                        product={product}
                        products={listProducts}
                        startDateChanged={setTempStartDate}
                        endDateChanged={setTempEndDate}
                        filterChanged={filterChanged}
                        startDate={startDate}
                        endDate={endDate}
                        searchText={searchText}
                        onFilterReset={resetFilters}
                        queryType={queryType}
                        webshop={webshop}
                        webshopChanged={setWebshop}
                        isSeller={isSeller}
                        transports={listTransports}
                        statusId={statusId}
                        statuses={statuses}
                        onStatusChanged={changeStatus}
                        transport={transport}
                        downloadFile={downloadFile}
                        downloadingFile={downloadingFile}
                        isOpen={filterModal}
                        open={() => setFilterModal(true)}
                        close={() => setFilterModal(false)}
                        arrLocations={listLocalization}
                        disabled={noTransactions}
                      />
                    </Grid>
                    {noTransactions && (
                      <Grid item sm={12}>
                        <InformationalComponent
                          type="info"
                          className={classes.informationalComponent}
                        >
                          <Typography variant={isMobile ? "body2" : "body1"}>
                            {t("Sales_NoTransactions_Information_Message")}
                          </Typography>
                        </InformationalComponent>
                      </Grid>
                    )}
                    <Grid item sm={12} className={classes.tableHolder}>
                      <TransactionsTable
                        trackOrderExpandedEvent={({ order }) => {
                          trackOrderExpandedEvent({
                            order,
                            filters: { product, startDate, endDate }
                          });
                        }}
                        setLimit={setLimit}
                        token={user.token}
                        queryType={queryType}
                        transactionType={transactionType}
                        data={
                          data?.UserTransactions &&
                          data?.UserTransactions.transactions
                            ? data?.UserTransactions.transactions
                            : []
                        }
                        statuses={insideTableStatuses}
                        isPolling={isPolling}
                        loading={loading}
                        searchText={searchText}
                        filterChanged={filterChanged}
                        refetchQueryVariables={{
                          token: user.token,
                          company: selectedCompany,
                          type: queryType,
                          limit,
                          offset,
                          startDate,
                          endDate,
                          webshop,
                          searchText:
                            searchText && searchText.length >= 3
                              ? searchText
                              : null
                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        rowsPerPage={limit}
                        page={page}
                        rowCount={
                          data?.UserTransactions
                            ? data?.UserTransactions.actualTransactions
                            : undefined
                        }
                        onChangePage={changePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        uploadInitialType={uploadInitialType}
                      />
                    </Grid>
                  </QueryContext.Provider>
                </div>
              )}
            </Grid>
          );
        }}
      </Query>
    </CheckErrors>
  );
};

const getStatusFromQueryType = queryType =>
  queryType === "sold"
    ? "Open"
    : queryType === "transport"
      ? "In transit"
      : "Completed";

export default TransactionsContainer;
