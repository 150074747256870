import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import UploadDialog from "../../../core/shared/uploadDialog";
import GetPickupsData from "../../../../queries/GetPickupsData";
import useEventTracking from "../../../../hooks/useEventTracking";

const PickUpUpload = ({
  item,
  user,
  selectedCompany,
  fullWidth,
  buttonProps,
  onRefetch,
  disableButtons
}) => {
  const { t } = useTranslation();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const { TrackEvent } = useEventTracking();

  const { refetch } = useQuery(GetPickupsData, {
    variables: { token: user?.token, partnerId: selectedCompany }
  });

  const handleRefetch = () => {
    if (refetch) {
      refetch();
    }
  };

  return (
    <>
      <Button
        fullWidth={fullWidth}
        startIcon={<AddIcon />}
        onClick={() => {
          TrackEvent("Upload document flow started", {
            orderId: item._id,
            clickSource: "Button"
          });
          setUploadDialogOpen(true);
        }}
        {...buttonProps}
        disabled={disableButtons}
      >
        {t("DOCUMENTS")}
      </Button>
      {uploadDialogOpen ? (
        <UploadDialog
          orderId={item._id}
          transactionId={item.transactionId}
          internalTransactionId={item.internalTransactionId}
          orderType="purchase"
          onClose={() => {
            // refetch custom query else use default
            if (onRefetch) {
              onRefetch();
            } else {
              handleRefetch();
            }
            setUploadDialogOpen(false);
          }}
          seller={true}
        />
      ) : (
        <></>
      )}
    </>
  );
};

PickUpUpload.defaultProps = {
  buttonProps: {
    variant: "outlined",
    color: "primary"
  }
};

export default PickUpUpload;
