import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button, makeStyles } from "@material-ui/core";
import ModalWrapper from "../../layout/Modals/ModalClass";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import _ from "lodash";

const MobileFilter = props => {
  const useStyles = makeStyles({
    closeButton: {
      marginRight: "16px"
    },
    container: {
      paddingLeft: "16px",
      paddingRight: "16px",
      height: "100%"
    },
    productDropdown: {
      width: "100%",
      marginTop: "20px"
    },
    filterTitle: {
      display: "flex",
      alignItems: "center",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: 0.25,
      margin: "16px 0 20px"
    },
    btnWrapper: {
      marginTop: 20,
      display: "flex",
      justifyContent: "flex-end"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const {
    transports,
    products,
    isOpen,
    close,
    onChangeSelectedTransaction,
    onChangeSelectedTransport,
    onChangeSelectedProduct,
    disabled = false
  } = props;

  const selectedTransactionChanged = event => {
    onChangeSelectedTransaction(event.target.value);
  };

  const selectedTransportChanged = event => {
    onChangeSelectedTransport(event.target.value);
  };
  const selectedProductChanged = event => {
    onChangeSelectedProduct(event.target.value);
  };

  const clearFilters = () => {
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);

    searchParams.set("transaction", "all");
    searchParams.set("transport", "all");
    searchParams.set("product", "all");

    onChangeSelectedTransaction("all");
    onChangeSelectedTransport("all");
    onChangeSelectedProduct("all");

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return (
    <ModalWrapper
      onCloseModal={close}
      isOpen={isOpen}
      className={classes.container}
      disableBackdropClick={true}
    >
      <div className={classes.filterTitle}>
        <CloseIcon onClick={close} className={classes.closeButton} />
        {t("Filter")}
      </div>

      <div className={classes.wrapper}>
        <FormControl
          variant="outlined"
          className={classes.productDropdown}
          disabled={disabled}
        >
          <InputLabel id="all-products-dropdown">
            {t("Purchases_ShowProducts")}
          </InputLabel>
          <Select
            labelId="all-products-dropdown"
            id="select-products"
            value={props.product}
            onChange={selectedProductChanged}
            label={t("Purchases_ShowProducts")}
            className={classes.selectProduct}
          >
            <MenuItem value="all">{t("All")}</MenuItem>

            {products
              ? products.map(product => (
                  <MenuItem value={product.productID}>
                    {product.productName}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className={classes.productDropdown}
          disabled={disabled}
        >
          <InputLabel id="all-transport-dropdown">
            {t("Purchases_ShowTransport")}
          </InputLabel>
          <Select
            labelId="all-transport-dropdown"
            id="select-transport"
            value={props.transport}
            onChange={selectedTransportChanged}
            label={t("Purchases_ShowTransport")}
            className={classes.selectProduct}
          >
            <MenuItem value="all">{t("All")}</MenuItem>

            {transports
              ? _.compact(transports).map(transport => (
                  <MenuItem value={transport.transportId}>
                    {transport.option}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className={classes.productDropdown}
          disabled={disabled}
        >
          <InputLabel id="all-transaction-dropdown">
            {t("Show transaction")}
          </InputLabel>
          <Select
            labelId="all-transaction-dropdown"
            id="select-transaction"
            value={props.transaction}
            onChange={selectedTransactionChanged}
            label={t("Show transaction")}
            className={classes.selectProduct}
          >
            <MenuItem value="all">{t("All")}</MenuItem>
            <MenuItem value="webshop">{t("Webshop")}</MenuItem>
            <MenuItem value="schrott24">{t("Metaloop")}</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className={classes.btnWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={clearFilters}
          disabled={disabled}
        >
          {t("Sales_ClearFilters")}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default MobileFilter;
