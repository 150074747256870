import React, { useEffect, useState } from "react";
import { makeStyles, Hidden } from "@material-ui/core";
import PickupSearchBar from "./PickupSearchBar";
import PickupDesktopFilters from "./PickupDesktopFilters";
import PickupMobileFilters from "./PickupMobileFilters";
import { isItAndAdminLoggedInParentCompany } from "../../../hooks/useUserData";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import queryString from "query-string";

const useStyles = makeStyles(theme => ({
  flexColumn: {
    flexDirection: "column"
  },
  fixedWrapper: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "1",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    "& .MuiFab-extended": {
      marginRight: "47px"
    }
  },
  dropdownContainer: {
    display: "flex",
    gap: "20px",
    marginTop: "24px"
  },

  filterWrapper: {
    display: "flex",
    gap: "20px"
  },

  filterContainer: {
    display: "flex",
    padding: "0 16px 0 32px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
      paddingBottom: "4px"
    }
  }
}));

const PickupsFilters = ({
  filters,
  triggerReset,
  setTriggerReset,
  disabled = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const [transactionId, setTransactionId] = useState(
    searchParams.get("transactionId") ?? null
  );
  const [date, setDate] = useState(searchParams.get("date") ?? "all");
  const [startDate, setStartDate] = useState(
    searchParams.get("startDate") ?? null
  );
  const [endDate, setEndDate] = useState(searchParams.get("endDate") ?? null);

  const [product, setProduct] = useState(searchParams.get("product") ?? "all");
  const [status, setStatus] = useState(searchParams.get("status") ?? "all");
  const [localization, setLocalization] = useState(
    searchParams.get("localization") ?? "all"
  );

  const [typing, setTyping] = useState(false);
  const [reset, setReset] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(
    product === "all" &&
      date === "all" &&
      transactionId === null &&
      localization === "all"
  );

  const changeFilters = initialLoad => {
    const loadedFromSideMenu =
      _.get(history, "location.state.loadingFrom.sideMenu") === true;
    const queryParams = {
      status,
      ...(transactionId !== null && {
        typing,
        transactionId
      }),
      ...(startDate !== null && { startDate }),
      ...(endDate !== null && { endDate }),
      ...(date !== "all" && { date }),
      ...(product !== "all" && { product }),
      ...(localization !== "all" && { localization })
    };

    if (!loadedFromSideMenu && status !== "all" && status !== null) {
      queryParams.status = status;
    }

    const loadingFrom = { filters: !initialLoad, date: new Date() };

    let oldQuery = queryString.parse(location.search);
    const oldSearchParams = new URLSearchParams(oldQuery);

    if (oldSearchParams.get("expanded") !== null) {
      queryParams.expanded = oldSearchParams.get("expanded");
    }

    if (!initialLoad) {
      history.push(
        {
          pathname: location.pathname,
          search: new URLSearchParams(queryParams).toString()
        },
        { loadingFrom }
      );
    }
  };

  useEffect(() => {
    const newStatus = searchParams.get("status") ?? "all";
    const newProduct = searchParams.get("product") ?? "all";
    const newLocalization = searchParams.get("localization") ?? "all";
    const newDate = searchParams.get("date") ?? "all";
    const newTransactionId = searchParams.get("transactionId") ?? null;
    const newStartDate = searchParams.get("startDate") ?? null;
    const newEndDate = searchParams.get("endDate") ?? null;

    if (newStartDate !== startDate) {
      setStartDate(newStartDate);
    }
    if (newEndDate !== endDate) {
      setEndDate(newEndDate);
    }
    if (newStatus !== status) {
      setStatus(newStatus);
    }

    if (newProduct !== product) {
      setProduct(newProduct);
    }

    if (newLocalization !== localization) {
      setLocalization(newLocalization);
    }

    if (newDate !== date) {
      setDate(newDate);
    }

    if (newTransactionId !== transactionId) {
      setTransactionId(newTransactionId);
    }
  }, [location.search]);

  useEffect(() => {
    if (reset) {
      setStatus("all");
      setProduct(null);
      setStartDate(null);
      setEndDate(null);
      setProduct("all");
      setLocalization("all");
      setDate("all");
      setTransactionId(null);
      setReset(false);
      changeFilters(false);
    }
  }, [reset]);

  useEffect(() => {
    if (date === "all" || (startDate !== null && endDate !== null)) {
      if (!isInitialLoad) {
        changeFilters(false);
      } else {
        setIsInitialLoad(false);
      }
    }
  }, [status, product, date, localization]);

  useEffect(() => {
    if (triggerReset === true) {
      setTriggerReset(false);
      setReset(true);
    }
  }, [triggerReset]);

  useEffect(() => {
    if (!isInitialLoad && status !== null) {
      changeFilters(false);
    }
  }, [transactionId, typing, startDate, endDate]);

  useEffect(() => {
    changeFilters(true);
  }, [isInitialLoad]);

  return (
    <div className={classes.filterContainer}>
      <div
        className={
          classes.dropdownContainer +
          " " +
          (isItAndAdminLoggedInParentCompany() ? classes.flexColumn : " ")
        }
      >
        <PickupSearchBar
          typing={typing}
          setTyping={setTyping}
          setTransactionId={setTransactionId}
          transactionId={transactionId}
          disabled={disabled}
        />
        <Hidden mdUp>
          <PickupMobileFilters
            date={date}
            product={product}
            status={status}
            setDate={setDate}
            setProduct={setProduct}
            setStatus={setStatus}
            setReset={setReset}
            filters={filters}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            disabled={disabled}
          />
        </Hidden>

        <Hidden mdDown>
          <div className={classes.filterWrapper}>
            <PickupDesktopFilters
              date={date}
              product={product}
              status={status}
              setDate={setDate}
              setProduct={setProduct}
              localization={localization}
              setLocalization={setLocalization}
              setStatus={setStatus}
              setReset={setReset}
              filters={filters}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              disabled={disabled}
            />
          </div>
        </Hidden>
      </div>
    </div>
  );
};

export default PickupsFilters;
