import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles
} from "@material-ui/core";
import { ReactComponent as WeightIcon } from "../../../../../assets/icons/weight.svg";
import ImageSharp from "@material-ui/icons/ImageSharp";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import PublishSharp from "@material-ui/icons/PublishSharp";
import {
  INVOICE_UPLOAD_TYPE,
  WEIGHTNOTE_UPLOAD_TYPE,
  MATERIAL_IMAGES_UPLOAD_TYPE,
  getSubtitle
} from ".";
import { useCallback } from "react";
import { withTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px",
      minHeight: "0 !important"
    },
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "24px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  buttons: {
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
    "& > button": {
      flexGrow: 1
    }
  }
}));
const Step1 = ({
  setType,
  setNextStepAction,
  t,
  disableInvoiceUpload = false,
  internalTransactionId,
  transactionId,
  type
}) => {
  const classes = useStyles();

  const chooseOption = useCallback(
    type => {
      setType(type);
      setNextStepAction();
    },
    [setType]
  );

  const showInvoiceButton = !disableInvoiceUpload;

  return (
    <>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {getSubtitle(internalTransactionId, transactionId, type, t)}
        </DialogContentText>
        {showInvoiceButton ? (
          <div className={classes.buttons}>
            <Button
              onClick={() => chooseOption(WEIGHTNOTE_UPLOAD_TYPE)}
              variant="outlined"
              color="primary"
              startIcon={<WeightIcon />}
            >
              {t("Button_WeightNote")}
            </Button>
            <Button
              onClick={() => chooseOption(MATERIAL_IMAGES_UPLOAD_TYPE)}
              variant="outlined"
              color="primary"
              startIcon={<ImageSharp />}
            >
              {t("Button_MaterialImages")}
            </Button>
            <Button
              onClick={() => chooseOption(INVOICE_UPLOAD_TYPE)}
              variant="outlined"
              color="primary"
              startIcon={<PublishSharp />}
            >
              {t("Button_Invoice")}
            </Button>
            <Button
              onClick={() => chooseOption("other")}
              variant="outlined"
              color="primary"
              startIcon={<InsertDriveFile />}
            >
              {t("Button_OtherDocumets")}
            </Button>
          </div>
        ) : (
          <>
            <div className={classes.buttons} style={{ marginBottom: 16 }}>
              <Button
                onClick={() => chooseOption(WEIGHTNOTE_UPLOAD_TYPE)}
                variant="outlined"
                color="primary"
                startIcon={<WeightIcon />}
              >
                {t("Button_WeightNote")}
              </Button>
              <Button
                onClick={() => chooseOption(MATERIAL_IMAGES_UPLOAD_TYPE)}
                variant="outlined"
                color="primary"
                startIcon={<ImageSharp />}
              >
                {t("Button_MaterialImages")}
              </Button>
            </div>
            <div className={classes.buttons}>
              <Button
                onClick={() => chooseOption("other")}
                variant="outlined"
                color="primary"
                startIcon={<InsertDriveFile />}
              >
                {t("Button_OtherDocumets")}
              </Button>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions />
    </>
  );
};

export default withTranslation()(Step1);
