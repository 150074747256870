import { useProductFruitsApi } from "react-product-fruits";
import { SURVEYS } from "../utils/constants";
import updateUserSurveys from "../mutations/updateUserSurveysMutation";
import { useMutation } from "react-apollo";
import { useRef } from "react";
import moment from "moment";
export const useProductFruitsSurvey = () => {
  const [updateSurvey] = useMutation(updateUserSurveys);
  const apiRef = useRef(null);
  useProductFruitsApi(api => {
    apiRef.current = api;
  }, []);

  const checkSurvey = (surveyId, userSurveys, token) => {
    try {
      userSurveys = userSurveys || {};
      // 1: if the survey is already seen
      if (userSurveys[surveyId]) {
        return false;
      }
      const now = moment();
      const threeDaysAgo = now.clone().subtract(3, "days");

      // 2: if last seen survey was more than 3 days
      const canAppear = Object.values(userSurveys).every(date =>
        moment(date).isBefore(threeDaysAgo)
      );
      if (!canAppear) {
        return false;
      }
      updateSurvey({
        variables: {
          token: token,
          surveyId: surveyId
        }
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const startSurvey = (type, userSurveys, token) => {
    const surveyId = SURVEYS[type];
    const check = checkSurvey(surveyId, userSurveys, token);
    if (!check) {
      return;
    }

    if (type === "GENERAL") {
      setTimeout(() => {
        apiRef.current.surveys.startSurvey(surveyId);
      }, 30000);
    } else {
      apiRef.current.surveys.startSurvey(surveyId);
    }
  };
  return { startSurvey };
};
