import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Search from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import ModalWrapper from "../../layout/Modals/ModalClass";
import RemoveIcon from "@material-ui/icons/Remove";
import { OutlinedInput } from "@material-ui/core";
import DateDropdown from "../../../general/DateDropdown";

import { isItAndAdminLoggedInParentCompany } from "../../../../hooks/useUserData";
import { SelectMenuProps } from "../../../core/shared/constants";
const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: "flex",
    gap: "20px"
  },
  flexColumn: { flexDirection: "column" },
  wrapper: {
    marginBottom: "24px",
    color: "#DEDEDE",
    paddingLeft: "32px",
    paddingRight: "32px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "18px",
      paddingRight: "18px"
    }
  },
  searchInputContainer: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginBottom: "24px",
      width: "100%",
      maxWidth: "604px"
    },
    "& .MuiIconButton-root": {
      height: "40px"
    }
  },
  datePickersSeparator: {
    fontSize: "18px",
    width: "24px"
  },
  searchInputMobile: {
    width: "100%",
    display: "flex"
  },
  datePickersContainer: {
    display: "flex",
    alignItems: "center",
    width: "240px",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    "& .MuiFormControl-root": {
      width: "240px",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    }
  },
  datePickers: {
    "& div[class*='MuiInputBase-root']": {
      height: "40px",
      paddingRight: "4px"
    }
  },
  selectProduct: {
    height: "40px"
  },
  productDropdown: {
    width: "100%"
  },
  date: {
    width: "100px",
    height: "38px",
    paddingLeft: "5px"
  },
  searchInput: {
    width: "315px",
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: "16px"
    }
  },
  printButton: {
    display: "flex",
    alignSelf: "flex-end",
    width: "24px"
  },
  greyInput: {
    color: "#0F0F0F",
    padding: "0 10px 0 10px"
  },
  fixedWrapper: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px"
  },
  ModalWrapperRoot: {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  ModalWrapperCloseWrapper: {
    fontWeight: "500",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    padding: "16px 0",
    marginBottom: "20px"
  },
  ModalWrapperCloseIcon: {
    marginRight: "16px"
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px"
  },
  w240: {
    width: "240px",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  [theme.breakpoints.down("xs")]: {
    alignRightMobile: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    }
  }
}));

const FiltersPart = ({
  arrLocations,
  startDateChanged,
  endDateChanged,
  startDate,
  endDate,
  products,
  statusId,
  statuses,
  product,
  location,
  onChangeSelectedProduct,
  onChangeSelectedLocalization,
  onStatusChanged,
  onFilterReset,
  queryType,
  date,
  setDate,
  disabled = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedProductChanged = event => {
    onChangeSelectedProduct(event.target.value);
  };

  const selectedStatusChanged = event => {
    onStatusChanged(event.target.value);
  };

  const selectedLocalizationChanged = event => {
    //we will see if we delete the all option
    const cond1 =
      location.includes("all") && event.target.value.toString() !== "all";
    const cond2 =
      !location.includes("all") && event.target.value.includes("all");
    if (cond1) {
      onChangeSelectedLocalization(
        event.target.value.filter(item => item !== "all").toString()
      );
      return;
    }
    if (cond2) {
      onChangeSelectedLocalization(["all"]);
      return;
    }
    onChangeSelectedLocalization(event.target.value.toString());
  };

  if (
    statusId !== "" &&
    statusId !== null &&
    statuses &&
    !statuses.find(status => status.statusId === statusId)
  ) {
    //Set status to all if the product has not that status
    onStatusChanged("");
  }

  return (
    <div className={classes.flex}>
      {isItAndAdminLoggedInParentCompany() && (
        <div className={classes.w240}>
          <FormControl
            variant="outlined"
            className={classes.productDropdown}
            disabled={disabled}
          >
            <InputLabel
              id="all-transaction-dropdown"
              className={classes.inputLabel}
            >
              {t("filter_show_locations")}
            </InputLabel>
            <Select
              labelId="all-transaction-dropdown"
              id="select-transaction"
              value={location}
              onChange={selectedLocalizationChanged}
              label={t("filter_show_locations")}
              className={classes.selectProduct}
              MenuProps={SelectMenuProps}
              multiple={true}
            >
              <MenuItem value="all">{t("All")}</MenuItem>

              {arrLocations &&
                arrLocations.map(item => (
                  <MenuItem value={item.locationId}>{item.location}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className={classes.datePickersContainer}>
        <DateDropdown
          date={date}
          setDate={setDate}
          label={"Calendar_DateRange"}
          startDate={startDate}
          setStartDate={startDateChanged}
          endDate={endDate}
          setEndDate={endDateChanged}
          disabled={disabled}
        />
      </div>
      <div className={classes.w240}>
        <FormControl
          variant="outlined"
          className={classes.productDropdown}
          disabled={disabled}
        >
          <InputLabel id="all-products-dropdown">
            {t("Show products")}
          </InputLabel>
          <Select
            labelId="all-products-dropdown"
            id="select-products"
            value={product}
            onChange={selectedProductChanged}
            label={t("Show products")}
            className={classes.selectProduct}
            MenuProps={SelectMenuProps}
          >
            <MenuItem value="all">{t("All")}</MenuItem>

            {products
              ? products.map(product => (
                  <MenuItem value={product.productID} key={product.productID}>
                    {product.productName}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </div>
      {queryType !== "closed" ? (
        <div className={classes.w240}>
          <FormControl
            variant="outlined"
            className={classes.productDropdown}
            disabled={disabled}
          >
            <InputLabel id="all-status-dropdown" shrink>
              {t("Status")}
            </InputLabel>
            <Select
              labelId="all-status-dropdown"
              id="select-status"
              value={statusId || ""}
              displayEmpty
              onChange={selectedStatusChanged}
              input={<OutlinedInput notched label={t("Status")} />}
              label={t("Status")}
              className={classes.selectProduct}
              MenuProps={SelectMenuProps}
            >
              <MenuItem value="">{t("All")}</MenuItem>
              {/* statuses */}
              {statuses
                ? statuses.map(record => (
                    <MenuItem value={record.statusId} key={record._id}>
                      {t(record.statusUI)}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </div>
      ) : null}

      <div className={classes.alignRightMobile}>
        <Hidden mdDown>
          <Button
            variant="outlined"
            color="primary"
            onClick={onFilterReset}
            disabled={disabled}
          >
            {t("Sales_ClearFilters")}
          </Button>
        </Hidden>
        <Hidden lgUp>
          <Button
            variant="contained"
            color="primary"
            onClick={onFilterReset}
            disabled={disabled}
          >
            {t("Sales_ClearFilters")}
          </Button>
        </Hidden>
      </div>
    </div>
  );
};

const DesktopFilters = props => {
  const classes = useStyles();

  const [filterModal, setFilterModal] = React.useState(false);

  const { t } = useTranslation();

  const {
    startDateChanged,
    endDateChanged,
    filterChanged,
    searchText,
    startDate,
    endDate,
    products,
    statusId,
    statuses,
    product,
    onChangeSelectedProduct,
    onChangeSelectedLocalization,
    onStatusChanged,
    onFilterReset,
    downloadFile,
    downloadingFile,
    queryType,
    date,
    setDate,
    location,
    arrLocations,
    disabled = false
  } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.searchInputContainer}>
        <Hidden lgUp>
          <TextField
            className={classes.searchInputMobile}
            placeholder={t("internalOrMetaloopId")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            variant="outlined"
            onChange={filterChanged}
            value={searchText || ""}
            disabled={disabled}
          />
          <IconButton
            aria-label="PRINT"
            className={classes.printButton}
            onClick={downloadFile}
            disabled={disabled || downloadingFile}
          >
            <PrintIcon />
          </IconButton>
        </Hidden>
      </div>
      <Hidden mdDown>
        <div
          className={
            classes.filterContainer +
            " " +
            (isItAndAdminLoggedInParentCompany() ? classes.flexColumn : " ")
          }
        >
          <TextField
            className={classes.searchInput}
            placeholder={t("internalOrMetaloopId")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            variant="outlined"
            onChange={filterChanged}
            value={searchText || ""}
            disabled={disabled}
          />

          <FiltersPart
            location={location}
            arrLocations={arrLocations}
            date={date}
            setDate={setDate}
            startDateChanged={startDateChanged}
            endDateChanged={endDateChanged}
            startDate={startDate}
            endDate={endDate}
            products={products}
            product={product}
            statusId={statusId}
            statuses={statuses}
            onChangeSelectedProduct={onChangeSelectedProduct}
            onChangeSelectedLocalization={onChangeSelectedLocalization}
            onStatusChanged={onStatusChanged}
            onFilterReset={onFilterReset}
            queryType={queryType}
            disabled={disabled}
          />
        </div>
      </Hidden>
      <Hidden lgUp>
        <ModalWrapper
          onCloseModal={() => setFilterModal(false)}
          isOpen={filterModal}
          className={classes.ModalWrapperRoot}
          disableBackdropClick={true}
        >
          <div className={classes.ModalWrapperCloseWrapper}>
            <CloseIcon
              onClick={() => setFilterModal(false)}
              className={classes.ModalWrapperCloseIcon}
            />
            {t("Filter")}
          </div>

          <FiltersPart
            onChangeSelectedLocalization={onChangeSelectedLocalization}
            startDateChanged={startDateChanged}
            endDateChanged={endDateChanged}
            startDate={startDate}
            endDate={endDate}
            setDate={setDate}
            date={date}
            products={products}
            product={product}
            statusId={statusId}
            statuses={statuses}
            onChangeSelectedProduct={onChangeSelectedProduct}
            onStatusChanged={onStatusChanged}
            onFilterReset={onFilterReset}
            queryType={queryType}
            disabled={disabled}
          />
        </ModalWrapper>
        <div className={classes.fixedWrapper}>
          <Fab
            variant="extended"
            color="primary"
            aria-label="filter"
            onClick={() => setFilterModal(true)}
          >
            <FilterListIcon />
            {t("Filters")}
          </Fab>
        </div>
      </Hidden>
    </div>
  );
};

export default DesktopFilters;
