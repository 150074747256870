import React from "react";
import { useTranslation } from "react-i18next";
import NextPickUpContent from "./NextPickUpContent";
import LimitedTextWithMoreButton from "../../general/LimitedTextWithMoreButton";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PickUpStatus from "./PickUpStatus";
import ModalWrapper from "../../transactions/layout/Modals/ModalClass";
import FrequencyConversor from "./FrequencyConversor";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import {
  getProductImageUrl,
  getQuantityString,
  getTitle,
  renderEstimatedPrice,
  renderIndexConnection,
  renderPriceValue
} from "./ProductUtils";
import AddressLabel from "../../general/label/address";

const ModalExpandedCard = props => {
  const {
    item,
    closeModal,
    isOpen,
    onEventRequest: actualOnEventRequest
  } = props;

  const useStyles = makeStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "0 16px 16px"
    },
    headerContainer: {
      display: "flex",
      gap: 16,
      padding: "16px 0",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "24px",
      alignItems: "flex-start"
    },
    imageContainer: {
      width: "100%",
      height: 100,
      backgroundColor: item.image.productName ? "none" : "lightgrey",
      "& > img": {
        objectFit: "cover"
      }
    },
    contractedBar: {
      padding: "1px 4px",
      position: "absolute",
      transform: "translateY(12px)",
      background: "#52F597",
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: 1.5,
      lineHeight: "16px"
    },
    statusContainer: {
      display: "flex",
      padding: "16px 0 8px"
    },
    saleStatusContainer: {
      display: "flex",
      alignItems: "center",
      gap: 8,
      padding: "16px 0 0",
      color: "rgba(0, 0, 0, 0.38)",
      "& > svg": {
        fontSize: 12
      },
      "& > p": {
        fontSize: 12,
        lineHeight: "16px",
        letterSpacing: 0.4
      }
    },
    active: {
      color: "rgba(0, 0, 0, 0.6)",
      "& > svg": {
        color: "#52F597"
      },
      "& > p": {
        cursor: "pointer"
      }
    },
    expired: {
      color: "rgba(0, 0, 0, 0.6)",
      "& > svg": {
        color: "#E11900"
      },
      "& > p": {
        cursor: "pointer"
      }
    },
    pickUpStatusContainer: {
      marginTop: "12px",
      marginBottom: "8px"
    },
    descriptionContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: 16
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 20
    },
    title: {
      fontSize: 16,
      letterSpacing: 0.25,
      paddingTop: 20
    },
    subtitle: {
      color: "rgba(0, 0, 0, 0.6)",
      margin: 0,
      paddingTop: 8
    },
    capitalize: {
      textTransform: "capitalize"
    },
    labelContainer: {
      display: "flex",
      width: "fit-content",
      marginTop: "12.5px"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();

  const { parseDate, parseCurrency } = useStandardLocalization();
  const showPickupRequest = item.showPickupRequest;
  return (
    <ModalWrapper
      onCloseModal={closeModal}
      isOpen={isOpen}
      disableBackdropClick={true}
    >
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Close onClick={closeModal} size="small" />
          {item.alias}
        </div>

        <div className={classes.imageContainer}>
          {item.statusId === 1 && (
            <div className={classes.contractedBar}>{t("CONTRACTED")}</div>
          )}

          <img
            src={getProductImageUrl(item, 550, 100)}
            alt={item.image.productName}
            width="100%"
            height="100%"
          />
        </div>
        {item.showAddressLabel && item.pickUpFullAddress.numOfAddresses > 1 && (
          <div className={classes.labelContainer}>
            <AddressLabel
              postCode={item.pickUpFullAddress.zipCode}
              city={item.pickUpFullAddress.city}
              isMobile={true}
            />
          </div>
        )}
        <div className={classes.pickUpStatusContainer}>
          <PickUpStatus
            pickups={item.pickups}
            productId={item.old_id}
            contractNumber={item.contractNumber}
          />
        </div>
        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {getTitle(t, item)}
            </Typography>
            {`${item.collectedQty || 0} / ${item.totalQuantity || item.quantity || 0} mt`}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Packaging")}
            </Typography>
            {item.packaging || "-"}
          </Grid>
        </Grid>

        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            {item.statusId === 1 ? (
              <>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Materials_NextPickupDate")}
                </Typography>
                <NextPickUpContent item={item} />
              </>
            ) : (
              <>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Price Validity Date")}
                </Typography>
                {(item.validTo && parseDate(item.validTo)) || "-"}
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Pickup Frequency")}
            </Typography>
            <FrequencyConversor item={item} />
          </Grid>
        </Grid>

        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Price")}
            </Typography>
            {renderPriceValue(item, { parseCurrency })}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Index")}
            </Typography>
            {renderIndexConnection(item, { parseDate, parseCurrency })}
          </Grid>
        </Grid>

        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Incoterms")}
            </Typography>
            {item.incoterms || "-"}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Products_EstPrice") + "/mt"}
            </Typography>
            {renderEstimatedPrice(item, { parseCurrency })}
          </Grid>
        </Grid>
        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={6}>
            {" "}
            <Typography variant="body2" className={classes.subtitle}>
              {t("Account_ContractType")}
            </Typography>
            {item.contractType || "-"}{" "}
          </Grid>

          <Grid item xs={6}>
            {item.statusId === 1 ? (
              <>
                <Typography variant="body2" className={classes.subtitle}>
                  {t("Materials_ContractEndDate")}
                </Typography>
                {(item?.contractStartDate &&
                  parseDate(item?.contractStartDate)) ||
                  ""}
                {" - "}
                {(item?.contractEndDate && parseDate(item?.contractEndDate)) ||
                  ""}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.descriptionContainer}>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Payment Terms")}
            </Typography>
            <LimitedTextWithMoreButton
              text={item.paymentTerms}
              maxCharacters={150}
              expanded={false}
            />
          </Grid>
        </Grid>
        {/*<Grid container className={classes.descriptionContainer}>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.subtitle}>
              {t("Fixation Terms")}
            </Typography>
            <LimitedTextWithMoreButton
              text={item.fixationTerms}
              maxCharacters={150}
              expanded={false}
            />
          </Grid>
            </Grid>*/}
        <div className={classes.buttonContainer}>
          {item.statusId !== 2 && showPickupRequest && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => actualOnEventRequest("button")}
            >
              {item.statusId === 1 ? t("Request Pickup") : t("Sale Request")}
            </Button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalExpandedCard;
