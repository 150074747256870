export const validFiles = async fileListArray => {
  let validFilesArray = [];
  let corrupted = false;
  for (const file of fileListArray) {
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension === "pdf") {
      const isValidPdf = await validatePdfFile(file);
      if (!isValidPdf) {
        corrupted = true;
        continue;
      }
    }
    validFilesArray.push(file);
  }
  return { validFilesArray, corrupted };
};

const validatePdfFile = async file => {
  try {
    const fileUrl = URL.createObjectURL(file);

    const loadingFile = pdfjsLib.getDocument({
      url: fileUrl,
      cMapPacked: true
    });

    await loadingFile.promise;

    URL.revokeObjectURL(fileUrl);

    return true;
  } catch (error) {
    return false;
  }
};
