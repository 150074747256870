import React from "react";
import { Typography, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  materialPreviewHeader: {
    color: "rgba(0, 0, 0, 0.60))",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    marginBottom: "8px"
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    marginBottom: "20px",
    flexDirection: "column"
  },
  row: {
    width: "70%",
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  }
}));

const CompareMaterialAlias = ({ item, addAliasAction }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <TextField
          id="outlined-basic"
          label={t("change_material_name_current_name")}
          variant="outlined"
          fullWidth
          defaultValue={item.alias}
          InputProps={{
            readOnly: true
          }}
        />
      </div>
      <div className={classes.row}>
        <TextField
          id="outlined-basic"
          label={t("change_material_name_new_name")}
          variant="outlined"
          fullWidth
          onKeyUp={e => addAliasAction(e.target.value)}
        />
      </div>
    </div>
  );
};

export default CompareMaterialAlias;
