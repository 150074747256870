import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import GetRequestPickUpInfo from "../../../queries/GetRequestPickUpInfo";
import moment from "moment";
import "moment-timezone";
import DesktopProductsCard from "./DesktopProductsCard";
import ExistingPickUpModal from "../../pickups/PickupsCard/Modals/ExistingPickUpModal";
import { useLazyQuery } from "react-apollo";
import useQueryParams from "../../../hooks/useQueryParams";
import useEventTracking from "../../../hooks/useEventTracking";
import { useRequestPickupHandler } from "./RequestPickUpInfoHandler";
import UserContext from "../../../providers/UserProvider";
import { isDemoEnviroment } from "../../../config/config";

const ProductCard = props => {
  const {
    item,
    isGroupAdmin,
    changeImageAction,
    changeNameAction,
    saleRequestInitializedEvent = () => {}
  } = props;

  const [hasExistingPickups, setHasExistingPickups] = useState(false);
  const queryParams = useQueryParams();
  const itemRef = useRef();
  const { TrackEvent } = useEventTracking();

  const [isExpanded, setExpanded] = useState(() => {
    const conditionId = queryParams.get("conditionId");
    const leadId = queryParams.get("leadId");
    const productId = queryParams.get("productId");
    const conditionIdArray = conditionId?.split(",");

    return (
      (conditionId &&
        item.conditionId &&
        conditionIdArray.includes(item.conditionId.toString())) ||
      (leadId && item.leadId && item.leadId === leadId) ||
      (productId && item.productId && item.productId === parseInt(productId))
    );
  });
  const { user, selectedCompany } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const isDemoEnv = isDemoEnviroment();

  const { handleOnCompleted, handleOnError } = useRequestPickupHandler(
    item,
    history,
    location,
    setHasExistingPickups,
    isDemoEnv
  );

  const [GetPickUpInfo, { loading, data, error }] = useLazyQuery(
    GetRequestPickUpInfo,
    {
      fetchPolicy: "network-only",
      onCompleted: handleOnCompleted,
      onError: handleOnError
    }
  );

  useEffect(() => {
    if (isExpanded && itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const onEventRequest = source => {
    if (item.statusId === 1) {
      TrackEvent("Pick up requested", {
        source: source,
        productId: item.productId,
        quantity: item.quantity,
        conditionsId: item.conditionId
      });
    } else if (item.statusId !== 3) {
      saleRequestInitializedEvent(source, item);
    }

    GetPickUpInfo({
      variables: {
        token: user?.token,
        conditionId: item.conditionId,
        partnerId: selectedCompany
      }
    });
  };

  const disableButton = isGroupAdmin && item.partnerId !== selectedCompany;

  return (
    <>
      <DesktopProductsCard
        item={item}
        onEventRequest={onEventRequest}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        disableButton={disableButton}
        changeImageAction={changeImageAction}
        changeNameAction={changeNameAction}
        saleRequestInitializedEvent={saleRequestInitializedEvent}
      />

      {data?.RequestPickUpInfo?.pickup?._id ? (
        <ExistingPickUpModal
          pickupStatusCondition={data?.RequestPickUpInfo?.pickupStatusCondition}
          pickupId={data?.RequestPickUpInfo?.pickup?._id}
          item={data?.RequestPickUpInfo?.pickup}
          productId={item.old_id}
          conditionId={item.conditionId}
          contractStartDate={moment(item?.contractStartDate).format(
            "YYYY-MM-DD"
          )}
          contractEndDate={moment(item?.contractEndDate).format("YYYY-MM-DD")}
          open={hasExistingPickups}
          onClose={() => setHasExistingPickups(false)}
          {...item?.pickups} // confirmation_required, pre_scheduled, confirmed,
        />
      ) : (
        <> </>
      )}
    </>
  );
};

export default ProductCard;
