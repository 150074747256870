import React, { Fragment, useState } from "react";
import TransactionDesktopCart from "../TransactionDesktopCart";
import { Typography, Button, Hidden } from "@material-ui/core";
import TransactionsGallery from "../../../core/shared/TransactionsGallery";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import GetAppIcon from "@material-ui/icons/GetApp";
import useEventTracking from "../../../../hooks/useEventTracking";
import Status from "../../../general/Status";
import FileList from "../../../core/shared/FileList";
import AddIcon from "@material-ui/icons/Add";
import TransactionsCard from "../TransactionsCard";
import useFilePartition from "../../../../hooks/useFilePartition";
import UploadDialog from "../../../core/shared/uploadDialog";
import moment from "moment";
import { Info } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {
  SALES_STATUS,
  SALES_STATUS_TRANSPORT_DELIVERED
} from "../../../../utils/constants";
import { WEIGHTNOTE_KEY } from "../../../core/shared/uploadDialog/steps";

const useStyles = makeStyles(theme => ({
  wrapper: {
    "& .padded": {
      padding: "0 16px"
    },
    "& .topContainer": {
      margin: "0 0 24px"
    }
  },
  ourComment: {
    marginBottom: "24px"
  },
  clip: {
    color: "#666666",
    marginRight: "10px"
  },

  downloadInvoices: {
    display: "flex",
    textDecoration: "none",
    fontSize: "14px",
    color: "#276EF1",
    lineHeight: "20px",
    fontWeight: "400",
    letterSpacing: "0.25px",
    alignItems: "center",
    "&:visited": {
      color: "#276EF1"
    },
    [theme.breakpoints.down("sm")]: {
      "&:first-child": {
        marginTop: "12px"
      }
    }
  },
  downloadFilesLink: {
    display: "flex",
    textDecoration: "none",
    fontSize: "14px",
    color: "#276EF1",
    lineHeight: "20px",
    fontWeight: "400",
    letterSpacing: "0.25px",
    alignItems: "center",
    "&:visited": {
      color: "#276EF1"
    }
  },
  sectionContainer: {
    // display: "flex",
    fontSize: "14px",

    color: "rgba(0, 0, 0, 0.6)",

    "&>p": {
      marginRight: "5px",
      marginBottom: "4px"
    }
  },
  subtitles: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    marginBottom: "8px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  downloadFilesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      flex: "0 0 100%",
      width: "100%"
    }
  },
  deliveredContainer: {
    backgroundColor: "#F9F9F9",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.60)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "22px 24px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0",
      padding: "20px 16px"
    }
  },
  black60: {
    color: "rgba(0, 0, 0, 0.60)"
  },
  black87: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  deliveredContainerWrap: {
    padding: "0 16px",
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      order: 1,
      padding: "0",
      marginBottom: "24px"
    }
  },
  statuses: {
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  statusBar: {
    flex: "none",
    paddingLeft: "0 !important",
    "&:not(:last-child)": {
      paddingRight: "8px !important"
    },
    "&:last-child": {
      paddingRight: "0 !important"
    },
    "& .status_button": {
      width: "136px"
    },
    "&.progress:after, &:first-child.progress:after": {
      left: 0,
      width: "calc(100% - 8px)"
    },
    "&:last-child.progress:after": {
      width: "100%"
    },
    "&:not(:first-child).progress:before": {
      background: "#d9d9d9"
    },
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      "&:first-child": {
        paddingLeft: "16px !important"
      },
      "& .status_button": {
        width: "100%"
      }
    }
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column"
  },
  columns: {
    display: "flex",
    flexWrap: "wrap",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      gap: "24px"
    }
  },
  column: {
    width: "400px",
    flex: "0 0 400px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: "100%"
    }
  },
  statusWrapper: {
    marginBottom: "24px",
    padding: "20px 16px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      marginBottom: "14px"
    }
  },
  statusWrapper__body: {
    padding: "24px 24px 14px 24px",
    background: "#F9F9F9",
    width: "100%",
    maxWidth: canSeePricingInfo => (canSeePricingInfo ? "1048px" : "750px"),
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      background: "transparent",
      borderRadius: "0"
    }
  },
  statusHeading: {
    margin: "0 0 9px 0",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 8px 16px"
    }
  },
  fileWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px"
    }
  },
  transactionCardWrapper: {
    padding: "0 16px",
    width: "100%",
    maxWidth: "1080px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  transactionCardRow: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -10px",
    [theme.breakpoints.down("sm")]: {
      margin: "-10px 0"
    }
  },
  transactionCardCol: {
    flex: "0 0 50%",
    width: "50%",
    padding: "0 10px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 0 100%",
      width: "100%",
      padding: "10px 0"
    }
  },
  weightNoteBtn: {
    margin: "16px 16px 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 16px 0"
    }
  },
  documentBtn: {
    margin: "16px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 24px 0"
    }
  },
  btnGroups: {
    display: "flex",
    justifyContent: "flex-end"
  },
  disabled: {
    pointerEvents: "none",
    opacity: "0.5",
    filter: "grayscale(100%)"
  },
  tooltip: {
    maxWidth: "320px",
    marginLeft: "4px",
    pointerEvents: "auto !important",
    opacity: "1 !important",
    filter: "none !important"
  },
  tooltipAlign: {
    display: "flex",
    alignItems: "center"
  }
}));

const TransactionDesktopDetail = props => {
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const {
    order,
    type,
    isSeller,
    canSeePricingInfo = true,
    uploadInitialType = null
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(
    uploadInitialType ? true : false
  );

  const classes = useStyles(canSeePricingInfo);

  const invoiceUrl = order.invoiceUrl;
  const photos = order.images;
  const weightnote = order.weightnote;
  const currentStatusIndex =
    order && order.statusId ? SALES_STATUS[order.statusId] : 1;
  const statusData = order.statusData || {};
  const isAnImportedTransaction = order.importedTransaction;
  const todayText = moment().format("DD.MM.YYYY");

  const statusElements = [
    {
      title: "1." + t("Pickup"),
      className: classes.statusBar,
      position: 1,
      text: `${t(statusData.text)} ${
        isAnImportedTransaction
          ? `[${todayText}]`
          : statusData.period
            ? `[${statusData.t ? t(statusData.period) : statusData.period}]`
            : ""
      }`
    },
    {
      title: "2." + t("Status_Transport"),
      className: classes.statusBar,
      position: 2,
      text: t("Pickups_Organisation")
    },
    {
      title: "3." + t("Status_Transport"),
      className: classes.statusBar,
      position: 3,
      text: t("Documents")
    },
    {
      title: "4." + t("Status_Transport"),
      className: classes.statusBar,
      position: 4,
      text: t("In transit")
    },
    {
      title: "5." + t("Status_Transport"),
      position: 5,
      className: classes.statusBar,
      text: t("Sales_Delivered")
    },
    canSeePricingInfo && {
      title: "6." + t("Payment"),
      position: 6,
      className: classes.statusBar,
      text: t("In progress")
    },
    canSeePricingInfo && {
      title: "7." + t("Sale"),
      position: 7,
      className: classes.statusBar,
      text: t("Completed")
    }
  ];
  // partition files into my document and metaloop document
  const [metaloopDocuments, myDocuments] = useFilePartition(order.documents);

  return (
    <div className={classes.wrapper}>
      {/* statuses */}
      <div className={classes.statusWrapper}>
        <div className={`${classes.statusWrapper__body} `}>
          <div className={classes.tooltipAlign}>
            <div
              className={`${classes.statusHeading} ${
                isAnImportedTransaction && ` ${classes.tooltipAlign}`
              }`}
            >
              <span
                className={`${
                  isAnImportedTransaction && ` ${classes.disabled}`
                }`}
              >
                {" "}
                {t("status")}
              </span>
              {isAnImportedTransaction && (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {t("tooltip_enterprise_status")}
                    </span>
                  }
                  className={classes.tooltip}
                >
                  <Info className={classes.icon} />
                </Tooltip>
              )}
            </div>
          </div>
          <Status
            className={`${classes.statuses} ${
              isAnImportedTransaction && classes.disabled
            }`}
            currentIndex={
              isAnImportedTransaction
                ? 7
                : !canSeePricingInfo &&
                    currentStatusIndex > SALES_STATUS_TRANSPORT_DELIVERED
                  ? SALES_STATUS_TRANSPORT_DELIVERED
                  : currentStatusIndex
            }
            statusElements={statusElements}
          />
        </div>
      </div>

      <div className="padded topContainer">
        <div className={classes.columns}>
          <div className={classes.column}>
            <Typography variant="h6" className={classes.subtitles}>
              {t("Transaction information")}
            </Typography>
            {isAnImportedTransaction ? (
              <div className={classes.sectionContainer}>
                <Typography variant="body2" className={classes.black60}>
                  {t("col_head_site_name") + ": "}{" "}
                  <span className={classes.black87}>{order.deliveryPlace}</span>
                </Typography>
                <Typography variant="body2" className={classes.black60}>
                  {t("Collection date") + ":"}{" "}
                  <span className={classes.black87}>{order.pickupDate}</span>
                </Typography>
              </div>
            ) : (
              <div className={classes.sectionContainer}>
                <Typography variant="body2" className={classes.black60}>
                  {t("Transport option") + ":"}{" "}
                  <span className={classes.black87}>
                    {t(order.transportOption)}
                  </span>
                </Typography>
                <Typography variant="body2" className={classes.black60}>
                  {t("Address") + ": "}{" "}
                  <span className={classes.black87}>{order.deliveryPlace}</span>
                </Typography>
                <Typography variant="body2" className={classes.black60}>
                  {t("Collection date") + ":"}{" "}
                  <span className={classes.black87}>{order.pickupDate}</span>
                </Typography>
                <Typography variant="body2" className={classes.black60}>
                  {t("Delivery date") + ":"}{" "}
                  <span className={classes.black87}>{order.deliveryDate}</span>
                </Typography>
              </div>
            )}

            {photos && photos.length ? (
              <TransactionsGallery photos={photos} />
            ) : null}
          </div>
          <div className={classes.column}>
            <Typography
              variant="h6"
              className={`${classes.subtitles} ${
                isAnImportedTransaction && classes.tooltipAlign
              }`}
            >
              <span
                className={`${
                  isAnImportedTransaction && ` ${classes.disabled}`
                }`}
              >
                {t("Schrott24 comments")}
              </span>
              {isAnImportedTransaction && (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {t("tooltip_enterprise_comments")}
                    </span>
                  }
                  className={classes.tooltip}
                >
                  <Info className={classes.icon} />
                </Tooltip>
              )}
            </Typography>

            <Typography
              variant="body2"
              className={`${classnames(
                classes.ourComment,
                classes.black60
              )} ${isAnImportedTransaction && classes.disabled}`}
            >
              {" "}
              {props.order.ourComment ? props.order.ourComment : null}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.flexContainer}>
        <TransactionDesktopCart
          cart={order.cart}
          address={order.deliveryPlace}
          date={order.date}
          transportOption={order.transportOption}
          statusId={order.statusId}
          isSeller={isSeller}
          canSeePricingInfo={canSeePricingInfo}
        />
        {type === "closed" ? (
          <div className={classes.deliveredContainerWrap}>
            <div className={classes.deliveredContainer}>
              <div>
                {t("Delivered on") +
                  ":" +
                  (order.deliveryDate &&
                    order.deliveryDate.split("/").join("."))}
              </div>
              <div className={classes.downloadFilesContainer}>
                {!isSeller && weightnote && weightnote.length ? (
                  <a
                    target="_blank"
                    href={weightnote}
                    download
                    className={classes.downloadInvoices}
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <GetAppIcon className={classes.clip} /> {"weightnote"}
                  </a>
                ) : null}{" "}
                {invoiceUrl && invoiceUrl.length ? (
                  <a
                    target="_blank"
                    href={invoiceUrl}
                    download
                    className={classes.downloadInvoices}
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <GetAppIcon className={classes.clip} />
                    {isSeller ? "Creditnote" : "Invoice"}
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {uploadDialogOpen ? (
        <UploadDialog
          orderId={order._id}
          transactionId={order.transactionID}
          internalTransactionId={order.internalTransactionId}
          orderType="sale"
          onClose={() => setUploadDialogOpen(false)}
          seller={true}
          initialType={uploadInitialType}
        />
      ) : null}

      {modalIsOpen ? (
        <Fragment>
          <UploadDialog
            orderId={order._id}
            transactionId={order.transactionID}
            internalTransactionId={order.internalTransactionId}
            orderType="sale"
            onClose={() => setModalIsOpen(false)}
            initialType={WEIGHTNOTE_KEY}
            seller={true}
          />
        </Fragment>
      ) : null}

      {/* files */}
      <div className={classes.fileWrapper}>
        <Hidden mdUp>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            fullWidth
            onClick={() => {
              TrackEvent("Upload document flow started", {
                orderId: order._id,
                clickSource: "Button"
              });
              setModalIsOpen(true);
            }}
            className={classes.weightNoteBtn}
          >
            {t("WEIGHTNOTE")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            fullWidth
            onClick={() => {
              TrackEvent("Upload document flow started", {
                orderId: order._id,
                clickSource: "Button"
              });
              setUploadDialogOpen(true);
            }}
            className={classes.documentBtn}
          >
            {t("DOCUMENTS")}
          </Button>
        </Hidden>
        {/* Transaction cards */}
        <div className={classes.transactionCardWrapper}>
          <div className={classes.transactionCardRow}>
            <div className={classes.transactionCardCol}>
              <TransactionsCard
                title={t("Metaloop Documents")}
                className={classes.transactionCard}
                tooltipText={
                  isAnImportedTransaction
                    ? t("tooltip_enterprise_met_docs")
                    : null
                }
                disabled={isAnImportedTransaction}
              >
                <FileList files={metaloopDocuments} orderId={order.id} />
              </TransactionsCard>
            </div>
            <div className={classes.transactionCardCol}>
              <TransactionsCard
                title={t("My Documents")}
                className={classes.transactionCard}
                tooltipText={
                  isAnImportedTransaction
                    ? t("tooltip_enterprise_my_docs")
                    : null
                }
                disabled={isAnImportedTransaction}
              >
                <>
                  <FileList files={myDocuments} orderId={order.id} />
                  <Hidden smDown>
                    <div className={classes.btnGroups}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setModalIsOpen(true)}
                        className={classes.weightNoteBtn}
                      >
                        {t("WEIGHTNOTE")}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setUploadDialogOpen(true)}
                        className={classes.documentBtn}
                      >
                        {t("DOCUMENTS")}
                      </Button>
                    </div>
                  </Hidden>
                </>
              </TransactionsCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDesktopDetail;
