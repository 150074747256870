import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { getCurrencySymbol } from "../components/core/shared/CurrencyFormat";
import { DATE_FORMAT_STRING } from "./Format";

export const exportToCSV = (data, fileName) => {
  const fileType = "text/csv;charset=utf-8";
  const fileExtension = ".csv";
  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
  const csvOutput = XLSX.utils.sheet_to_csv(ws);
  const blob = new Blob([csvOutput], { type: fileType });

  FileSaver.saveAs(blob, fileName + fileExtension);
};

export const exportToXLSX = (data, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blob, fileName + fileExtension);
};

export const transactionListToAoA = (transactions, props, t) => {
  const { transactionType } = props;
  const isSeller = transactionType === "sale";
  const aoa = [];

  moment.locale("en");

  const quantityFields = isSeller
    ? [
        "Declared Quantity (mt)",
        "Invoiced Quantity (mt)",
        "Quantity Difference (mt)"
      ]
    : ["Metric Tonnage"];

  aoa.push([
    "Collection Date",
    "Month",
    "Year",
    "Supplier Name",
    "Plant Name",
    "Metaloop Transaction ID",
    "Internal Transaction ID",
    "Base Metal",
    "Material Description",
    ...quantityFields,
    "Price per Metric Tonnes",
    "Total price",
    "Currency"
  ]);

  transactions.forEach(transaction => {
    const collectionDate = moment(transaction.pickupDate, DATE_FORMAT_STRING);

    if (transaction.cart) {
      transaction.cart.forEach((item, index) => {
        const row = [];
        row.push(collectionDate.format("DD.MM.YYYY"));
        row.push(collectionDate.format("M"));
        row.push(collectionDate.format("YYYY"));
        row.push(transaction.source || "Metaloop");
        row.push(transaction.location);
        row.push(transaction.transactionID);
        row.push(transaction.internalTransactionId);
        row.push(t(item.baseMetalName));
        row.push(item.material);
        if (isSeller) {
          let { amount, invoicedAmount } = item;
          const difference = amount - invoicedAmount;

          row.push(isNaN(amount) ? "-" : amount / 1000);
          row.push(
            invoicedAmount === null || isNaN(invoicedAmount)
              ? "-"
              : invoicedAmount / 1000
          );
          row.push(isNaN(difference) ? "-" : difference / 1000);
        } else {
          row.push(isNaN(item.amount) ? "-" : item.amount / 1000);
        }
        row.push(
          (() => {
            const price = isSeller ? item.pricePurchase : item.priceAmount;
            return isNaN(price) ? "-" : price * 1000;
          })()
        );
        row.push(
          (() => {
            if (
              isNaN(item.amount) ||
              (isSeller &&
                (!item.pricePurchase || isNaN(item.pricePurchase))) ||
              (!isSeller && (!item.priceAmount || isNaN(item.priceAmount)))
            )
              return "-";

            return isSeller
              ? Math.round(item.pricePurchase * item.amount * 100) / 100
              : Math.round(item.priceAmount * item.amount * 100) / 100;
          })()
        );
        row.push(getCurrencySymbol(props));
        aoa.push(row);
      });
    } else {
      const row = [];
      row.push(collectionDate.format("DD.MM.YYYY"));
      row.push(collectionDate.format("M"));
      row.push(collectionDate.format("YYYY"));
      row.push(transaction.source || "Metaloop");
      row.push(transaction.location);
      row.push(transaction.transactionID);
      row.push(transaction.partnerTransactionID);
      aoa.push(row);
    }
  });
  return aoa;
};
