import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  panelCol: {
    flexBasis: "50%",
    width: "50%"
  },
  panelCol2: {
    flexBasis: "100%",
    width: "100%"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  panelTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0"
  },
  panelDescription: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  panelDescription__mb: {
    marginBottom: "8px"
  },
  [theme.breakpoints.up("md")]: {
    panelCol: {
      flexBasis: "188px",
      width: "188px"
    },
    panelCol2: {
      flexBasis: "188px",
      width: "188px"
    },
    panelDescription__mb: {
      marginBottom: "16px"
    }
  }
}));

const PickUpPanel = ({
  title,
  variant, // half or full
  className,
  hasMarginBottom,
  description,
  children
}) => {
  const classes = useStyles();

  const defaultClass =
    variant === "half" ? classes.panelCol : classes.panelCol2;

  return (
    <div
      className={classnames(
        className ? className : defaultClass,
        classes.detailColRoot
      )}
    >
      <div>
        {title && <p className={classes.panelTitle}>{title}</p>}
        {!children ? (
          <p
            className={classnames(
              classes.panelDescription,
              hasMarginBottom ? classes.panelDescription__mb : ""
            )}
          >
            {description}
          </p>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

PickUpPanel.defaultProps = {
  variant: "half"
};

export default PickUpPanel;
