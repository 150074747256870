import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Hidden, Typography, Button } from "@material-ui/core";
import TransactionsGallery from "../../../core/shared/TransactionsGallery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import TransactionDesktopCart from "../TransactionDesktopCart";
import FileList from "../../../core/shared/FileList";
import TransactionsCard from "../TransactionsCard";
import useFilePartition from "../../../../hooks/useFilePartition";
import UploadDialog from "../../../core/shared/uploadDialog";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useEventTracking from "../../../../hooks/useEventTracking";
import { WEIGHTNOTE_KEY } from "../../../core/shared/uploadDialog/steps";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "32px 0"
  },
  infoContainer: {
    paddingLeft: 16,
    marginBottom: 24,
    "& > div": {
      maxWidth: 400,
      whiteSpace: "normal"
    }
  },
  ourComment: {
    marginBottom: "24px"
  },
  clip: {
    marginRight: "8px"
  },
  downloadFilesLink: {
    display: "flex",
    textDecoration: "none",
    fontSize: "14px",
    color: "#276EF1",
    alignItems: "center",
    "&:visited": {
      color: theme.palette.secondary.main
    }
  },
  sectionContainer: {
    display: "flex",
    fontSize: "14px",

    color: "rgba(0, 0, 0, 0.6)",

    "&>p": {
      marginRight: "5px",
      marginBottom: "4px"
    }
  },
  secondColumn: {
    paddingLeft: "32px"
  },
  subtitles: {
    fontSize: "16px",
    marginBottom: "8px"
  },
  documentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  buttonsWrapper: {
    marginTop: "20px",
    display: "flex",
    gap: "16px"
  },
  addIcon: {
    marginRight: "6px"
  },
  black60: {
    color: "rgba(0, 0, 0, 0.60)"
  },
  black87: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  transactionCardWrapper: {
    padding: "0 16px",
    width: "100%",
    maxWidth: "1080px",
    marginBottom: "20px"
  },
  transactionCardRow: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -10px"
  },
  transactionCardCol: {
    flex: "0 0 50%",
    width: "50%",
    padding: "0 10px"
  },
  weightNoteBtn: {
    margin: "16px 16px 0 0"
  },
  documentBtn: {
    margin: "16px 0 0 0"
  },
  btnGroups: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const PurchaseDesktopInformation = props => {
  const {
    order,
    photos,
    isSeller,
    partnerUploads,
    weightnote,
    type,
    canSeePricingInfo,
    uploadInitialType = null
  } = props;

  const theme = useTheme();

  const classes = useStyles();

  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [modalIsOpen, setModalIsOpen] = useState(props.openModal);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(
    uploadInitialType ? true : false
  );

  const [step, setStep] = useState(0);

  // partition files into my document and metaloop document
  const [metaloopDocuments, myDocuments] = useFilePartition(order.documents);

  //We chech if the weigth note dialog should be open
  const location = useLocation();
  const history = useHistory();

  const query = queryString.parse(location.search);
  const isOpenWeightNote = query.openWeightNoteDialog;
  if (!modalIsOpen && isOpenWeightNote) {
    //We open the dialog
    setModalIsOpen(true);
    delete query.openWeightNoteDialog;
    //We replace the param from the query to avoid opening the dialog again
    history.replace({
      pathname: location.pathname || "/",
      search: queryString.stringify(query)
    });
  }

  return (
    <div container className={classes.container}>
      <Grid container item sm={12} className={classes.infoContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.subtitles}>
            {t("Transaction information")}
          </Typography>
          <div className={classes.sectionContainer}>
            <Typography variant="body2" className={classes.black60}>
              {t("Transport option") + ":"}
            </Typography>{" "}
            <Typography variant="body2" className={classes.black87}>
              {t(order.transportOption)}
            </Typography>{" "}
          </div>
          <div className={classes.sectionContainer}>
            {" "}
            <Typography variant="body2" className={classes.black60}>
              {t("Address") + ": "}
            </Typography>{" "}
            <Typography variant="body2" className={classes.black87}>
              {order.deliveryPlace}
            </Typography>{" "}
          </div>
          <div className={classes.sectionContainer}>
            {" "}
            <Typography variant="body2" className={classes.black60}>
              {t("Collection date") + ":"}
            </Typography>{" "}
            <Typography variant="body2" className={classes.black87}>
              {order.pickupDate}
            </Typography>{" "}
          </div>
          {photos && photos.length ? (
            <TransactionsGallery photos={photos} />
          ) : null}
        </Grid>
        <Grid item className={classes.secondColumn}>
          <Typography variant="h6" className={classes.subtitles}>
            {t("Schrott24 comments")}
          </Typography>
          <Typography
            variant="body2"
            className={classnames(classes.ourComment, classes.black60)}
          >
            {props.order.ourComment
              ? props.order.ourComment
              : t("No comments here")}
          </Typography>

          <Typography variant="h6" className={classes.subtitles}>
            {t("Your comments")}
          </Typography>
          <Typography
            variant="body2"
            className={classnames(classes.ourComment, classes.black60)}
          >
            {props.order.weightnoteComment
              ? props.order.weightnoteComment
              : t("No comments here")}
          </Typography>
        </Grid>
      </Grid>

      {uploadDialogOpen ? (
        <UploadDialog
          orderId={order._id}
          transactionId={order.transactionID}
          internalTransactionId={order.internalTransactionId}
          orderType="purchase"
          onClose={() => setUploadDialogOpen(false)}
          seller={false}
          initialType={uploadInitialType}
        />
      ) : null}

      {modalIsOpen ? (
        <UploadDialog
          orderId={order._id}
          transactionId={order.transactionID}
          internalTransactionId={order.internalTransactionId}
          orderType="purchase"
          onClose={() => setModalIsOpen(false)}
          initialType={WEIGHTNOTE_KEY}
          seller={false}
        />
      ) : null}

      <div className={classes.tableWrapper}>
        <TransactionDesktopCart
          cart={order.cart}
          address={order.deliveryPlace}
          date={order.date}
          transportOption={order.transportOption}
          statusId={order.statusId}
          isSeller={isSeller}
          canSeePricingInfo={canSeePricingInfo}
        />
      </div>
      {/* Transaction cards */}
      <div className={classes.transactionCardWrapper}>
        <div className={classes.transactionCardRow}>
          <div className={classes.transactionCardCol}>
            <TransactionsCard
              title={t("Metaloop Documents")}
              className={classes.transactionCard}
            >
              <FileList files={metaloopDocuments} orderId={order.id} />
            </TransactionsCard>
          </div>
          <div className={classes.transactionCardCol}>
            <TransactionsCard
              title={t("My Documents")}
              className={classes.transactionCard}
            >
              <FileList files={myDocuments} orderId={order.id} />
              {type === "open" && (
                <Hidden smDown>
                  <div className={classes.btnGroups}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        TrackEvent("Upload document flow started", {
                          orderId: order._id,
                          clickSource: "Button"
                        });
                        setModalIsOpen(true);
                      }}
                      className={classes.weightNoteBtn}
                    >
                      {t("WEIGHTNOTE")}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        TrackEvent("Upload document flow started", {
                          orderId: order._id,
                          clickSource: "Button"
                        });
                        setUploadDialogOpen(true);
                      }}
                      className={classes.documentBtn}
                    >
                      {t("DOCUMENTS")}
                    </Button>
                  </div>
                </Hidden>
              )}
            </TransactionsCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseDesktopInformation;
