import React from "react";
import { Modal, Button, makeStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import useEventTracking from "../../../hooks/useEventTracking";
import ImgSvg from "../../../assets/images/fillings.png";
import PickUpCardImageWrapper from "./shared/PickUpCardImageWrapper";
import PickUpHeader from "./shared/PickUpHeader";
import PickUpPanel from "./shared/PickUpPanel";
import PickUpDocument from "./shared/PickUpDocument";
import useFilePartition from "../../../hooks/useFilePartition";
import PickUpDownloadAllButton from "./shared/PickUpDownLoadAllButton";
import PickUpStatusBars from "./shared/PickUpStatusBars";
import PickUpUpload from "./shared/PickUpUpload";

const useStyles = makeStyles(theme => ({
  root: {},

  modalWrapper: {
    background: "#fff",
    height: "100%"
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    "& p": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  modalBody: {
    padding: "0 16px",
    height: "calc(100vh - 40px)",
    overflowY: "auto"
  },
  ctaWrap: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    gap: "16px"
  },
  documents: {
    marginTop: "12px"
  },
  statusBars: {
    margin: "24px 0 10px 0"
  },
  buttonGroups: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    justifyContent: "flex-end",
    marginTop: "24px"
  },
  modalGap: {
    gap: "12px"
  }
}));

const ModalPickupCardDisplay = ({
  open,
  onClose,
  className,
  classes,
  item,
  user,
  selectedCompany,
  ariaDescribedby,
  parentCardConditions,
  parentClasses
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { parseDate, parseNumber } = useStandardLocalization();
  const pickUpDateFormatted = parseDate(
    item.pickupDate || item.pickupOrderDate
  );
  const [metaloopDocuments, myDocuments] = useFilePartition(item.documents);
  const { TrackEvent } = useEventTracking();

  const tClasses = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={item.pickupName}
      aria-describedby={ariaDescribedby}
      className={className}
    >
      <div className={tClasses.modalWrapper}>
        {/* modal header */}
        <div className={tClasses.header}>
          <IconButton aria-label="close modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <p>{item.pickupName}</p>
        </div>

        <div className={tClasses.modalBody}>
          <div className={classnames(classes.row, classes.gridRoot)}>
            {/* image wrapper */}
            <PickUpCardImageWrapper
              imageHeight="100px"
              imageSrc={item.imageSrc || ImgSvg}
              isContracted={item.isContracted}
              badgePosition={{
                top: "12px",
                left: "0"
              }}
            />

            <div className={classes.detailCol}>
              <div className={classnames(classes.row, classes.detailGridRoot)}>
                <PickUpHeader
                  pickUpDateFormatted={pickUpDateFormatted}
                  parentClasses={parentClasses}
                  cardConditions={parentCardConditions}
                  pickupName={item.aliasForLanguage || item.pickupName}
                />

                <PickUpPanel title={t("PICKUP ADDRESS")}>
                  <p className={classnames(classes.panelDescription)}>
                    {item.companyName}
                    <br /> {item.streetAddress}
                    <br /> {item.zipCode} {item.city}
                    <br /> {item.country}
                  </p>
                </PickUpPanel>

                <div
                  className={classnames(
                    classes.panelWrapCol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.row}>
                    <div className={classes.fullBasis}>
                      <div
                        className={classnames(
                          classes.row,
                          classes.detailGridRoot,
                          tClasses.modalGap
                        )}
                      >
                        <PickUpPanel title={t("Transaction ID")} variant="full">
                          <p className={classes.panelDescription}>
                            {item.transactionId}
                            {item.internalTransactionId && (
                              <>
                                {" "}
                                /
                                <br />
                                {item.internalTransactionId}
                              </>
                            )}
                          </p>
                        </PickUpPanel>
                        <PickUpPanel
                          title={`${t("Quantity")}(mt)`}
                          variant="full"
                        >
                          <p className={classnames(classes.panelDescription)}>
                            {parseNumber(item.quantity / 1000)}
                          </p>
                        </PickUpPanel>
                        <PickUpPanel
                          title={t("Pickups_TransportDetails")}
                          variant="full"
                        >
                          <p className={classnames(classes.panelDescription)}>
                            {item.truckNumberPlate && item.truckType ? (
                              <>
                                {item.truckNumberPlate}
                                <br />
                                {item.truckType}
                              </>
                            ) : (
                              "-"
                            )}
                          </p>
                        </PickUpPanel>
                        <PickUpPanel
                          title={t("Products_Packaging")}
                          variant="full"
                        >
                          <p className={classnames(classes.panelDescription)}>
                            {t(item.packaging) || "-"}
                          </p>
                        </PickUpPanel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>

          <PickUpDocument
            className={tClasses.documents}
            title={t("Metaloop Documents")}
            orderId={item._id}
            documents={metaloopDocuments}
          />

          <PickUpDocument
            className={tClasses.documents}
            title={t("My Documents")}
            orderId={item._id}
            documents={myDocuments}
            showDeleteIcon
          />

          <div className={tClasses.buttonGroups}>
            <PickUpDownloadAllButton
              sellerId={selectedCompany}
              token={user?.token}
              orderId={item._id}
              fullWidth={false}
            />
            {/* upload component */}
            <PickUpUpload
              item={item}
              user={user}
              selectedCompany={selectedCompany}
              fullWidth={false}
            />
          </div>

          {/* status here */}
          <PickUpStatusBars item={item} className={tClasses.statusBars} />

          <div className={tClasses.ctaWrap}>
            {item.canConfirmPickup && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  TrackEvent("Pick up confirmation started", {
                    clickSource: "button",
                    orderId: item._id
                  });
                  history.push(`/pickups?confirm=${item._id}`, {
                    background: location
                  });
                }}
              >
                {t("Pickups_ConfirmPickup")}
              </Button>
            )}
            {item.canReschedulePickup && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(`/pickups?reschedule=${item._id}`, {
                    background: location
                  });
                }}
              >
                {t("Pickups_ReschedulePickup")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalPickupCardDisplay.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  ariaDescribedby: PropTypes.string
};

ModalPickupCardDisplay.defaultProps = {
  open: false,
  ariaLabelledby: "pick up mobile view",
  ariaDescribedby: "pick up card mobile view when expanded",
  className: ""
};

export default ModalPickupCardDisplay;
