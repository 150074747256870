import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  DialogContentText
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Clear as ClearIcon } from "@material-ui/icons";

import classNames from "classnames";
import { useCallback, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InformationalComponent from "../../../../general/InformationalComponent";
import CompareMaterialAlias from "./CompareMaterialAlias";

import { useNotifications } from "../../../../../hooks/Notifications";

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "840px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "16px"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "0",
      position: "fixed",
      background: "white"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        padding: "0"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },

  actionButtons: {
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      paddingTop: "24px!important"
    }
  },
  subHeader: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  subHeaderText: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px" /* 142.857% */,
    letterSpacing: "0.25px"
  },
  informationalComponent: {
    [theme.breakpoints.down("sm")]: {
      margin: "12px 16px 20px 16px"
    },
    margin: "8px 24px 12px 24px",
    maxWidth: "792px"
  },

  bottomText: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px", // 142.857%
    letterSpacing: "0.25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      marginTop: "20px",
      marginBottom: "88px"
    }
  },
  hereClass: {
    color: "var(--states-more-blue, #276EF1)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },

  closeButton: {
    color: "#767676",
    padding: "16px"
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px !important"
    }
  }
}));

export default props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { newAlias, setNewAlias } = props;
  const [disableButton, setDisableButton] = useState(true);
  const { pushNotification } = useNotifications();
  const { t } = useTranslation();

  const addAliasAction = alias => {
    if (!alias) {
      setDisableButton(true);
      setNewAlias(null);
    } else {
      setDisableButton(false);
      setNewAlias(alias);
    }
  };
  const ownVariables = {
    disableButton,
    addAliasAction
  };
  const newProps = { ...props, ...ownVariables };

  if (isMobile) {
    return <Mobile {...newProps} />;
  } else {
    return <Desktop {...newProps} />;
  }
};

const Desktop = ({
  onClose,
  item,
  onSubmit,
  onReset,
  disableButton,
  addAliasAction
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            {t("change_material_name_title")}
          </Typography>

          <IconButton
            aria-label="delete"
            onClick={onClose}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className={classes.subHeader}>
          <DialogContentText>
            {t("change_material_name_subtitle")}
          </DialogContentText>
        </div>
      </div>

      <>
        <InformationalComponent
          type="warning"
          className={classes.informationalComponent}
        >
          <Typography variant={"body1"}>{t("newMatImg_warning")}</Typography>
        </InformationalComponent>
        <DialogContent className={classNames(classes.root)}>
          <CompareMaterialAlias item={item} addAliasAction={addAliasAction} />

          <Typography className={classes.bottomText}>
            {t("change_material_name_back_to_default") + " "}
            <span className={classes.hereClass} onClick={onReset}>
              {t("here")}
            </span>
          </Typography>
        </DialogContent>

        <DialogActions className={classes.actionButtons}>
          <Button
            color="primary"
            classes={{ root: classes.buttonRoot }}
            variant="outlined"
            onClick={() => {
              addAliasAction(null);
              onClose();
            }}
          >
            {t("newMatImg_cancel")}
          </Button>
          <Button
            classes={{ root: classes.buttonRoot }}
            elevation={0}
            color="secondary"
            variant="contained"
            onClick={onSubmit}
            disabled={disableButton}
          >
            {t("newMatImg_saveAndExit")}
          </Button>
        </DialogActions>
      </>
    </>
  );
};

const Mobile = ({
  onClose,
  item,
  onSubmit,
  onReset,
  disableButton,
  addAliasAction
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <IconButton
            aria-label="delete"
            onClick={onClose}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom className={classes.title}>
            {t("change_material_name_title")}
          </Typography>
        </div>
      </div>

      <>
        <>
          <div className={classes.subHeader}>
            <DialogContentText className={classes.subHeaderText}>
              {t("newMatImg_subheader")}
            </DialogContentText>
          </div>
          <InformationalComponent
            type="info"
            className={classes.informationalComponent}
          >
            <Typography variant={"body2"}>{t("newMatImg_warning")}</Typography>
          </InformationalComponent>
        </>

        <DialogContent className={classNames(classes.root)}>
          <CompareMaterialAlias item={item} addAliasAction={addAliasAction} />

          <Typography className={classes.bottomText}>
            {t("change_material_name_back_to_default") + " "}
            <span className={classes.hereClass} onClick={onReset}>
              {t("here")}
            </span>
          </Typography>
        </DialogContent>

        <DialogActions className={classes.actionButtons}>
          <Button
            color="primary"
            classes={{ root: classes.buttonRoot }}
            variant="outlined"
            onClick={() => {
              addAliasAction(null);
              onClose();
            }}
          >
            {t("newMatImg_cancel")}
          </Button>
          <Button
            classes={{ root: classes.buttonRoot }}
            elevation={0}
            color="secondary"
            variant="contained"
            onClick={onSubmit}
            disabled={disableButton}
          >
            {t("newMatImg_saveAndExit")}
          </Button>
        </DialogActions>
      </>
    </>
  );
};
