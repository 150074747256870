import React, { useContext } from "react";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";

import { CheckErrors } from "../../ErrorTracking";
import UserContext from "../../providers/UserProvider";
import { ConfirmOrRescheduleRoute } from "./ConfirmOrRescheduleRoute";
import PickupsPage from "./PickupsPage";
import queryString from "query-string";
import Loader from "../../components/core/shared/Loader";
import { RequestPickupModal } from "./modal/RequestPickupModal";
import { GET_FULL_PRODUCT_DATA } from "../../queries/Products";
import { useQuery } from "@apollo/react-hooks";
const validStatuses = ["all", "pending", "scheduled"];

const isValidStatus = status => validStatuses.includes(status);

const getSearchParams = location => {
  const searchParams = new URLSearchParams(location.search);

  return {
    status: searchParams.get("status") || null,
    date: searchParams.get("date") || null,
    startDate: searchParams.get("startDate") || null,
    endDate: searchParams.get("endDate") || null,
    product: searchParams.get("product") || null,
    transactionId: searchParams.get("transactionId") || null,
    localization: searchParams.get("localization") || null
  };
};

const redirectToValidStatus = () => <Redirect to="/pickups?status=all" />;

export const PickupsRoute = props => {
  const match = useRouteMatch();
  const location = useLocation();

  const { user, selectedCompany } = useContext(UserContext);

  const backgroundLocation = location.state && location.state.background;
  const isBackgroundLocation = !!backgroundLocation;

  const {
    status,
    date,
    product,
    transactionId,
    startDate,
    endDate,
    localization
  } = getSearchParams(location);

  let query = queryString.parse(location.search);
  const searchParams = new URLSearchParams(query);
  const confirm_transaction_id = searchParams.get("confirm");
  const reschedule_transaction_id = searchParams.get("reschedule");
  const request_pickUp = searchParams.get("request");
  const arrayOfExpanded = searchParams.get("expanded")?.split(",");
  const showExpandedFirst = searchParams.get("showExpandedFirst") === "true";

  const notExtraFunctionality =
    !confirm_transaction_id && !reschedule_transaction_id && !request_pickUp;

  if (!isValidStatus(status) && notExtraFunctionality) {
    return redirectToValidStatus();
  }

  const showPickUps =
    !confirm_transaction_id && !reschedule_transaction_id && !request_pickUp;

  const { error, loading, data, refetch } = useQuery(GET_FULL_PRODUCT_DATA, {
    variables: { token: user.token, companyId: selectedCompany },
    fetchPolicy: "network-only",
    skip: false
  });
  if (loading) {
    return <Loader />;
  } else if (error) {
    if (location.search.includes("download")) refetch();
    return null;
  }

  const { FullProductData } = data;

  const hasTransactions = FullProductData.hasTransactions;

  const productData = [...FullProductData.contracted];

  return (
    <CheckErrors>
      <Switch location={backgroundLocation || location}>
        {showPickUps && (
          <PickupsPage
            productData={productData}
            user={user}
            selectedCompany={selectedCompany}
            urlDate={date}
            urlStatus={status}
            urlProductId={product}
            urlLocalizationId={localization}
            urlTransactionId={transactionId}
            hidePickups={isBackgroundLocation}
            urlStartDate={startDate}
            urlEndDate={endDate}
            arrayOfExpanded={arrayOfExpanded}
            showExpandedFirst={showExpandedFirst}
            hasTransactions={hasTransactions}
          />
        )}
        <ConfirmOrRescheduleRoute
          match={match}
          reschedule_transaction_id={reschedule_transaction_id}
          confirm_transaction_id={confirm_transaction_id}
          request_pickUp={request_pickUp}
        />
      </Switch>

      <ConfirmOrRescheduleRoute
        match={match}
        showRoute={isBackgroundLocation}
      />

      <RequestPickupModal showRoute={isBackgroundLocation} />
    </CheckErrors>
  );
};
