import gql from "graphql-tag";

export default gql`
  query UserTransactionsQuery(
    $token: String!
    $company: String
    $type: String!
    $offset: Int
    $limit: Int
    $searchText: String
    $startDate: String
    $endDate: String
    $transaction: String
    $productID: Int
    $transportType: String
    $transactionType: String
    $statusId: String
    $localization: String
  ) {
    UserTransactions(
      token: $token
      company: $company
      type: $type
      offset: $offset
      limit: $limit
      searchText: $searchText
      startDate: $startDate
      endDate: $endDate
      transaction: $transaction
      productID: $productID
      transportType: $transportType
      transactionType: $transactionType
      statusId: $statusId
      localization: $localization
    ) {
      products {
        productID
        productName
      }
      transports {
        transportId
        option
      }
      locations {
        locationId
        location
      }
      transactions {
        id
        _id
        partnerTransactionID
        partner {
          zipCode
        }
        source
        cart {
          amount
          invoicedAmount
          material
          priceEuro
          quantity
          priceAmount
          productId
          buyPriceEuro
          buyPriceAmount
          image {
            src
            alt
            id
            filename
          }
          pricePurchase
          formula
          baseMetalName
        }
        price
        amount
        invoicedAmount
        images {
          src
          alt
          id
          filename
        }
        location
        locationId
        weightnote {
          src
          alt
          id
          filename
        }
        weightnoteComment
        partnerUploads {
          src
          alt
          id
          filename
          fromBuyer
        }
        isTender
        documents {
          id
          documentType
          documentName
          downloadLink
          uploadedByMetaloop
          fileType
        }
        statusData
        tenderType
        material
        statusId
        invoiceUrl
        checkoutDate
        deliveryDate
        pickupDate
        invoiceDate
        transactionID
        deliveryPlace
        transportOption
        comments
        date
        avisoAcceptKey
        partnerComment
        ourComment
        containerComment
        contractDetails
        importedTransaction
        internalTransactionId
      }
      statuses {
        _id
        statusId
        status
      }
      actualTransactions
      totalTransactions
    }
  }
`;
