import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { MenuItem } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { SelectMenuProps } from "../../core/shared/constants";
const DateDropdownSLASelector = ({
  date,
  label,
  dateOptions,
  resetStartEndDate,
  setOpenDataRangeSelector,
  setDate,
  updateDates,
  isOpen,
  setIsOpen,
  startDate,
  endDate,
  error
}) => {
  const useStyles = makeStyles(theme => ({
    closeButton: {
      color: "#767676",
      padding: 0,
      margin: 0
    },
    closeButtonContainer: {
      height: "27px"
    },
    dateRangeLabelWrapper: {
      width: "100%"
    },
    dateRangeWrapper: {
      display: "flex",
      gap: "10px",
      maxHeight: "20px",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between"
    },
    productDropdown: {
      width: "236px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    hideCloseButtonContainer: {
      display: "none"
    }
  }));

  const { t } = useTranslation();
  const classes = useStyles();

  const dropdownRef = useRef(null);

  function shouldCloseDropdown(value) {
    const selectedOption = dateOptions.find(option => option.value === value);

    if (selectedOption !== undefined) {
      if (selectedOption.keepDropdownOpen !== undefined) {
        return !selectedOption.keepDropdownOpen;
      }
    }
    return true;
  }

  function runCallBackIfThereIsOne(value) {
    const selectedOption = dateOptions.find(option => option.value === value);

    if (selectedOption !== undefined) {
      if (selectedOption.callback !== undefined) {
        selectedOption.callback();
      }
    }
  }
  function handleSelectChange(event) {
    const newValue = event.target.value;
    if (newValue !== undefined) {
      setDate(newValue);

      const selectedOption = dateOptions.find(
        option => option.value === newValue
      );

      updateDates(selectedOption.start, selectedOption.end);
    }
    runCallBackIfThereIsOne(newValue);
    setIsOpen(!shouldCloseDropdown(newValue));
  }
  const generateLabelWhenDatePick = (option, showCloseButton = true) => {
    if (option.start === null || option.end === null || date !== "date")
      return option.label;
    return (
      <div className={classes.dateRangeWrapper}>
        <div
          className={classes.dateRangeLabelWrapper}
          onClick={() => {
            setOpenDataRangeSelector(true);
          }}
        >
          {option.start}
        </div>
        <div
          className={` ${classes.closeButtonContainer} ${
            !showCloseButton ? classes.hideCloseButtonContainer : ""
          }`}
          onClick={event => {
            event.stopPropagation();
            setIsOpen(false);
            resetStartEndDate();
          }}
        >
          <Close className={classes.closeButton} size="small" />
        </div>
      </div>
    );
  };
  const generateLabelWhenDateRange = (option, showCloseButton = true) => {
    if (startDate === null || endDate === null || date !== "dateRange")
      return option.label;
    return (
      <div className={classes.dateRangeWrapper}>
        <div
          onClick={() => {
            setOpenDataRangeSelector(true);
          }}
        >
          {startDate} - {endDate}
        </div>
        <div
          className={` ${classes.closeButtonContainer} ${
            !showCloseButton ? classes.hideCloseButtonContainer : ""
          }`}
          onClick={event => {
            event.stopPropagation();
            setIsOpen(false);
            resetStartEndDate();
          }}
        >
          <Close className={classes.closeButton} size="small" />
        </div>
      </div>
    );
  };

  function renderSelectedValue(value) {
    const selectedOption = dateOptions.find(option => option.value === value);

    if (value === "dateRange") {
      return generateLabelWhenDateRange(selectedOption, false);
    } else if (value === "date") {
      return generateLabelWhenDatePick(selectedOption, false);
    }

    return selectedOption.label;
  }
  // show modal when menu item are clicked, not only when they change
  const onMenuItemClick = (_event, value) => {
    handleSelectChange({
      target: {
        value
      }
    });
  };

  return (
    <div ref={dropdownRef}>
      <FormControl variant="outlined" className={classes.productDropdown}>
        <InputLabel id="all-products-dropdown">{t(label)}</InputLabel>
        <Select
          labelId="all-products-dropdown"
          id="select-products"
          value={date}
          onChange={handleSelectChange}
          label={t(label)}
          open={isOpen}
          renderValue={data => renderSelectedValue(data)}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          MenuProps={SelectMenuProps}
          error={error}
        >
          {dateOptions.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              selected={option.value === date}
              disabled={option.disabled}
              onClick={event => onMenuItemClick(event, option.value)}
            >
              {option.value === "dateRange"
                ? generateLabelWhenDateRange(option)
                : option.value === "date"
                  ? generateLabelWhenDatePick(option)
                  : option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DateDropdownSLASelector;
