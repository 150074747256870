import English_language from "../assets/flags/English_language.png";
import German_language from "../assets/flags/German_language.png";
import Spanish_language from "../assets/flags/Spanish_language.jpg";
import Romanian_language from "../assets/flags/Romanian_language.png";
import Czech_language from "../assets/flags/Czech_language.jpg";
import Italian_language from "../assets/flags/Italian_language.png";
import { getEnviroment } from "../config/config";
export const PROFILE_LANGUAGES = [
  {
    value: "de",
    name: "Deutsch"
  },
  {
    value: "en",
    name: "English"
  },
  {
    value: "es",
    name: "Español"
  },
  { value: "ro", name: "Română" },
  {
    value: "cs",
    name: "Čeština (Beta)"
  },
  {
    value: "it",
    name: "Italiano (Beta)"
  }
];

export const acceptedFileExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "webp",
  "tiff",
  "tif",
  "svg",
  "ico",
  "pdf"
];

export const SALES_STATUS_PICKED_UP = 1;
export const SALES_STATUS_TRANSPORT_ORGANIZATION = 2;
export const SALES_STATUS_TRANSPORT_DOCUMENTS = 3;
export const SALES_STATUS_TRANSPORT_IN_TRANSIT = 4;
export const SALES_STATUS_TRANSPORT_DELIVERED = 5;
export const SALES_STATUS_PAYMENT_IN_PROGRESS = 6;
export const SALES_STATUS_SALE_COMPLETE = 7;

export const SALES_STATUS = {
  // SALES_STATUS_PICKED_UP
  100: SALES_STATUS_PICKED_UP,
  new: SALES_STATUS_PICKED_UP,
  "pre-scheduled_pickups": SALES_STATUS_PICKED_UP,
  // SALES_STATUS_TRANSPORT_ORGANIZATION
  quote_required: SALES_STATUS_TRANSPORT_ORGANIZATION,
  scheduling_pickup: SALES_STATUS_TRANSPORT_ORGANIZATION,
  scheduling_shipment: SALES_STATUS_TRANSPORT_ORGANIZATION,
  scheduling_transport: SALES_STATUS_TRANSPORT_ORGANIZATION,
  waiting_for_spedition: SALES_STATUS_TRANSPORT_ORGANIZATION,
  waiting_for_transport_information: SALES_STATUS_TRANSPORT_ORGANIZATION,
  // SALES_STATUS_TRANSPORT_DOCUMENTS
  bnl_creation: SALES_STATUS_TRANSPORT_DOCUMENTS,
  transport_document_creation: SALES_STATUS_TRANSPORT_DOCUMENTS,
  creating_seller_shipping_instructions: SALES_STATUS_TRANSPORT_DOCUMENTS,
  waiting_for_shipping_docs_from_seller: SALES_STATUS_TRANSPORT_DOCUMENTS,
  waiting_for_buyer_shipping_instructions: SALES_STATUS_TRANSPORT_DOCUMENTS,
  // SALES_STATUS_TRANSPORT_IN_TRANSIT
  waiting_for_package: SALES_STATUS_TRANSPORT_IN_TRANSIT,
  waiting_for_material: SALES_STATUS_TRANSPORT_IN_TRANSIT,
  transport_in_progress: SALES_STATUS_TRANSPORT_IN_TRANSIT,
  waiting_for_wiegeschein: SALES_STATUS_TRANSPORT_IN_TRANSIT,
  // SALES_STATUS_TRANSPORT_DELIVERED
  waiting_for_invoicing_information: SALES_STATUS_TRANSPORT_DELIVERED,
  // SALES_STATUS_PAYMENT_IN_PROGRESS
  buffer: SALES_STATUS_PAYMENT_IN_PROGRESS,
  paid_for_export: SALES_STATUS_PAYMENT_IN_PROGRESS,
  invoicing_payment: SALES_STATUS_PAYMENT_IN_PROGRESS,
  ready_for_datenstrom: SALES_STATUS_PAYMENT_IN_PROGRESS,
  "IBAN / Verification": SALES_STATUS_PAYMENT_IN_PROGRESS,
  creating_seller_payment_and_buyer_invoice: SALES_STATUS_PAYMENT_IN_PROGRESS,
  // SALES_STATUS_SALE_COMPLETE
  webshop: SALES_STATUS_SALE_COMPLETE,
  completed: SALES_STATUS_SALE_COMPLETE,
  container_released: SALES_STATUS_SALE_COMPLETE,
  waiting_for_buyer_payment: SALES_STATUS_SALE_COMPLETE
};

export const accountingPosition = "accounting";
export const accountingPositionName = "onboarding_role_accounting";
export const logisticAndOpsPosition = "logisticAndOps";
export const logisticAndOpsPositionName = "onboarding_role_logistics";
export const procurementPosition = "procurement";
export const procurementPositionName = "onboarding_role_procurement";
export const otherPosition = "other";
export const otherPositionName = "Other";

export const roleOptions = [
  { value: accountingPosition, name: accountingPositionName },
  { value: logisticAndOpsPosition, name: logisticAndOpsPositionName },
  { value: procurementPosition, name: procurementPositionName },
  { value: otherPosition, name: otherPositionName }
];

export const SPECIAL_CONTACT_POSITIONS = {
  [accountingPosition]: accountingPositionName,
  [logisticAndOpsPosition]: logisticAndOpsPositionName,
  [procurementPosition]: procurementPositionName
};

export const MAX_PORTAL_UPLOAD_FILE_SIZE = 10 * 1024 * 1024;

export const ONBOARDING_GET_STARTED = "welcome";
export const ONBOARDING_PERSONAL_INFORMATION = "personal";
export const ONBOARDING_COMPANY_INFORMATION = "company";
export const ONBOARDING_TRANSPORT_INFORMATION = "transport";
export const ONBOARDING_BILLING_AND_PAYMENT_INFORMATION = "billing";
export const ONBOARDING_CONGRATULATIONS = "congratulations";

export const ONBOARDING_BO_PERSONAL_INFORMATION = "PersonalInformation";
export const ONBOARDING_BO_COMPANY_INFORMATION = "CompanyInformation";
export const ONBOARDING_BO_TRANSPORT_INFORMATION = "TransportInformation";
export const ONBOARDING_BO_BILLING_AND_PAYMENT_INFORMATION =
  "BillingInformation";

export const ONBOARDING_BO_COMPONENT_NAMES = {
  [ONBOARDING_PERSONAL_INFORMATION]: ONBOARDING_BO_PERSONAL_INFORMATION,
  [ONBOARDING_COMPANY_INFORMATION]: ONBOARDING_BO_COMPANY_INFORMATION,
  [ONBOARDING_TRANSPORT_INFORMATION]: ONBOARDING_BO_TRANSPORT_INFORMATION,
  [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]:
    ONBOARDING_BO_BILLING_AND_PAYMENT_INFORMATION
};

export const ONBOARDING_BO_COMPONENT_NAMES_REVERSE = {
  [ONBOARDING_BO_PERSONAL_INFORMATION]: ONBOARDING_PERSONAL_INFORMATION,
  [ONBOARDING_BO_COMPANY_INFORMATION]: ONBOARDING_COMPANY_INFORMATION,
  [ONBOARDING_BO_TRANSPORT_INFORMATION]: ONBOARDING_TRANSPORT_INFORMATION,
  [ONBOARDING_BO_BILLING_AND_PAYMENT_INFORMATION]:
    ONBOARDING_BILLING_AND_PAYMENT_INFORMATION
};

export const ONBOARDING_STEPS_LIST_LABELS = {
  PersonalInformation: "onboarding_slider_step_personal",
  CompanyInformation: "onboarding_slider_step_company",
  TransportInformation: "onboarding_slider_step_transport",
  BillingInformation: "onboarding_slider_step_billing"
};

export const ONBOARDING_STEPS = {
  personal: 1,
  company: 2,
  transport: 3,
  billing: 4,
  congratulations: 5
};

export const REVERSE_ONBOARDING_STEPS = {
  1: ONBOARDING_PERSONAL_INFORMATION,
  2: ONBOARDING_COMPANY_INFORMATION,
  3: ONBOARDING_TRANSPORT_INFORMATION,
  4: ONBOARDING_BILLING_AND_PAYMENT_INFORMATION,
  5: ONBOARDING_CONGRATULATIONS
};

export const FLAGS = {
  en: {
    name: "EN",
    img: English_language,
    label: "English"
  },
  es: {
    name: "ES",
    img: Spanish_language,
    label: "Español"
  },
  de: {
    name: "DE",
    img: German_language,
    label: "Deutsch"
  },
  ro: {
    name: "RO",
    img: Romanian_language,
    label: "Română"
  },
  cs: {
    name: "CS",
    img: Czech_language,
    label: "Čeština (Beta)"
  },
  it: {
    name: "IT",
    img: Italian_language,
    label: "Italiano (Beta)"
  }
};

export const LANGUAGES = Object.keys(FLAGS);

export const FALLBACK_LANGUAGE = "en";

export const ALLOWED_DEMO_FREQUENCY_TYPES = [
  "Spot",
  "Daily",
  "Weekly",
  "Every month"
];
const env = getEnviroment();
export const SURVEYS =
  env === "production"
    ? {
        GENERAL: "c42aec2b-ffcf-4064-8e8f-9b907e0c1d45",
        SALE_REQUEST: "16e136ca-7ed8-47f3-926e-a18d7e435a0f",
        PICKUP_REQUEST: "dad0435e-d798-49bb-8c3a-ad6345c2ceee",
        UPLOAD_DOC: "92742287-16cc-4674-b2fb-4d5697100aa9"
      }
    : {
        GENERAL: "1228a720-6cf9-494e-b103-88c896962071",
        SALE_REQUEST: "2d7c2a55-96db-4a6d-aecd-50220c98abb4",
        PICKUP_REQUEST: "3423ae76-0399-42d9-a289-13a9c13d2819",
        UPLOAD_DOC: "40c75330-fb99-4409-a72f-24523e305601"
      };
