import React, { useState } from "react";
import { Hidden, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../general/Table";
import PurchaseDesktopInformation from "./purchaseDesktopInformation";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ReactComponent as S24Square } from "../../../../assets/s24Square-black.svg";
import { ReactComponent as OfferSquare } from "../../../../assets/offerSquare-grey.svg";
import MobilePurchaseDetail from "../../layout/Modals/MobilePurchaseDetail";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { canSeeItem } from "../../../../utils/SharedUtils";
import useCompany from "../../../../hooks/useCompany";
import useStandardLocalization from "../../../../hooks/useStandardLocalization";

//we need to show different tables for mobile and desktop
//freaking six in total...
//this is just a switcher component

const PurchaseTable = props => {
  const { t } = useTranslation();
  const { parseNumber, parseCurrency } = useStandardLocalization();
  const [openModal, setOpenModal] = useState(false);
  const [order, setOrder] = useState(false);

  const useStyles = makeStyles({
    table: {
      whiteSpace: "nowrap",
      "& th:hover": {
        background: "transparent !important"
      }
    }
  });

  const classes = useStyles();

  const {
    data,
    isPolling,
    loading,
    handleExpansion,
    type,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowCount,
    queryParamTransactionID,
    uploadInitialType
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const company = useCompany();

  const canSeePricingInfo = canSeeItem(company?.selectedCompany, [company], {
    rules: "purchases_prices"
  });

  const onRowClick = ({ record, onOpenCollapse }) => {
    handleExpansion(record);
    if (isMobile) {
      setOpenModal(true);
      setOrder(record);
    } else {
      onOpenCollapse();
    }
  };

  const columns = [
    {
      width: "32px",
      spacer: true
    },
    {
      headerName: "",
      flex: 1,
      renderCell({ record: order }) {
        return (
          <div style={{ height: "24px" }}>
            {order.source === "S24" ? <S24Square /> : <OfferSquare />}
          </div>
        );
      }
    },
    {
      field: "metaloop_id",
      headerName: t("metaloop_id"),
      flex: 1,
      valueFormatter({ record }) {
        return record?.transactionID;
      }
    },
    {
      field: "yourOrderId",
      headerName: t("yourOrderId"),
      flex: 1,
      valueFormatter({ record }) {
        return record?.internalTransactionId;
      }
    },
    {
      field: "source",
      headerName: t("Transaction source"),
      sortable: false
    },
    {
      field: "transportOption",
      headerName: t("Transport type"),
      flex: 1
    },
    {
      field: "amount",
      headerName: t("Quantity (kg)"),
      flex: 1,
      type: "number",
      valueFormatter({ record: order }) {
        return parseNumber(order.amount);
      }
    },
    canSeePricingInfo && {
      field: "price",
      headerName: t("Purchase price"),
      flex: 1,
      type: "number",
      renderCell({ record: order }) {
        return parseCurrency(Math.round(order.price * 100) / 100);
      }
    },
    !isMobile && {
      headerName: "",
      flex: 1,
      type: "number",
      renderCell: ({ collapseIsOpen }) => (
        <IconButton aria-label="expand row" size="small">
          {collapseIsOpen && !isMobile ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      )
    },
    {
      width: "32px",
      spacer: true
    }
  ];

  const expandedRowRender = order => {
    if (!order) {
      return null;
    }
    return (
      <>
        <Hidden smDown>
          <PurchaseDesktopInformation
            order={order}
            invoiceUrl={order.invoiceUrl}
            photos={order.images}
            partnerUploads={order.partnerUploads}
            weightnote={order.weightnote}
            comment={order.comments}
            type={type}
            isSeller={false}
            canSeePricingInfo={canSeePricingInfo}
            uploadInitialType={uploadInitialType}
          />
        </Hidden>
      </>
    );
  };

  //MOBILE
  //If we have the transactionID and the openWeightNoteDialog param,
  //we open the order in detail
  const location = useLocation();
  const query = queryString.parse(location.search);
  const isOpenWeightNote = query.openWeightNoteDialog;
  const isTransactionID = query.transactionID;
  if (
    !openModal &&
    isOpenWeightNote &&
    isTransactionID &&
    rowCount == 1 &&
    data[0]
  ) {
    //We open the dialog of the order in detail
    setOpenModal(true);
    setOrder(data[0]);
  }
  return (
    <>
      <MobilePurchaseDetail
        order={order}
        open={openModal}
        type={type}
        onCloseModal={() => setOpenModal(false)}
      />
      <CustomTable
        columns={columns}
        rows={data}
        hover
        loading={loading && !isPolling}
        getRowId={record => record.transactionID}
        expandedRowRender={expandedRowRender}
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={rowsPerPage}
        onPageChange={onChangePage}
        onPageSizeChange={onChangeRowsPerPage}
        rowCount={rowCount}
        onRowClick={onRowClick}
        defaultCollapseId={queryParamTransactionID}
        className={classes.table}
      />
    </>
  );
};

export default PurchaseTable;
