import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { useCallback, useRef } from "react";
import CustomDropzone from "../../core/shared/uploadDialog/components/Dropzone";
import FileListTwoSteps from "../../core/shared/uploadDialog/components/FileList/FileListTwoSteps";
import { validFiles } from "../../../utils/checkCorruptedFile";
import { showError } from "../../core/shared/Notify";
const useStyles = makeStyles({
  dropzoneContainer: {
    padding: "41px 21px 41px 21px",
    background: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    height: "100px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "43px",
    letterSpacing: ".25px",
    color: "#151124",
    margin: "0"
  },
  dropzoneContent: {
    maxWidth: "244px",
    width: "100%",
    display: "flex",
    "& p": {
      margin: 0,
      lineHeight: "24px",
      fontWeight: "400",
      letterSpacing: "0.25px",
      "& > a": {
        color: "#276EF1",
        textDecoration: "none"
      }
    }
  },
  dropzoneArea: {
    display: "flex",
    flexDirection: "column",
    gap: "24px"
  }
});

const DragAndDrop = ({ files, setFiles }) => {
  const accept = "image/*, application/pdf,application/docx";
  const { t } = useTranslation();
  const classes = useStyles();
  const dropzoneRef = useRef();

  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  const addFilesAction = useCallback(
    async fileList => {
      const fileListArray = Array.from(fileList);
      const { validFilesArray, corrupted } = await validFiles(fileListArray);
      if (corrupted) {
        showError(t("error_corruptedFiles"));
      }
      setFiles([...files, ...validFilesArray]);
    },
    [files, setFiles]
  );
  return (
    <>
      <CustomDropzone accept={accept} onDrop={addFilesAction} ref={dropzoneRef}>
        <div className={classes.dropzoneArea}>
          <div className={classes.dropzoneContainer}>
            <div className={classes.dropzoneContent}>
              <AddIcon className={classes.addIcon} />
              <p>
                {t("DragDropWindow1")}{" "}
                <a href="#" onClick={openUploadDialog}>
                  {t("DragDropWindowBrowse")}
                </a>
              </p>
            </div>
          </div>{" "}
          {files.length > 0 && (
            <div>
              <FileListTwoSteps
                step={0}
                files={files}
                setFiles={setFiles}
                className={classes.fileSelection}
                canPreview={true}
                canRemove={true}
                canRename={false}
                uploading={false}
                hideInfo={true}
              />
            </div>
          )}
        </div>
      </CustomDropzone>{" "}
    </>
  );
};

export default DragAndDrop;
