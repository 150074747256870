import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  FormHelperText,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-apollo";

import GetPickupsData from "../../queries/GetPickupsData";
import { RequestPickup } from "../../mutations/pickupsMutations";
import useQuantityValidation from "../../hooks/useQuantityValidation";
import { showError, showSuccess } from "../core/shared/Notify";
import useEventTracking from "../../hooks/useEventTracking";
import DateDropdownSLA from "../general/DateDropdownSLA";
import AddIcon from "@material-ui/icons/Add";
import UploadDialogWithoutSend from "../core/shared/UploadDialogWithoutSend";
import FileListNotUploaded from "../core/shared/FileListNotUploaded/FileListNotUploaded";
import Hidden from "@material-ui/core/Hidden";
import useUserData from "../../hooks/useUserData";
import { useValidation } from "../../hooks/useValidation";
import { SelectMenuProps } from "../core/shared/constants";
import useCompany from "../../hooks/useCompany";
import { useProductFruitsSurvey } from "../../hooks/useProductFruitsSurvey";
const useStyles = makeStyles(theme => ({
  formWrap: {
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: { overflowY: "auto" }
  },
  formControl: {
    width: "100%",
    margin: 0,
    "& .MuiIconButton-root": {
      padding: 0
    },
    "& .Mui-error": {
      marginLeft: "0px"
    }
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    "& .MuiButton-root:not(:first-child)": {
      marginLeft: "16px"
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      paddingRight: "16px",
      paddingBottom: "16px",
      paddingTop: "16px",
      backgroundColor: "#fff",
      zIndex: 1000
    }
  },
  gridContainer: {
    width: "calc(100% + 20px)",
    margin: "-10px"
  },
  gridCol: {
    padding: "10px"
  },
  rangePicker: {
    width: "100%"
  },
  textField: {
    height: "104px",
    alignItems: "baseline",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #9E9E9E"
    }
  },
  attachedDocsTitle: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": 500,
    "font-size": "16px",
    "line-height": "24px",
    /* identical to box height, or 150% */

    display: "flex",
    "align-items": "center",
    "letter-spacing": "0.25px",

    /* On surface/High emphasis */

    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "8px",
    marginTop: "20px"
  },
  [theme.breakpoints.down("sm")]: {
    maringInside: {
      marginTop: "5px",
      marginBottom: "60px"
    }
  }
}));

const notValidQuantity = quantity => {
  const floatRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;
  if (!floatRegex.test(quantity)) return { error: true };
};

const notPositiveQuantity = quantity => {
  const quantityFloat = parseFloat(quantity);
  if (quantityFloat <= 0) return { error: true };
};

const PickupRequestForm = ({
  form,
  packaging,
  setIsLoading,
  userArray,
  setRequiredError
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { startSurvey } = useProductFruitsSurvey();
  const { token, email, fullName, isAdmin, pf } = useUserData();
  const { name: companyName, selectedCompany } = useCompany();

  const { errors, checkValidation, setErrors } = useValidation({
    quantity: notValidQuantity,
    quantityPositive: notPositiveQuantity
  });
  const [formData, setFormData] = React.useState(() => {
    if (!form) {
      return {
        quantity: "",
        packaging: "",
        collectionType: "",
        internalTransactionId: "",
        selectedUser: null
      };
    }
    const { quantity, packaging, selectedUser } = form;
    return {
      quantity,
      packaging: packaging || "loose",
      collectionType: "",
      internalTransactionId: "",
      selectedUser
    };
  });
  const classes = useStyles();

  const { state: { from } = {} } = useLocation();

  const quantityParams = useQuantityValidation(formData.quantity, t);
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [comments, setComments] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesWithDownloadOptions, setFilesWithDownloadOptions] = useState([]);
  const { TrackEvent } = useEventTracking();

  const [requestPickup] = useMutation(RequestPickup, {
    refetchQueries: () => [
      {
        query: GetPickupsData,
        variables: { token, partnerId: selectedCompany }
      }
    ]
  });

  const onChange = prop => event => {
    const value = event.target.value;
    setFormData(forms => ({
      ...forms,
      [prop]: value
    }));
  };
  const onCancel = () => {
    let pickupDate;

    if (
      (date === "date" || date === "immediately") &&
      startDate &&
      endDate &&
      startDate === endDate
    ) {
      pickupDate = startDate;
    } else {
      pickupDate = `${startDate} - ${endDate}`;
    }

    const { quantity, packaging, internalTransactionId } = formData;
    const { id } = form;
    TrackEvent("Pick up request canceled", {
      conditionId: id,
      quantity,
      packaging,
      internalTransactionId,
      pickupDate,
      comments
    });

    setFormData({
      quantity: "",
      packaging: "",
      collectionType: "",
      date: null,
      dateRange: null,
      internalTransactionId: ""
    });
    history.goBack();
    startSurvey("PICKUP_REQUEST", pf?.surveys, token);
  };

  const onSetFiles = receivedFiles => {
    const newFiles = files;
    const newfilesWithDownloadOptions = filesWithDownloadOptions;
    for (let file of receivedFiles) {
      const newExtructureOfFiles = {
        documentName: file.file.name,
        fileType: file.file.type,
        downloadLink: file.file.preview,
        documentType: "Extra Files Seller",
        uploadedByMetaloop: null,
        __typename: "Document"
      };
      newFiles.push(newExtructureOfFiles);
      newfilesWithDownloadOptions.push({ file: file.file, meta: file.meta });
    }
    setFiles(newFiles);
    setFilesWithDownloadOptions(newfilesWithDownloadOptions);
  };

  const onSubmit = async () => {
    formData.quantity =
      formData.quantity &&
      formData.quantity.includes(",") &&
      !formData.quantity.includes(".")
        ? formData.quantity.replace(/,/g, ".")
        : formData.quantity;

    const quantity = formData.quantity;
    const errorsCheckingFields = {
      date,
      quantity,
      packaging: formData.packaging,
      quantityPositive: quantity
    };
    if (isAdmin) {
      errorsCheckingFields.selectedUser = selectedUser;
    }

    const errors = checkValidation(errorsCheckingFields);
    setRequiredError(!form.id);
    quantityParams.onSubmit();

    if (errors || !form.id) return;
    let pickUpDate;

    if (
      (date === "date" || date === "immediately") &&
      startDate &&
      endDate &&
      startDate === endDate
    ) {
      pickUpDate = startDate;
    }

    let collectionType =
      date == "date" || date == "immediately"
        ? "date"
        : date == "dateRange"
          ? "dateRange"
          : date == "2weeks"
            ? "within2Weeks"
            : null;

    if (
      (date == "date" && !pickUpDate) ||
      (date == "dateRange" && (!startDate || !endDate))
    ) {
      setErrors({ date: true });
      return;
    }

    const dateRange = `${startDate} - ${endDate}`;

    const filesArray = filesWithDownloadOptions.map(file => file.file);
    const metasArray = filesWithDownloadOptions.map(file => file.meta);

    const variables = {
      token,
      conditionId: form.id,
      ...formData,
      date: pickUpDate,
      dateRange: collectionType == "dateRange" ? dateRange : null,
      collectionType: collectionType,
      additionalComments: comments,
      files: filesArray,
      metas: metasArray,
      selectedUser: selectedUser
    };

    setIsLoading(true);
    await requestPickup({
      variables
    })
      .then(({ data: { requestPickup } }) => {
        const material_alias = form.alias;

        TrackEvent("Pick up request completed", {
          conditionId: form.id,
          quantity: formData.quantity,
          packaging: formData.packaging,
          pickupDate: formData.date,
          internalTransactionId: formData.internalTransactionId,
          orderId: requestPickup.pickup.transactionId,
          operatorEmail: requestPickup.operatorEmail,
          traderEmail: requestPickup.traderEmail,
          userEmail: email,
          materialName: material_alias,
          companyName: companyName,
          userName: fullName
        });
        showSuccess(
          t("FeedbackNotification_PickupRequestSent", {
            material_alias
          })
        );
        setIsLoading(false);
        history.push("/pickups?status=pending", { requestPickup });
      })
      .catch(err => showError(err.message));
  };

  if (isAdmin) form.slaLevel = "MetaloopAdmin";

  return (
    <div className={classes.formWrap}>
      <div className={classes.maringInside}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.gridCol}>
            <TextField
              id="outlined-textarea"
              label={t("Declared Quantity (kg)")}
              type={"number"}
              value={formData.quantity}
              onChange={onChange("quantity")}
              name="quantity"
              variant="outlined"
              className={classes.formControl}
              {...quantityParams}
            />
            {formData.quantity && errors?.quantity && (
              <FormHelperText error>
                {t("pickup_request_quantity_error")}
              </FormHelperText>
            )}
            {formData.quantity &&
              !errors?.quantity &&
              errors?.quantityPositive && (
                <FormHelperText error>
                  {t("pickup_request_quantity_err_bigger_zero")}
                </FormHelperText>
              )}
          </Grid>
          {/*<Grid item xs={12} md={6} className={classes.gridCol}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="packaging-dropdown">
                {t("Products_Packaging")}
              </InputLabel>
              <Select
                labelId="packaging-dropdown"
                id="packaging-select"
                label={t("Products_Packaging")}
                value={formData.packaging}
                onChange={onChange("packaging")}
                name="packaging"
                MenuProps={SelectMenuProps}
                disabled={true}
              >
                {packaging.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(item.name)}
                  </MenuItem>
                ))}
              </Select>
              {errors?.packaging && (
                <FormHelperText error>{t("Required")}</FormHelperText>
              )}
            </FormControl>
          </Grid>*/}
          <Grid item xs={12} md={6} className={classes.gridCol}>
            <FormControl variant="outlined" className={classes.formControl}>
              <DateDropdownSLA
                date={date}
                contractStartDate={form?.contractStartDate}
                contractEndDate={form?.contractEndDate}
                setDate={setDate}
                label={"Pick up Date"}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                slaLevel={form.slaLevel}
                error={errors?.date}
              />
              {errors?.date && (
                <FormHelperText error>{t("Required")}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridCol}>
            <TextField
              id="outlined-textarea"
              label={t("Placeholder_InternalTransactionIdTextfield")}
              value={formData.internalTransactionId}
              onChange={onChange("internalTransactionId")}
              name="internalTransactionId"
              variant="outlined"
              className={classes.formControl}
            />
          </Grid>
          {isAdmin && (
            <Grid item xs={12} md={6} className={classes.gridCol}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="users-dropdown">{t("Choose_User")}</InputLabel>
                <Select
                  id="companyUsers"
                  label={t("Choose_User")}
                  value={formData.selectedUser}
                  onChange={event => setSelectedUser(event.target.value)}
                  name="selectedUser"
                  MenuProps={SelectMenuProps}
                  error={errors?.selectedUser}
                >
                  {userArray.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.email}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.selectedUser && (
                  <FormHelperText error>{t("Required")}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        </Grid>
        <div style={{ marginTop: "20px" }}>
          <TextField
            multiline
            maxRows={4}
            variant="outlined"
            label={t("Additional Comments")}
            InputProps={{ className: classes.textField }}
            fullWidth
            value={comments}
            onChange={event => setComments(event.target.value)}
          />
        </div>
        {files.length > 0 && (
          <div>
            <Typography className={classes.attachedDocsTitle}>
              {t("PickupModal_AttachedDocuments")}
            </Typography>
            <FileListNotUploaded files={files} />
          </div>
        )}
      </div>
      <div className={classes.bottom}>
        <Hidden smDown>
          <Button variant="text" color="primary" onClick={onCancel}>
            {t("Cancel")}
          </Button>
        </Hidden>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.buttonDesktop}
          onClick={() => {
            TrackEvent("Upload document flow started", {
              orderId: null,
              clickSource: "Button"
            });
            setUploadDialogOpen(true);
          }}
        >
          {t("DOCUMENTS")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("Request")}
        </Button>
      </div>
      {uploadDialogOpen ? (
        <UploadDialogWithoutSend
          orderType="sale"
          onClose={() => {
            setUploadDialogOpen(false);
          }}
          seller={true}
          onSetFiles={onSetFiles}
          disableInvoiceUpload={true}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

PickupRequestForm.propTypes = {
  form: PropTypes.object
};

PickupRequestForm.defaultProps = {
  form: {
    quantity: "",
    packaging: "",
    collectionType: "",
    date: null,
    dateRange: null
  }
};

export default PickupRequestForm;
