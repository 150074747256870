import React, { useCallback, useRef } from "react";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  Hidden
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import FileList from "../../components/core/shared/uploadDialog/components/FileList";
import AddIcon from "@material-ui/icons/Add";
import FileIcon from "../../assets/icons/file.png";

const useStyles = makeStyles(theme => ({
  pageContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "35%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "640px",
      maxHeight: "100px"
    }
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
      overflow: "hidden"
    },
    [theme.breakpoints.up("md")]: {
      width: "600px"
    }
  },
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  buttonRootOne: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center",
      marginTop: "0px"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "14px"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "12px"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  fileSelection: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
      borderWidth: "0px",
      maxHeight: "200px"
    },
    margin: "0 -17px"
  },
  textfield: {
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px"
    }
  },
  closeButton: {
    padding: "12px"
  },
  actionButtons: {
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row"
    },
    display: "flex",
    flexDirection: "column"
  },
  text: {
    paddingBottom: "15px"
  },
  root: {
    paddingRight: "17px !important",
    paddingLeft: "17px !important"
  },
  fileIcon: {
    width: "24px",
    height: "24px",
    marginRight: "8px"
  }
}));

export default function ThirdStep({
  files,
  closingModal,
  driverTokenValidity,
  TrackEvent,
  setStep,
  setFiles
}) {
  const classes = useStyles();
  const commentRef = useRef();

  const handleFinishClick = useCallback(() => {
    const comment =
      commentRef.current && commentRef.current.value
        ? commentRef.current.value
        : "";
    setStep(4);
    closingModal(comment);
  }, [closingModal, files, driverTokenValidity, TrackEvent]);

  const handleAddOns = () => {
    setStep(1);
    setFiles([]);
  };
  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.headerRoot}>
              <Hidden mdUp>
                <IconButton
                  aria-label="delete"
                  onClick={() => closingModal("closeTab")}
                  className={classes.closeButton}
                >
                  <ClearIcon />
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <img src={FileIcon} alt="logo" class={classes.fileIcon} />
              </Hidden>
              <Typography variant="h2" gutterBottom>
                {"Upload Documents"}
              </Typography>
            </div>
          </div>
          <DialogContent className={classes.root}>
            <DialogContentText className={classes.text}>
              {"The files you selected were uploaded successfully."}
              <br />
              {"Do you have any comments to add to this transaction?"}
            </DialogContentText>
            <TextField
              inputRef={commentRef}
              className={classes.textfield}
              label={"Add comment"}
              variant="outlined"
              fullWidth={true}
              multiline={true}
              minRows={4}
            />
            <FileList
              files={files}
              uploading={true}
              className={classes.fileSelection}
              isDriver={true}
            />
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            {/*duplication here to keep the same order as figma design, could use as well useMediaQuery */}
            <Hidden mdUp>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleFinishClick}
                classes={{ root: classes.buttonRootOne }}
              >
                {"FINISH"}
              </Button>
              <Button
                color="primary"
                onClick={handleAddOns}
                startIcon={<AddIcon />}
                classes={{ root: classes.buttonRoot }}
              >
                {"Add More"}
              </Button>
            </Hidden>
            <Hidden smDown>
              <Button
                color="primary"
                onClick={handleAddOns}
                startIcon={<AddIcon />}
                classes={{ root: classes.buttonRoot }}
              >
                {"Add More"}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleFinishClick}
                classes={{ root: classes.buttonRootOne }}
              >
                {"FINISH"}
              </Button>
            </Hidden>
          </DialogActions>
        </div>
      </div>
    </>
  );
}
