import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { makeStyles, IconButton } from "@material-ui/core";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import {
  getProductImageUrl,
  getQuantityString,
  getTitle,
  renderEstimatedPrice,
  renderIndexConnection,
  renderPriceValue
} from "./ProductUtils";
import LimitedTextWithMoreButton from "../../general/LimitedTextWithMoreButton";
import FrequencyConversor from "./FrequencyConversor";
import NextPickUpContent from "./NextPickUpContent";
import DropdownMenu from "./DropdownMenu";

import {
  ProductTitleAndValue,
  ProductCardButton,
  CardTitleRow,
  ContractedLabel,
  ImageContainer
} from "./ProductUtils";

const useStyles = makeStyles(theme => ({
  productCard: {
    boxSizing: "border-box",
    width: "1116px",
    minHeight: "180px",
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "19px",
    position: "relative",
    "&:hover": {
      backgroundColor: "#F2F2F2",
      cursor: "pointer",
      "& $dropdownMenu": {
        backgroundColor: "#F9F9F9"
      }
    }
  },
  productCardWrapper: {
    display: "flex",
    gap: "16px"
  },

  imageContainer: {
    height: "auto"
  },

  imagePlaceholder: {
    background: "lightgrey"
  },
  editNamePlaceholder: {
    marginTop: -4,
    "& > div": {
      display: "none"
    },
    "&:not(:hover) > div": {
      display: "flex",
      padding: 4,
      color: "black",
      backgroundColor: "transparent",
      cursor: "pointer"
    },
    "&:hover > div": {
      display: "flex",
      padding: 4,
      color: "rgb(82, 245, 151)",
      backgroundColor: "rgb(21, 17, 36)",
      cursor: "pointer"
    }
  },
  editImagePlaceholder: {
    width: 100,
    height: 112,
    position: "absolute",
    "& > div": {
      display: "none"
    },
    "&:not(:hover) > div": {
      position: "absolute",
      bottom: "4px",
      right: "4px",
      display: "flex",
      padding: 4,
      color: "rgba(255, 255, 255, 0.75)",
      backgroundColor: "transparent",
      cursor: "pointer"
    },
    "&:hover > div": {
      position: "absolute",
      bottom: "4px",
      right: "4px",
      display: "flex",
      padding: 4,
      color: "rgb(82, 245, 151)",
      backgroundColor: "rgb(21, 17, 36)",
      cursor: "pointer"
    }
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    width: "100%"
  },
  greyStatus: {
    color: "rgba(0, 0, 0, 0.38)",
    "& > svg": {
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: "12px",
      marginRight: "8px"
    }
  },
  greenStatus: {
    color: "rgba(0, 0, 0, 0.6)",
    "& > svg": {
      color: "#4ef191",
      fontSize: "12px",
      marginRight: "8px"
    }
  },
  pickUpStatusContainer: {
    display: "flex",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "28px",
    alignItems: "center",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.38)",
    marginRight: "8px"
  },
  productNameContainer: {
    display: "flex",
    width: "100%",

    alignItems: "center"
  },
  addressContainer: {
    display: "flex",
    gap: 16
  },
  productNamesContainer: {
    display: "flex"
  },
  productName: {
    margin: "0 8px 0 0 ",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },

  collapseProductCardContainer: {
    position: "absolute",
    bottom: 4,
    right: 10
  },

  dropdownMenu: {
    position: "absolute",
    top: "9px",
    right: "9px"
  },

  productCardRowDescription: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between"
  },
  productCardRow: {
    display: "flex",
    gap: "20px"
  },
  buttonPlaceholder: { width: "208px", marginTop: "16px" },
  productTitle: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "7px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  productTitleAndValueContainerLong: {
    width: "336px"
  },
  productTitleAndValueContainer: {
    width: "168px"
  },

  button: {
    width: "208px",
    marginTop: "16px"
  },
  productImageContainer: {
    display: "flex",
    "& img": {
      objectFit: "cover"
    }
  },
  expandedButtonContainer: {
    marginTop: "2px",
    marginBottom: "12px"
  }
}));

const CommonProductCard = ({
  item,
  t,
  setExpanded,
  onEventRequest,
  expanded,
  children,
  changeImageAction,
  changeNameAction,
  disableButton = false,
  saleRequestInitializedEvent
}) => {
  const classes = useStyles();

  const { parseDate, parseCurrency } = useStandardLocalization();
  const removeExtraSpace = !item.fixationTerms && !item.paymentTerms;
  const imageHeight = !expanded ? 140 : removeExtraSpace ? 212 : 264;

  const imageUrl = getProductImageUrl(item, 100, imageHeight);
  const showPickupRequest = item.showPickupRequest;
  const handleChildClick = e => {
    e.stopPropagation();
  };
  return (
    <div className={classes.productCardWrapper}>
      <div className={classes.productImageContainer}>
        <ContractedLabel item={item} />
        <ImageContainer
          item={item}
          classes={classes}
          imageResize={imageHeight}
          imageUrl={imageUrl}
          changeImageAction={changeImageAction}
          style={removeExtraSpace && expanded ? { marginBottom: "30px" } : {}}
          handleChildClick={handleChildClick}
        />
        <div className={classes.dropdownMenu} onClick={handleChildClick}>
          <DropdownMenu
            item={item}
            showPickupRequest={showPickupRequest}
            changeImageAction={changeImageAction}
            changeNameAction={changeNameAction}
            onEventRequest={() => onEventRequest("drop down")}
            saleRequestInitializedEvent={saleRequestInitializedEvent}
          />
        </div>
        <div className={classes.collapseProductCardContainer}>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </div>
      </div>
      <div className={classes.productInfoContainer}>
        <CardTitleRow
          item={item}
          classes={classes}
          t={t}
          changeNameAction={changeNameAction}
        />

        <div className={classes.productCardRow}>
          <ProductTitleAndValue title={getTitle(t, item)} classes={classes}>
            {`${item.collectedQty || 0} / ${item.quantity || 0} mt`}
          </ProductTitleAndValue>
          <ProductTitleAndValue title={t("Price")} classes={classes}>
            {renderPriceValue(item, { parseCurrency })}
          </ProductTitleAndValue>
          <ProductTitleAndValue title={t("Incoterms")} classes={classes}>
            {item.incoterms || "-"}
          </ProductTitleAndValue>
          {item.statusId === 1 ? (
            <ProductTitleAndValue
              title={t("Materials_NextPickupDate")}
              classes={classes}
            >
              <div onClick={handleChildClick}>
                <NextPickUpContent item={item} />
              </div>
            </ProductTitleAndValue>
          ) : (
            <ProductTitleAndValue
              title={t("Price Validity Date")}
              classes={classes}
            >
              {(item.validTo && parseDate(item.validTo)) || "-"}
            </ProductTitleAndValue>
          )}{" "}
          {!expanded ? (
            showPickupRequest && (
              <div onClick={handleChildClick}>
                <ProductCardButton
                  item={item}
                  onEventRequest={() => onEventRequest("button")}
                  classes={classes}
                  t={t}
                  disableButton={disableButton}
                />
              </div>
            )
          ) : (
            <ProductTitleAndValue
              title={t("Account_ContractType")}
              classes={classes}
            >
              {((item.statusId === 1 || item.statusId === 3) &&
                t(item.contractType)) ||
                "-"}{" "}
            </ProductTitleAndValue>
          )}
        </div>

        {children}
      </div>
    </div>
  );
};

const ProductCardExpanded = props => {
  const classes = useStyles();
  const { item, t, onEventRequest, disableButton } = props;
  const showPickupRequest = item.showPickupRequest;

  const { parseDate, parseCurrency } = useStandardLocalization();
  return (
    <div>
      <div className={classes.productCardRow}>
        <ProductTitleAndValue title={t("Packaging")} classes={classes}>
          {t(item.packaging) || "-"}
        </ProductTitleAndValue>
        <ProductTitleAndValue title={t("Index")} classes={classes}>
          {renderIndexConnection(item, { parseDate, parseCurrency })}
        </ProductTitleAndValue>
        <ProductTitleAndValue
          title={t("Products_EstPrice") + "/mt"}
          classes={classes}
        >
          {renderEstimatedPrice(item, { parseCurrency })}
        </ProductTitleAndValue>
        <ProductTitleAndValue title={t("Pickup Frequency")} classes={classes}>
          <FrequencyConversor item={item} />
        </ProductTitleAndValue>
        {item.statusId === 1 ? (
          <ProductTitleAndValue
            title={t("Materials_ContractEndDate")}
            classes={classes}
          >
            {(item?.contractStartDate && parseDate(item?.contractStartDate)) ||
              ""}
            {" - "}
            {(item?.contractEndDate && parseDate(item?.contractEndDate)) || ""}
          </ProductTitleAndValue>
        ) : (
          <></>
        )}
      </div>

      <div className={classes.productCardRowDescription}>
        <div className={classes.productTitleAndValueContainerLong}>
          <div className={classes.productTitle}>{t("Payment Terms")}</div>
          <div className={classes.productValue}>
            {" "}
            <LimitedTextWithMoreButton
              text={item.paymentTerms}
              maxCharacters={150}
              expanded={false}
            />
          </div>
        </div>
        {/*<div className={classes.productTitleAndValueContainerLong}>
          <div className={classes.productTitle}>{t("Fixation Terms")}</div>
          <div className={classes.productValue}>
            {" "}
            <LimitedTextWithMoreButton
              text={item.fixationTerms}
              maxCharacters={150}
              expanded={false}
            />
          </div>
        </div>*/}
        {showPickupRequest && (
          <div className={classes.expandedButtonContainer}>
            <ProductCardButton
              item={item}
              onEventRequest={() => onEventRequest("button")}
              classes={classes}
              t={t}
              disableButton={disableButton}
            />
          </div>
        )}{" "}
      </div>
    </div>
  );
};

const DesktopProductCard = ({
  item,
  isExpanded,
  setExpanded,
  onEventRequest,
  disableButton,
  changeImageAction = () => {},
  changeNameAction = () => {},
  saleRequestInitializedEvent = () => {}
}) => {
  const { t } = useTranslation();
  // use the bigger photos

  const classes = useStyles();

  const handleClick = e => {
    setExpanded(!isExpanded);
  };

  return (
    <>
      <div className={classes.productCard} onClick={handleClick}>
        <CommonProductCard
          item={item}
          t={t}
          setExpanded={setExpanded}
          onEventRequest={onEventRequest}
          expanded={isExpanded}
          disableButton={disableButton}
          changeImageAction={changeImageAction}
          changeNameAction={changeNameAction}
          saleRequestInitializedEvent={saleRequestInitializedEvent}
        >
          <Collapse in={isExpanded}>
            <ProductCardExpanded
              item={item}
              t={t}
              setExpanded={setExpanded}
              onEventRequest={onEventRequest}
              disableButton={disableButton}
            />
          </Collapse>
          <Collapse in={!isExpanded} />
        </CommonProductCard>
      </div>
    </>
  );
};

export default DesktopProductCard;
