import { ImageSharp, PublishSharp, InsertDriveFile } from "@material-ui/icons";
import FolderIcon from "@material-ui/icons/Folder";
import { ReactComponent as WeightIcon } from "../../../../../assets/icons/weight.svg";
import { useTranslation } from "react-i18next";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step3_no_upload from "./Step3_no_upload";
import Step4_no_upload from "./Step4_no_upload";
import { applyFnOrValue } from "../../../../../utils/SharedUtils";

export const WEIGHTNOTE_KEY = "weightnote";
export const MATERIAL_IMAGES_KEY = "images";
export const INVOICE_KEY = "invoice";
export const OTHER_KEY = "other";

export const WEIGHTNOTE_UPLOAD_TYPE = "WEIGHTNOTE_UPLOAD_TYPE";
export const MATERIAL_IMAGES_UPLOAD_TYPE = "MATERIALS_IMAGES_UPLOAD_TYPE";
export const INVOICE_UPLOAD_TYPE = "INVOICE_UPLOAD_TYPE";

const TITLES = {
  [WEIGHTNOTE_UPLOAD_TYPE]: {
    title: "UploadFiles_UploadWeightNote",
    icon: WeightIcon
  },
  [MATERIAL_IMAGES_UPLOAD_TYPE]: {
    title: "UploadFiles_UploadImages",
    icon: ImageSharp
  },
  [INVOICE_UPLOAD_TYPE]: {
    title: "UploadFiles_UploadInvoice",
    icon: PublishSharp
  },
  default: {
    title: "UploadFiles_UploadOtherDocs",
    icon: InsertDriveFile
  }
};

const getStepTitleByType = type => {
  const { title } = TITLES[type] || TITLES.default;
  return title || "";
};

const getStepIconByType = type => {
  const titleObj = TITLES[type] || TITLES.default;
  return titleObj.icon;
};

const DOC_TYPES_STRING = {
  [WEIGHTNOTE_UPLOAD_TYPE]: "UploadFiles_Type_WeightNote",
  [MATERIAL_IMAGES_UPLOAD_TYPE]: "UploadFiles_Type_MatImages",
  [INVOICE_UPLOAD_TYPE]: "UploadFiles_Type_Invoice",
  default: null
};

export const getTypeString = (type, t) => {
  const value = DOC_TYPES_STRING[type] || DOC_TYPES_STRING.default;
  return value ? t(value)?.toLowerCase() : "";
};

export const getSubtitle = (internalTransactionId, transactionId, type, t) => {
  const translationKey = internalTransactionId
    ? "UploadFilesSubtitle"
    : "UploadFilesSubtitle_noIntId";
  return t(translationKey, {
    type: getTypeString(type, t),
    metaloopId: transactionId,
    ...(internalTransactionId ? { internalTransactionId } : {})
  });
};

const getType = type => type;

const STEPS = [
  {
    title: "UploadFiles",
    titleIcon: FolderIcon,
    component: Step1
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: Step2,
    hideTitle: true,
    type: getType
  },

  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    disableClose: true,
    component: Step3
  },
  { title: getStepTitleByType, titleIcon: getStepIconByType, component: Step4 }
];

export const STEPS_NO_UPLOAD = [
  {
    title: "UploadFiles",
    titleIcon: FolderIcon,
    component: Step1
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: Step2,
    hideTitle: true,
    type: getType
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: Step3_no_upload
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: Step4_no_upload
  }
];

export const StepTitleComponent = ({ fullScreen, type, title, titleIcon }) => {
  const { t } = useTranslation();

  const TitleIcon = applyFnOrValue(titleIcon, type);

  const titleText = applyFnOrValue(title, type);

  return (
    <>
      {!fullScreen && TitleIcon && <TitleIcon />}
      {t(titleText)}
    </>
  );
};

export default STEPS;
