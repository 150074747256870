import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DateDropdownSLASelector from "./DateDropdownSLASelector";
import DateDropdownSLADateRangePicker from "./DateDropdownSLADateRangePicker";
import moment from "moment";
import "moment-timezone";
const getDate = numOfDays => {
  const date = new Date();

  date.setDate(date.getDate() + numOfDays);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${day < 10 ? "0" + day : day}-${
    month < 10 ? "0" + month : month
  }-${year}`;
  return formattedDate;
};

const DateDropdownSLA = ({
  date,
  contractStartDate,
  contractEndDate,
  setDate,
  label,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  slaLevel,
  error
}) => {
  const useStyles = makeStyles(theme => ({}));

  const { t } = useTranslation();
  const classes = useStyles();
  const [openDataPickerSelector, setOpenDataPickerSelector] = useState(false);
  const [openDataRangeSelector, setOpenDataRangeSelector] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [minDate, setMinDate] = useState(() => {
    if (!slaLevel || slaLevel === "1") return 7;
    if (slaLevel === "MetaloopAdmin") return 3;
    if (slaLevel === "2") return 4;
    const hourAtVienna = parseInt(moment.tz("Europe/Vienna").format("hh"));
    const minsAtVienna = parseInt(moment.tz("Europe/Vienna").format("mm"));
    if (hourAtVienna > 11 || (hourAtVienna === 11 && minsAtVienna > 1)) {
      return 1;
    }
    return 0;
  });
  const [dateOptions, setDateOptions] = useState(() => {
    //Depending de SLA LEVEL, they options will be different
    const contractStartMoment = moment(contractStartDate, "YYYY-MM-DD");
    const contractEndMoment = moment(contractEndDate, "YYYY-MM-DD");
    const now = moment();
    const twoWeeksFromNow = now.clone().add(2, "weeks");
    const displayTwoWeeks =
      contractStartMoment.isSameOrBefore(now) &&
      contractEndMoment.isSameOrAfter(twoWeeksFromNow); // here return true if the contract start date is before or now && the contract end date is after two weeks from now or more

    if (!slaLevel || slaLevel === "1") {
      return [
        ...(displayTwoWeeks
          ? [
              {
                value: "2weeks",
                label: t("Pickups_WithinTwoWeeks"),
                start: getDate(0),
                end: getDate(13)
              }
            ]
          : []),
        {
          value: "dateRange",
          label: t("Pickups_SelectADateRange"),
          keepDropdownOpen: true,
          callback: () => {
            setOpenDataRangeSelector(true);
          },
          start: date === "dateRange" ? startDate : null,
          end: date === "dateRange" ? endDate : null
        }
      ];
    }

    if (slaLevel === "MetaloopAdmin") {
      return [
        {
          value: "dateRange",
          label: t("Pickups_SelectADateRange"),
          keepDropdownOpen: true,
          callback: () => {
            setOpenDataRangeSelector(true);
          },
          start: date === "dateRange" ? startDate : null,
          end: date === "dateRange" ? endDate : null
        },
        {
          value: "date",
          label: t("Pickups_SelectADate"),
          keepDropdownOpen: true,
          callback: () => {
            setOpenDataRangeSelector(true);
          },
          start: date === "date" ? startDate : null,
          end: date === "date" ? endDate : null
        }
      ];
    }

    if (slaLevel === "2") {
      return [
        ...(displayTwoWeeks
          ? [
              {
                value: "2weeks",
                label: t("Pickups_WithinTwoWeeks"),
                start: getDate(0),
                end: getDate(13)
              }
            ]
          : []),
        {
          value: "dateRange",
          label: t("Pickups_SelectADateRange"),
          keepDropdownOpen: true,
          callback: () => {
            setOpenDataRangeSelector(true);
          },
          start: date === "dateRange" ? startDate : null,
          end: date === "dateRange" ? endDate : null
        },
        {
          value: "date",
          label: t("Pickups_SelectADate"),
          keepDropdownOpen: true,
          callback: () => {
            setOpenDataRangeSelector(true);
          },
          start: date === "date" ? startDate : null,
          end: date === "date" ? endDate : null
        }
      ];
    }
    const hourAtVienna = parseInt(moment.tz("Europe/Vienna").format("HH"));
    const minsAtVienna = parseInt(moment.tz("Europe/Vienna").format("mm"));

    let exactDate = -1;
    if (hourAtVienna > 11 || (hourAtVienna === 11 && minsAtVienna > 1)) {
      exactDate = 1;
    } else {
      exactDate = 0;
    }

    return [
      ...(displayTwoWeeks
        ? [
            {
              value: "immediately",
              label: t("Pickups_Immediately"),
              start: getDate(exactDate),
              end: getDate(exactDate)
            },
            {
              value: "2weeks",
              label: t("Pickups_WithinTwoWeeks"),
              start: getDate(0),
              end: getDate(13)
            }
          ]
        : []),
      {
        value: "dateRange",
        label: t("Pickups_SelectADateRange"),
        keepDropdownOpen: true,
        callback: () => {
          setOpenDataRangeSelector(true);
        },
        start: date === "dateRange" ? startDate : null,
        end: date === "dateRange" ? endDate : null
      },
      {
        value: "date",
        label: t("Pickups_SelectADate"),
        keepDropdownOpen: true,
        callback: () => {
          setOpenDataRangeSelector(true);
        },
        start: date === "date" ? startDate : null,
        end: date === "date" ? endDate : null
      }
    ];
  });

  const updateDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const updateParameter = (parameter, startNewValue, endNewValue) => {
    setStartDate(startNewValue);
    setEndDate(endNewValue);

    setDateOptions(prevOptions => {
      const updatedOptions = [...prevOptions];
      const dateRangeOption = updatedOptions.find(
        option => option.value === parameter
      );
      if (dateRangeOption) {
        dateRangeOption["start"] = startNewValue;
        dateRangeOption["end"] = endNewValue;
      }
      return updatedOptions;
    });
  };
  const resetStartEndDate = () => {
    setTempEndDate(null);
    setTempStartDate(null);
    setDate(null);
    setDateOptions(prevOptions => {
      const updatedOptions = [...prevOptions];
      const dateRangeOption = updatedOptions.find(
        option => option.value === "dateRange"
      );
      if (dateRangeOption) {
        dateRangeOption["start"] = null;
        dateRangeOption["end"] = null;
      }
      return updatedOptions;
    });
  };

  return (
    <>
      <DateDropdownSLASelector
        label={label}
        date={date}
        setDate={setDate}
        dateOptions={dateOptions}
        resetStartEndDate={resetStartEndDate}
        setOpenDataRangeSelector={setOpenDataRangeSelector}
        setOpenDataPickerSelector={setOpenDataPickerSelector}
        updateDates={updateDates}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        startDate={startDate}
        endDate={endDate}
        contractStartDate={contractStartDate}
        contractEndDate={contractEndDate}
        error={error}
      />
      <DateDropdownSLADateRangePicker
        type={date}
        setDate={setDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        open={openDataRangeSelector}
        setOpen={setOpenDataRangeSelector}
        tempStartDate={tempStartDate}
        setTempStartDate={setTempStartDate}
        tempEndDate={tempEndDate}
        setTempEndDate={setTempEndDate}
        dateOptions={dateOptions}
        setDateOptions={setDateOptions}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        minDate={minDate}
        contractStartDate={contractStartDate}
        contractEndDate={contractEndDate}
      />
    </>
  );
};

export default DateDropdownSLA;
