import React, { useContext, useState, useEffect } from "react";
import { Switch } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";

import UserContext from "../../providers/UserProvider";
import { GET_FULL_PRODUCT_DATA } from "../../queries/Products";
import ProductsAddPage from "./add";
import ProductsProposePage from "./propose";
import ProductsPage from "./ProductsPage";
import { CheckErrors } from "../../ErrorTracking";
import Loader from "../../components/core/shared/Loader";
import queryString from "query-string";
import CompanyLocationContext from "../../providers/CompanyLocationProvider";

const ProductsRoute = () => {
  let location = useLocation();
  let query = queryString.parse(location.search);
  const searchParams = new URLSearchParams(query);
  const sale_request_id = searchParams.get("sale-request");
  const [showLocations, setShowLocations] = useState("all");
  const showMaterials = !sale_request_id;

  const { user, selectedCompany } = useContext(UserContext);

  //We skip the query unless we show all materials
  const { error, loading, data, refetch } = useQuery(GET_FULL_PRODUCT_DATA, {
    variables: {
      token: user.token,
      companyId: selectedCompany,
      location: showLocations
    },
    fetchPolicy: "cache-and-network",
    skip: !showMaterials
  });
  useEffect(() => {
    refetch();
  }, [showLocations, refetch]);

  if (loading) {
    return <Loader />;
  } else if (error) {
    return null;
  }

  const { FullProductData } = showMaterials
    ? data
    : {
        FullProductData: {
          contracted: [],
          priceRequested: [],
          noSaleInProgress: []
        }
      };

  const productData = [
    ...FullProductData.contracted
      .map(c => ({ ...c, statusId: 1 }))
      .sort((a, b) => (a.aliasForLanguage > b.aliasForLanguage ? 1 : -1)),
    ...FullProductData.priceRequested
      .map(c => ({ ...c, statusId: 2 }))
      .sort((a, b) => (a.aliasForLanguage > b.aliasForLanguage ? 1 : -1)),
    ...FullProductData.noSaleInProgress
      .map(c => ({ ...c, statusId: 4 }))
      .sort((a, b) => (a.aliasForLanguage > b.aliasForLanguage ? 1 : -1))
  ];
  const addingProduct = location.pathname.includes("/add");
  const proposeProduct = location.pathname.includes("/propose");
  return (
    <CompanyLocationContext.Provider
      value={{ showLocations, setShowLocations }}
    >
      <CheckErrors>
        <Switch>
          {addingProduct && <ProductsAddPage />}
          {proposeProduct && <ProductsProposePage />}

          {sale_request_id && (
            <ProductsPage
              user={user}
              selectedCompany={selectedCompany}
              type="Sale"
              params_id={sale_request_id}
            />
          )}
          {showMaterials && (
            <ProductsPage
              locationFilter={FullProductData?.allLocations}
              productData={productData}
              setShowLocations={setShowLocations}
              user={user}
              selectedCompany={selectedCompany}
              type="main"
            />
          )}
        </Switch>
      </CheckErrors>
    </CompanyLocationContext.Provider>
  );
};

export default ProductsRoute;
