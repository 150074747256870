import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Button,
  useTheme,
  makeStyles,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { useQuery } from "react-apollo";
import PickupsCard from "../../components/pickups/PickupsCard";
import GetPickupsData from "../../queries/GetPickupsData";
import { useHistory } from "react-router-dom";
import useEventTracking from "../../hooks/useEventTracking";
import PickupFilters from "../../components/pickups/Filters/PickupFilters";
import { useLocation } from "react-router-dom";
import useIsGroupAdmin from "../../hooks/useIsGroupAdmin";
import StyledTooltip from "../../components/core/StyledTooltip";
import InformationalComponent from "../../components/general/InformationalComponent";
import EmptyPickUpView from "../../components/emptyState/EmptyPickUpView";
import LoadingPickups from "../../components/emptyState/LoadingPickups";
import { isDemoEnviroment as _isDemoEnviroment } from "../../config/config";
const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "inherit",
    lineHeight: "inherit",
    marginLeft: "5px",
    letterSpacing: "inherit",
    textDecoration: "underline",
    color: "#276EF1",
    display: "inline"
  },
  pageTitle: {
    display: "none"
  },
  pageSubTitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  mobileTopButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: "-4px",
    marginBottom: "-4px",
    "@media (max-width: 600px)": {
      marginTop: "8px",
      marginBottom: "-10px",
      width: "100%",
      flexDirection: "column"
    }
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "72px 16px 0px 16px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: "16px"
  },
  contentArea: {
    width: "100%"
  },
  [theme.breakpoints.up("md")]: {
    pageTitle: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "8px",
      display: "block"
    },
    pageSubTitle: {
      fontSize: "16px",
      lineHeight: "24px"
    },
    titleWrapper: {
      padding: "28px 32px",
      boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
    },
    mainContentWrapper: {
      padding: "24px 32px 24px 32px",
      overflowX: "auto"
    },
    contentArea: {
      width: "1118px",
      maxWidth: "1118px"
    }
  },
  informationalComponent: {
    width: "1118px",
    maxWidth: "1118px",
    margin: "24px 32px",
    "& p": { lineHeight: "24px", letterSpacing: "0.25px" },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      margin: "20px 16px",
      "& p": { lineHeight: "unset", letterSpacing: "unset" }
    }
  },
  fullWidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  fullWidthSpan: {
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "12px",
      marginBottom: "6px"
    }
  }
}));

const PickupsPage = ({
  productData,
  urlStatus,
  urlTransactionId,
  urlProductId,
  urlLocalizationId,
  urlDate,
  urlStartDate,
  urlEndDate,
  hidePickups = false,
  user,
  selectedCompany,
  arrayOfExpanded = [],
  showExpandedFirst = false,
  hasTransactions = true
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [triggerReset, setTriggerReset] = useState(false);
  const location = useLocation();
  const { TrackEvent } = useEventTracking();
  let [count, setCount] = useState(0);
  useEffect(() => {
    TrackEvent("Pick up page opened");
    setCount(productData.length);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isGroupAdmin = useIsGroupAdmin();
  const isDemoEnviroment = _isDemoEnviroment();

  const pickupsQueryVariables = {
    token: user?.token,
    partnerId: selectedCompany === null ? "all" : selectedCompany,
    status: urlStatus,
    productId: urlProductId === null ? "all" : urlProductId,
    localizationId: urlLocalizationId === null ? "all" : urlLocalizationId,
    searchTransactionId: urlTransactionId === null ? "all" : urlTransactionId,
    limitedToFour: false,
    date: urlDate === null ? "all" : urlDate,
    startDate: urlStartDate === null ? null : urlStartDate,
    endDate: urlEndDate === null ? null : urlEndDate,
    showExpandedFirst: showExpandedFirst,
    expanded: arrayOfExpanded
  };

  const { error, loading, data, refetch } = useQuery(GetPickupsData, {
    variables: pickupsQueryVariables,
    fetchPolicy: "cache-and-network"
  });

  if (error) {
    if (location.search.includes("download")) {
      refetch();
      return null;
    }
    return error;
  }

  const byDateData = !loading ? data.PickupsData.pickupData : [];
  //If we only are showing four results we will fill the date range
  const initialSortedDate = !loading
    ? data.PickupsData.initialSortedDate
    : null;
  const finalSortedDate = !loading ? data.PickupsData.finalSortedDate : null;

  const showFilters =
    window.location.href.includes("confirm") ||
    window.location.href.includes("reschedule")
      ? false
      : true;

  const notHasProductsContracted = productData?.length < 1;

  const allPickupCount = data?.PickupsDataCount?.all;
  const noPickups =
    !loading && allPickupCount === 0 && (!hasTransactions || isDemoEnviroment);
  const disableButton =
    (noPickups && !isDemoEnviroment && notHasProductsContracted) ||
    isGroupAdmin;

  return (
    <>
      <div className={classes.titleWrapper}>
        <div>
          <Typography variant="h4" className={classes.pageTitle}>
            {t("Pickups")}
          </Typography>
          {!showFilters ? null : (
            <Typography variant="subtitle1" className={classes.pageSubTitle}>
              {allPickupCount === 1
                ? t("Pickup_subheader_1")
                : !noPickups &&
                  t("Pickup_subheader_2", {
                    no_total_pickups: !loading ? allPickupCount : 0
                  })}
            </Typography>
          )}
        </div>
        <div className={classes.mobileTopButton}>
          <StyledTooltip
            placement="top"
            text={
              noPickups && notHasProductsContracted
                ? t("Pickups_NoPickups_Tooltip_Message")
                : ""
            }
          >
            <span className={classes.fullWidthSpan}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (count === 1) {
                    history.push(
                      `/pickups?request=${productData[0].conditionId}&many=false`,
                      { background: location }
                    );
                  } else if (count > 1) {
                    history.push(`/pickups?request=----&many=true`, {
                      background: location
                    });
                  } else {
                    return null;
                  }
                }}
                disabled={disableButton}
                className={classes.fullWidth}
              >
                {t("Request Pickup")}
              </Button>
            </span>
          </StyledTooltip>
        </div>
      </div>
      {!showFilters ? null : (
        <PickupFilters
          triggerReset={triggerReset}
          setTriggerReset={setTriggerReset}
          filters={
            data !== undefined && data.PickupsData !== undefined
              ? data.PickupsData.filters
              : []
          }
          initialSortedDate={initialSortedDate}
          finalSortedDate={finalSortedDate}
          disabled={noPickups}
        />
      )}
      {noPickups && (
        <InformationalComponent
          type="info"
          className={classes.informationalComponent}
        >
          <Typography variant={isMobile ? "body2" : "body1"}>
            {t("Pickups_NoPickups_Information_Message")}
          </Typography>
        </InformationalComponent>
      )}
      <div className={classes.mainContentWrapper}>
        <div className={classes.contentArea}>
          {loading ? (
            <LoadingPickups />
          ) : (
            <>
              {!hidePickups &&
                !noPickups &&
                byDateData.map(item => (
                  <PickupsCard
                    key={item._id}
                    item={item}
                    expandedByDefault={arrayOfExpanded.includes(
                      item.transactionId
                    )}
                    isGroupAdmin={isGroupAdmin}
                    isDemoEnviroment={isDemoEnviroment}
                  />
                ))}
              {noPickups && <EmptyPickUpView />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PickupsPage;
