/* eslint-disable linebreak-style */
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";
import useStandardLocalization from "../../../../hooks/useStandardLocalization";
import SaleDesktopInformation from "./saleDesktopInformation";
import CustomTable from "../../../general/Table";
import EF from "../../../core/shared/CurrencyFormat";
import ModalWrapper from "../../layout/Modals/ModalClass";
import { isItAndAdminLoggedInParentCompany } from "../../../../hooks/useUserData";
import { canSeeItem } from "../../../../utils/SharedUtils";
import useCompany from "../../../../hooks/useCompany";

const isAngebotEnabled = function (cartState, tenderType) {
  let enabled = cartState.reduce(
    (a, b) =>
      a &&
      (tenderType === "number"
        ? !!b.priceEuro
        : !!b.formula && b.formula.trim().length > 0),
    true
  );

  return enabled;
};

const TenderPrice = ({ order }) => {
  if (order.tenderType === "formula") return "FORMULA";
  const cartState = order.cart || [];

  let priceAmountFromTender = 0;

  if (order.isTender) {
    cartState.forEach(({ priceEuro, quantity }) => {
      priceAmountFromTender +=
        order.tenderType === "number" ? priceEuro * quantity : 0;
    });
  }
  return !isAngebotEnabled(cartState, order.tenderType) ? (
    "?"
  ) : (
    <EF value={priceAmountFromTender} />
  );
};

const useStyles = makeStyles(theme => ({
  ModalWrapperCloseWrapper: {
    padding: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    display: "flex",
    alignItem: "center"
  },
  ModalWrapperCloseIcon: {
    marginRight: "21px"
  },
  ModalWrapperContent: {
    // padding: "8px 16px"
  },
  [theme.breakpoints.down("sm")]: {
    CustomTableRoot: {
      "& .MuiTable-root": {
        width: "auto",
        minWidth: "100%",
        whiteSpace: "nowrap"
      }
    }
  }
}));

const SaleTable = props => {
  const { t } = useTranslation();
  const {
    data,
    isPolling,
    loading,
    handleExpansion,
    type,
    isSeller,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowCount,
    queryParamTransactionID,
    statuses,
    uploadInitialType
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const company = useCompany();

  const [isExpanded, setIsExpanded] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const onRowClick = ({ record, onOpenCollapse }) => {
    handleExpansion(record);
    // open modal not table inner content in mobile
    if (isMobile) {
      setIsExpanded(true);
      setCurrentRecord(record);
      return;
    }
    onOpenCollapse();
  };

  const onCloseModal = () => {
    setIsExpanded(false);
    if (isMobile) {
      handleExpansion(currentRecord);
      return;
    }
  };

  data.forEach(item => {
    if (statuses) {
      const matchingStatus = statuses.find(
        status => status.statusId === item.statusId
      );
      if (matchingStatus) {
        item.status = matchingStatus.statusUI;
      }
    }
  });
  const { parseNumber, parseCurrency } = useStandardLocalization();
  const canSeePricingInfo = canSeeItem(company?.selectedCompany, [company], {
    rules: "sales_prices"
  });

  let columns = [
    {
      width: "16px",
      spacer: true
    },
    {
      field: "metaloop_id",
      headerName: t("metaloop_id"),
      //   width: 'auto',
      flex: 1,
      valueFormatter({ record }) {
        return (
          (record.isTender ? t("Ausschreibung") + " " : "") +
          record.transactionID
        );
      }
    },
    {
      field: "yourOrderId",
      headerName: t("yourOrderId"),
      flex: 1,
      valueFormatter({ record }) {
        return record.internalTransactionId;
      }
    },
    {
      field: "pickupDate",
      headerName: t("Pickup Date"),
      sortable: false,
      valueFormatter({ record }) {
        return record.pickupDate;
      }
    },
    {
      field: "status",
      headerName: t("Status"),
      sortable: false,
      valueFormatter({ record }) {
        return t(record.status);
      }
    },
    {
      field: "amount",
      headerName: t("Declared Quantity (kg)"),
      flex: 1,
      type: "number",
      valueFormatter({ record }) {
        return parseNumber((Math.round(record.amount * 100) / 100).toFixed(2));
      }
    },
    {
      field: "invoicedAmount",
      headerName: t("Invoiced Quantity (kg)"),
      flex: 1,
      type: "number",
      valueFormatter({ record }) {
        return record.invoicedAmount
          ? parseNumber(
              (Math.round(record.invoicedAmount * 100) / 100).toFixed(2)
            )
          : "-";
      }
    },
    canSeePricingInfo && {
      field: "price",
      headerName: t("Total Value"),
      flex: 1,
      type: "number",
      renderCell({ record }) {
        return record.isTender ? (
          <TenderPrice />
        ) : record.price ? (
          parseCurrency(record.price)
        ) : (
          "-"
        );
      }
    },
    {
      headerName: "",
      flex: 1,
      type: "number",
      hide: isMobile ? true : false,
      renderCell: ({ record, onOpenCollapse, collapseIsOpen }) => (
        <IconButton aria-label="expand row" size="small">
          {collapseIsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    {
      width: "16px",
      spacer: true
    }
  ];

  if (isItAndAdminLoggedInParentCompany()) {
    columns.splice(2, 0, {
      field: "location",
      headerName: t("location"),
      sortable: false,
      valueFormatter({ record }) {
        return record.location;
      }
    });
  }

  const expandedRowRender = record => {
    if (!record) {
      return null;
    }
    return (
      <SaleDesktopInformation
        order={record}
        type={type}
        isSeller={isSeller}
        canSeePricingInfo={canSeePricingInfo}
        uploadInitialType={uploadInitialType}
      />
    );
  };

  return (
    <React.Fragment>
      <CustomTable
        columns={columns}
        rows={data}
        hover
        loading={loading && !isPolling}
        getRowId={record => record.transactionID}
        expandedRowRender={isMobile ? null : expandedRowRender}
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        pageSize={rowsPerPage}
        onPageChange={onChangePage}
        onPageSizeChange={onChangeRowsPerPage}
        rowCount={rowCount}
        onRowClick={onRowClick}
        className={classes.CustomTableRoot}
        defaultCollapseId={queryParamTransactionID}
      />
      <Hidden lgUp>
        <ModalWrapper
          onCloseModal={onCloseModal}
          isOpen={isExpanded}
          disableBackdropClick={true}
        >
          <div className={classes.ModalWrapperCloseWrapper}>
            <CloseIcon
              onClick={onCloseModal}
              className={classes.ModalWrapperCloseIcon}
            />
            {currentRecord ? currentRecord.transactionID : null}
          </div>
          <div className={classes.ModalWrapperContent}>
            {expandedRowRender(currentRecord)}
          </div>
        </ModalWrapper>
      </Hidden>
    </React.Fragment>
  );
};

export default SaleTable;
