import gql from "graphql-tag";

export default gql`
  mutation ChangeProductAlias(
    $token: String!
    $partnerId: String!
    $productId: Int!
    $newAlias: String!
  ) {
    ChangeProductAlias(
      token: $token
      partnerId: $partnerId
      productId: $productId
      alias: $newAlias
    ) {
      message
    }
  }
`;
